import { Arc, Door, Line, Pointer, Segment, TileWrapper, BuildingPart, SegmentClass, DOORTYPE, WINDOWTYPE, } from '../model';
import { PRECISION, TILE_SCALE, TILE_TRANSFORM_SCALE, FixedBuildingJson, WallBuildingJson, METRIC_UNITS, BuildingJson, TOLERANCE_DISTANCE, TOLERANCE_ANGLE, DEFAULT_PREVIEW_IMAGE_BORDER_COLOR, DEFAULT_PREVIEW_IMAGE_FILL_COLOR, METRIC_SMALL_UNITS, FEET, INCH, } from '../global/variable';
import { PROJECT_TYPE } from '../global/types';
import { Graph } from '../model/Graph';
import { intersect } from './polygon';
import Matrix2D from '../model/tile/Matrix2D';
import Layout from '../model/tile/Layout';
import { v4 as uuidv4 } from 'uuid';
import { ClosedArea } from 'src/model/ClosedArea';
import { _ } from 'src/services/i18n';
import { get_current_component } from 'svelte/internal';
import Shape from 'src/model/tile/Shape';
import { isEmpty } from 'lodash';
import polylabel from 'polylabel';
import { getLayoutGeometry } from 'src/services/api';
export function dist(a, b) {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}
export function convertPointerToViewBox(pointer, svgElement) {
    const pt = svgElement.createSVGPoint();
    pt.x = pointer.x;
    pt.y = pointer.y;
    const matrixCTM = svgElement.getScreenCTM();
    const svgP = matrixCTM ? pt.matrixTransform(matrixCTM.inverse()) : pt;
    return new Pointer(Math.round(svgP.x * 10) / 10, Math.round(svgP.y * 10) / 10);
}
export function convertPointerToClientBox(pointer, svgElement) {
    if (!svgElement)
        return new Pointer(0, 0);
    const pt = svgElement.createSVGPoint();
    pt.x = pointer.x;
    pt.y = pointer.y;
    const matrixCTM = svgElement.getScreenCTM();
    const clientP = matrixCTM ? pt.matrixTransform(matrixCTM) : pt;
    return new Pointer(clientP.x, clientP.y);
}
export function convertMousePointerToToolPosition(mousePointer, svgElement) {
    if (!svgElement)
        return new Pointer(0, 0);
    let newPointer = mousePointer.translate(0, 0);
    newPointer.x = Math.min(newPointer.x, svgElement.clientWidth - 180);
    if (newPointer.x < 0)
        newPointer.x += svgElement.clientWidth;
    newPointer.y = Math.min(newPointer.y, svgElement.clientHeight - 40);
    if (newPointer.y < 0)
        newPointer.y += svgElement.clientHeight;
    return newPointer;
}
export function makePointerInSvgBox(pointer, svgSize) {
    return pointer;
    // const resultPointer = new Pointer(pointer.x, pointer.y);
    // if (resultPointer.x < 0) {
    //   resultPointer.x = 0;
    // }
    // if (resultPointer.x > svgSize.w) {
    //   resultPointer.x = svgSize.w;
    // }
    // if (resultPointer.y < 0) {
    //   resultPointer.y = 0;
    // }
    // if (resultPointer.y > svgSize.h) {
    //   resultPointer.y = svgSize.h;
    // }
    // return resultPointer;
}
export function isLine(item) {
    return item instanceof Line;
}
export function getCrossedPointBetweenLineAndSegment(a, b) {
    const pointsArray = b.getPointsArray();
    if (pointsArray.length > 1) {
        for (let i = 0; i < pointsArray.length - 1; i++) {
            const crossedPointer = getCrossedPointBetweenTwoLines(a, new Line(pointsArray[i], pointsArray[i + 1]));
            if (crossedPointer)
                return crossedPointer;
        }
    }
    return undefined;
}
export function getCrossedPointBetweenTwoLines(a, b) {
    let result = undefined;
    if (dist(b.startPointer, a.startPointer) < TOLERANCE_DISTANCE)
        return a.startPointer;
    else if (dist(b.endPointer, a.startPointer) < TOLERANCE_DISTANCE)
        return a.startPointer;
    else if (dist(b.startPointer, a.endPointer) < TOLERANCE_DISTANCE)
        return a.endPointer;
    else if (dist(b.endPointer, a.endPointer) < TOLERANCE_DISTANCE)
        return a.endPointer;
    if (Math.abs(a.endPointer.x - a.startPointer.x) < TOLERANCE_DISTANCE ||
        Math.abs(b.endPointer.x - b.startPointer.x) < TOLERANCE_DISTANCE) {
        if (Math.abs(a.endPointer.x - a.startPointer.x) < TOLERANCE_DISTANCE &&
            Math.abs(b.endPointer.x - b.startPointer.x) < TOLERANCE_DISTANCE) {
            return undefined;
        }
        let crossedX = 0;
        if (Math.abs(a.endPointer.x - a.startPointer.x) < TOLERANCE_DISTANCE) {
            crossedX = a.startPointer.x;
            const ba = (b.endPointer.y - b.startPointer.y) / (b.endPointer.x - b.startPointer.x);
            const bb = b.startPointer.y - ba * b.startPointer.x;
            const crossedY = ba * crossedX + bb;
            if (crossedX >= Math.min(b.startPointer.x, b.endPointer.x) - TOLERANCE_DISTANCE &&
                crossedX <= Math.max(b.startPointer.x, b.endPointer.x) + TOLERANCE_DISTANCE &&
                crossedY >= Math.min(a.startPointer.y, a.endPointer.y) - TOLERANCE_DISTANCE &&
                crossedY <= Math.max(a.startPointer.y, a.endPointer.y) + TOLERANCE_DISTANCE) {
                result = new Pointer(crossedX, crossedY);
            }
        }
        else {
            crossedX = b.startPointer.x;
            const aa = (a.endPointer.y - a.startPointer.y) / (a.endPointer.x - a.startPointer.x);
            const ab = a.startPointer.y - aa * a.startPointer.x;
            const crossedY = aa * crossedX + ab;
            if (crossedX >= Math.min(a.startPointer.x, a.endPointer.x) - TOLERANCE_DISTANCE &&
                crossedX <= Math.max(a.startPointer.x, a.endPointer.x) + TOLERANCE_DISTANCE &&
                crossedY >= Math.min(b.startPointer.y, b.endPointer.y) - TOLERANCE_DISTANCE &&
                crossedY <= Math.max(b.startPointer.y, b.endPointer.y) + TOLERANCE_DISTANCE) {
                result = new Pointer(crossedX, crossedY);
            }
        }
    }
    else {
        const aa = (a.endPointer.y - a.startPointer.y) / (a.endPointer.x - a.startPointer.x);
        const ab = a.startPointer.y - aa * a.startPointer.x;
        const ba = (b.endPointer.y - b.startPointer.y) / (b.endPointer.x - b.startPointer.x);
        const bb = b.startPointer.y - ba * b.startPointer.x;
        if (aa !== ba) {
            const crossedX = (bb - ab) / (aa - ba);
            const crossedY = aa * crossedX + ab;
            const crossedPointer = new Pointer(crossedX, crossedY);
            if (a.isInSegment(crossedPointer) && b.isInSegment(crossedPointer)) {
                result = crossedPointer;
            }
        }
    }
    if (result) {
        if (dist(result, a.startPointer) < TOLERANCE_DISTANCE)
            result = a.startPointer;
        if (dist(result, a.endPointer) < TOLERANCE_DISTANCE)
            result = a.endPointer;
        if (dist(result, b.startPointer) < TOLERANCE_DISTANCE)
            result = b.startPointer;
        if (dist(result, b.endPointer) < TOLERANCE_DISTANCE)
            result = b.endPointer;
    }
    return result;
}
export function getCrossedPointBetweenSegments(a, b) {
    if (isLine(a) && isLine(b)) {
        return getCrossedPointBetweenTwoLines(a, b);
    }
    else if (isLine(a)) {
        return getCrossedPointBetweenLineAndSegment(a, b);
    }
    else if (isLine(b)) {
        return getCrossedPointBetweenLineAndSegment(b, a);
    }
    else {
        const aPointsArray = a.getPointsArray();
        const bPointsArray = b.getPointsArray();
        for (let i = 0; i < aPointsArray.length - 1; i++) {
            const aLine = new Line(aPointsArray[i], aPointsArray[i + 1]);
            for (let j = 0; j < bPointsArray.length - 1; j++) {
                const bLine = new Line(bPointsArray[i], bPointsArray[i + 1]);
                const crossedPointer = getCrossedPointBetweenTwoLines(aLine, bLine);
                if (crossedPointer)
                    return crossedPointer;
            }
        }
        return undefined;
    }
}
function isOverlapped(a, b) {
    //If they have the same slope check for the points to intersect
    if (a.isInSegment(b.startPointer) && a.isInSegment(b.endPointer)) {
        const min1 = new Pointer(Math.min(a.startPointer.x, a.endPointer.x), Math.min(a.startPointer.y, a.endPointer.y));
        const max1 = new Pointer(Math.max(a.startPointer.x, a.endPointer.x), Math.max(a.startPointer.y, a.endPointer.y));
        const min2 = new Pointer(Math.min(b.startPointer.x, b.endPointer.x), Math.min(b.startPointer.y, b.endPointer.y));
        const max2 = new Pointer(Math.max(b.startPointer.x, b.endPointer.x), Math.max(b.startPointer.y, b.endPointer.y));
        const minIntersection = new Pointer(Math.max(min1.x, min2.x), Math.max(min1.y, min2.y));
        const maxIntersection = new Pointer(Math.min(max1.x, max2.x), Math.min(max1.y, max2.y));
        return minIntersection.x < maxIntersection.x && minIntersection.y < maxIntersection.y;
    }
    return false;
}
export function getAngleBetweenTwoLines(a, b) {
    const aVector = new Pointer(a.endPointer.x - a.startPointer.x, a.startPointer.y - a.endPointer.y);
    const bVector = new Pointer(b.endPointer.x - b.startPointer.x, b.startPointer.y - b.endPointer.y);
    let angle = Math.atan2(bVector.y, bVector.x) - Math.atan2(aVector.y, aVector.x);
    if (angle < 0) {
        angle += 2 * Math.PI;
    }
    return Math.abs(angle);
}
export function getAngleBetweenTwoLinesInPI(a, b) {
    const aVector = new Pointer(a.endPointer.x - a.startPointer.x, a.startPointer.y - a.endPointer.y);
    const bVector = new Pointer(b.endPointer.x - b.startPointer.x, b.startPointer.y - b.endPointer.y);
    let angle = Math.atan2(bVector.y, bVector.x) - Math.atan2(aVector.y, aVector.x);
    if (angle < 0) {
        angle += 2 * Math.PI;
    }
    if (angle >= (3 * Math.PI) / 2) {
        angle = Math.PI * 2 - angle;
    }
    else if (angle >= Math.PI) {
        angle -= Math.PI;
    }
    else if (angle >= Math.PI / 2) {
        angle = Math.PI - angle;
    }
    return angle;
}
export function checkValidMovePointer(segments, lineCircle) {
    let result = true;
    const allSegments = [];
    const circleSegmentList = [];
    for (const s of segments) {
        if (s instanceof TileWrapper)
            continue;
        if (s instanceof ClosedArea)
            continue;
        if (s.startPointer.equals(lineCircle) || s.endPointer.equals(lineCircle)) {
            circleSegmentList.push(s);
        }
        else {
            allSegments.push(s);
        }
    }
    for (const cs of circleSegmentList) {
        if ((isLine(cs) && cs.getLineLength() === 0) ||
            (!isLine(cs) && cs.getPointsArray().length === 0)) {
            result = false;
            break;
        }
        for (let i = 0; i < allSegments.length; i++) {
            if (isLine(cs) && isLine(allSegments[i])) {
                if (isOverlapped(cs, allSegments[i])) {
                    result = false;
                    break;
                }
            }
        }
        if (!result)
            break;
    }
    if (result) {
        for (let k = 0; k < circleSegmentList.length - 1; k++) {
            for (let j = k + 1; j < circleSegmentList.length; j++) {
                if (isLine(circleSegmentList[k]) && isLine(circleSegmentList[j])) {
                    if (isOverlapped(circleSegmentList[k], circleSegmentList[j])) {
                        result = false;
                        break;
                    }
                }
            }
            if (!result)
                break;
        }
    }
    return result;
}
export function getGlobalHelperPath(segments, removedSegments, pointsArray, viewBox) {
    let result = '';
    const allSegments = [];
    for (const s of segments) {
        if (!removedSegments.includes(s) && !(s instanceof TileWrapper) && !(s instanceof ClosedArea))
            allSegments.push(s);
    }
    for (const movingPointer of pointsArray) {
        for (const s of allSegments) {
            if ((s.startPointer.x === movingPointer.x || s.startPointer.y === movingPointer.y) &&
                !s.startPointer.equals(movingPointer)) {
                result =
                    result +
                        (result ? ' ' : '') +
                        'M' +
                        (s.startPointer.x === movingPointer.x ? movingPointer.x : viewBox.x) +
                        ',' +
                        (s.startPointer.x !== movingPointer.x ? movingPointer.y : viewBox.y) +
                        ' ' +
                        'L' +
                        (s.startPointer.x === movingPointer.x ? movingPointer.x : viewBox.x + viewBox.w) +
                        ',' +
                        (s.startPointer.x !== movingPointer.x ? movingPointer.y : viewBox.y + viewBox.h);
            }
            if ((s.endPointer.x === movingPointer.x || s.endPointer.y === movingPointer.y) &&
                !s.endPointer.equals(movingPointer)) {
                result =
                    result +
                        (result ? ' ' : '') +
                        'M' +
                        (s.endPointer.x === movingPointer.x ? movingPointer.x : viewBox.x) +
                        ',' +
                        (s.endPointer.x !== movingPointer.x ? movingPointer.y : viewBox.y) +
                        ' ' +
                        'L' +
                        (s.endPointer.x === movingPointer.x ? movingPointer.x : viewBox.x + viewBox.w) +
                        ',' +
                        (s.endPointer.x !== movingPointer.x ? movingPointer.y : viewBox.y + viewBox.h);
            }
            if (!s.startPointer.equals(movingPointer) &&
                !s.endPointer.equals(movingPointer) &&
                isLine(s) &&
                s.isInSegment(movingPointer)) {
                const deltaX = s.endPointer.x - s.startPointer.x;
                const deltaY = s.endPointer.y - s.startPointer.y;
                let x1 = s.startPointer.x - s.startPointer.y * Math.tan(Math.atan2(deltaX, deltaY));
                let y1 = 0;
                // if (x1 < 0) {
                //   x1 = 0;
                // }
                // if (x1 > svgSize.w) {
                //   x1 = svgSize.w;
                // }
                y1 = Math.tan(Math.atan2(deltaY, deltaX)) * (x1 - s.startPointer.x) + s.startPointer.y;
                let x2 = s.startPointer.x + (viewBox.h - s.startPointer.y) * Math.tan(Math.atan2(deltaX, deltaY));
                let y2 = 0;
                // if (x2 < 0) {
                //   x2 = 0;
                // }
                // if (x2 > svgSize.w) {
                //   x2 = svgSize.w;
                // }
                y2 = Math.tan(Math.atan2(deltaY, deltaX)) * (x2 - s.startPointer.x) + s.startPointer.y;
                result = result + (result ? ' ' : '') + 'M' + x1 + ',' + y1 + ' ' + 'L' + x2 + ',' + y2;
            }
        }
    }
    return result;
}
export function checkValidMoveSegment(segments, movingSegments) {
    const allSegments = [];
    for (const s of segments) {
        if (!movingSegments.includes(s))
            allSegments.push(s);
    }
    let result = true;
    for (const line of movingSegments) {
        if ((isLine(line) && line.getLineLength() === 0) || (!isLine(line) && line.getPointsArray().length === 0)) {
            result = false;
            break;
        }
        for (const s of allSegments) {
            if (isLine(line) && isLine(s)) {
                if (isOverlapped(line, s)) {
                    result = false;
                    break;
                }
            }
        }
        if (!result)
            break;
    }
    return result;
}
function snapShotGrid(pointer, unit = 10) {
    const leftTop = new Pointer(pointer.x, pointer.y);
    leftTop.x = Math.floor(pointer.x / unit) * unit - (pointer.x > 0 ? 0 : unit);
    leftTop.y = Math.floor(pointer.y / unit) * unit - (pointer.y > 0 ? 0 : unit);
    const distLeftTop = dist(leftTop, pointer);
    const rightTop = new Pointer(leftTop.x + unit, leftTop.y);
    const distRightTop = dist(rightTop, pointer);
    const bottomLeft = new Pointer(leftTop.x, leftTop.y + unit);
    const distBottomLeft = dist(bottomLeft, pointer);
    const bottomRight = new Pointer(leftTop.x + unit, leftTop.y + unit);
    const distBottomRight = dist(bottomRight, pointer);
    let resultPoint = leftTop;
    let minDist = distLeftTop;
    if (minDist > distRightTop) {
        resultPoint = rightTop;
        minDist = distRightTop;
    }
    if (minDist > distBottomLeft) {
        resultPoint = bottomLeft;
        minDist = distBottomLeft;
    }
    if (minDist > distBottomRight) {
        resultPoint = bottomRight;
        minDist = distBottomRight;
    }
    if (minDist < unit / 2) {
        return resultPoint;
    }
    return undefined;
}
function getClosetPointer(pointer, segment) {
    let result = undefined;
    let startPointer = segment.startPointer, endPointer = segment.endPointer;
    if (dist(startPointer, pointer) < PRECISION / 2) {
        return startPointer.translate(0, 0);
    }
    if (dist(endPointer, pointer) < PRECISION / 2) {
        return endPointer.translate(0, 0);
    }
    if (segment instanceof ClosedArea || segment instanceof TileWrapper) {
        let minDist = PRECISION / 2;
        segment.shape.points.forEach((pt) => {
            const distance = dist(pt, pointer);
            if (minDist > distance) {
                minDist = distance;
                result = pt.translate(0, 0);
            }
        });
        if (!result) {
            const { lines, snapPoints } = getTileWrapperSnapPoints(segment);
            snapPoints.forEach((pt) => {
                const distance = dist(pt, pointer);
                if (minDist > distance) {
                    minDist = distance;
                    result = pt.translate(0, 0);
                }
            });
        }
    }
    else if (segment instanceof Line) {
        if (segment.isInSegment(pointer, true, PRECISION / 2)) {
            if (Math.abs(segment.startPointer.y - segment.endPointer.y) < TOLERANCE_DISTANCE) {
                result = new Pointer(pointer.x, segment.startPointer.y);
            }
            else if (Math.abs(segment.startPointer.x - segment.endPointer.x) < TOLERANCE_DISTANCE) {
                result = new Pointer(segment.startPointer.x, pointer.y);
            }
            else {
                const pointerY = Math.tan(Math.atan2(segment.endPointer.y - segment.startPointer.y, segment.endPointer.x - segment.startPointer.x)) *
                    (pointer.x - segment.startPointer.x) +
                    segment.startPointer.y;
                result = new Pointer(pointer.x, pointerY);
            }
        }
        //  else {
        //   result = getDistancePointer(segment, pointer);
        // }
    }
    else if (segment instanceof Arc) {
        const arcPoints = segment.getPointsArray();
        for (const newPointer of arcPoints) {
            if (!result || dist(pointer, newPointer) < dist(pointer, result)) {
                result = newPointer.translate(0, 0);
            }
            else
                break;
        }
    }
    return result;
}
function snapShotObject(pointer, segmentList) {
    let result = undefined;
    let resultSeg = undefined;
    for (const segment of segmentList) {
        const closetPointer = getClosetPointer(pointer, segment);
        if (closetPointer) {
            // if (segment instanceof ClosedArea || segment instanceof TileWrapper) {
            //   return closetPointer;
            // }
            if (!result) {
                result = closetPointer;
                resultSeg = segment;
            }
            else if (dist(result, pointer) > dist(closetPointer, pointer)) {
                result = closetPointer;
                resultSeg = segment;
            }
        }
    }
    return result;
}
function getVHSnapshotObject(pointer, segmentList, movingPointer, unit = 10) {
    let result = [];
    for (const line of segmentList) {
        const fixedPointer = line.startPointer.equals(movingPointer) ? line.endPointer : line.startPointer;
        const newResult = Math.abs(fixedPointer.x - pointer.x) < Math.abs(fixedPointer.y - pointer.y)
            ? new Pointer(fixedPointer.x, pointer.y)
            : new Pointer(pointer.x, fixedPointer.y);
        if (dist(movingPointer, newResult) < unit / 2) {
            const prevItem = result.find((item) => item.x === newResult.x || item.y === newResult.y);
            if (prevItem) {
                if (dist(prevItem, movingPointer) > dist(newResult, movingPointer)) {
                    result.splice(result.findIndex((item) => item.equals(prevItem)), 1);
                }
                else {
                    continue;
                }
            }
            result.push(newResult);
        }
    }
    result = result.sort((a, b) => dist(pointer, a) - dist(pointer, b)).slice(0, 2);
    if (result.length === 1) {
        return result[0];
    }
    else if (result.length === 2) {
        if (result[0].x === result[1].x || result[0].y === result[1].y) {
            return result[0];
        }
        return new Pointer(result[0].y === pointer.y ? result[0].x : result[1].x, result[0].x === pointer.x ? result[0].y : result[1].y);
    }
    return undefined;
}
export function getPerpendicularPointer(pointer, segmentList, selfSegment, movingPointer) {
    const connectedLines = segmentList.filter((seg) => (seg instanceof Line &&
        (seg.startPointer.equals(selfSegment.startPointer) || seg.endPointer.equals(selfSegment.startPointer)) &&
        !seg.endPointer.equals(movingPointer)) ||
        seg.isInSegment(selfSegment.startPointer));
    if (connectedLines.length === 0) {
        const angle = Math.round((selfSegment.getLineAngle() * 180) / Math.PI) % 360;
        if ((angle > 45 && angle < 135) || (angle > 225 && angle < 315)) {
            // vertical
            return {
                pointer: new Pointer(selfSegment.startPointer.x, pointer.y),
                type: 1,
            };
        }
        else {
            // horizontal
            return {
                pointer: new Pointer(pointer.x, selfSegment.startPointer.y),
                type: 1,
            };
        }
    }
    else {
        const sortedLines = connectedLines
            .map((v) => ({
            line: v,
            angle: getAngleBetweenTwoLinesInPI(v, selfSegment),
        }))
            .sort((a, b) => Math.abs(90 - a.angle) - Math.abs(90 - b.angle));
        const baseLine = sortedLines[0].line;
        const baseAngle = baseLine.getLineAngle();
        const newPointer = new Line(selfSegment.startPointer, pointer);
        const angle = getAngleBetweenTwoLines(baseLine, newPointer);
        const newAngle = angle >= Math.PI ? Math.PI * 1.5 : Math.PI * 0.5;
        const newLength = Math.cos(newAngle - angle) * newPointer.getLineLength();
        const x = selfSegment.startPointer.x + Math.cos(baseAngle + newAngle) * newLength;
        const y = selfSegment.startPointer.y - Math.sin(baseAngle + newAngle) * newLength;
        return { pointer: new Pointer(x, y), type: 1 };
    }
}
export function snapShotPointer(pointer, segmentList, selfSegmentList, movingPointer, unit = 10, disableSnapping = false) {
    const resultObject = snapShotObject(movingPointer ?? pointer, segmentList);
    //type 0: Grid, 1: VH, 2: Object
    if (resultObject && dist(resultObject, pointer) < PRECISION / 2) {
        return { pointer: resultObject, type: 2 };
    }
    if (disableSnapping)
        return { pointer: undefined, type: -1 };
    const result = snapShotGrid(pointer, unit);
    if (result && dist(result, pointer) < PRECISION / 2) {
        return { pointer: result, type: 0 };
    }
    if (movingPointer) {
        const vhObject = getVHSnapshotObject(pointer, [...segmentList, ...selfSegmentList], movingPointer, unit);
        if (vhObject && dist(vhObject, pointer) < PRECISION / 2) {
            return { pointer: vhObject, type: 1 };
        }
    }
    if (selfSegmentList.length > 0) {
        const currentLine = new Line(selfSegmentList[0].startPointer, pointer);
        const angle = Math.round((currentLine.getLineAngle() * 180) / Math.PI) % 360;
        if ((angle > 85 && angle < 95) || (angle > -95 && angle < -85)) {
            // vertical
            return {
                pointer: new Pointer(currentLine.startPointer.x, pointer.y),
                type: 1,
            };
        }
        else if ((angle > -5 && angle < 5) || angle > 175 || angle < -175) {
            // horizontal
            return {
                pointer: new Pointer(pointer.x, currentLine.startPointer.y),
                type: 1,
            };
        }
    }
    return { pointer: undefined, type: -1 };
}
export function getSnapShotPointer(pointer, segments, movingPointer, unit = 10, disableSnapping = false) {
    const segmentList = [];
    const selfSegmentList = [];
    for (const s of segments) {
        if (s instanceof TileWrapper || s instanceof ClosedArea) {
            segmentList.push(s);
        }
        else if (movingPointer && (s.startPointer.equals(movingPointer) || s.endPointer.equals(movingPointer))) {
            selfSegmentList.push(s);
        }
        else {
            segmentList.push(s);
        }
    }
    if (selfSegmentList.find((i) => i instanceof Door)) {
        return { pointer: undefined, type: -2 };
    }
    return snapShotPointer(pointer, segmentList, selfSegmentList, movingPointer, unit, disableSnapping);
}
export function getSegmentsOfPoint(segments, pointer) {
    const pointSegmentList = [];
    for (const s of segments) {
        if (s instanceof TileWrapper || s instanceof ClosedArea)
            continue;
        if (s.startPointer.equals(pointer) || s.endPointer.equals(pointer)) {
            pointSegmentList.push(s);
        }
    }
    return pointSegmentList;
}
export function translatePointerWithSegments(dx, dy, pointer, segments, isDashed = false) {
    let deltaX = dx, deltaY = dy;
    const ownedSegments = getSegmentsOfPoint(segments, pointer);
    ownedSegments.forEach((item) => {
        if (isDashed) {
            if (isLine(item) && item.isDashed()) {
                if (item.startPointer.equals(pointer)) {
                    item.startPointer = item.startPointer.translate(dx, dy);
                }
                else if (item.endPointer.equals(pointer)) {
                    item.endPointer = item.endPointer.translate(dx, dy);
                }
            }
        }
        else if (isLine(item)) {
            const buildingParts = getAllBuildingPartsInSegment(segments, item);
            const originalAngle = item.getLineAngle();
            let centerPointer = undefined;
            if (item.startPointer.equals(pointer)) {
                item.startPointer = item.startPointer.translate(dx, dy);
                centerPointer = item.endPointer;
            }
            else if (item.endPointer.equals(pointer)) {
                item.endPointer = item.endPointer.translate(dx, dy);
                centerPointer = item.startPointer;
            }
            const targetAngle = item.getLineAngle();
            const deltaAngle = originalAngle - targetAngle;
            if (centerPointer && buildingParts.length > 0) {
                buildingParts.map((buildingPart) => {
                    if (buildingPart instanceof Door) {
                        buildingPart.startPointer = getRotatedPointer(buildingPart.startPointer, centerPointer, deltaAngle);
                        buildingPart.endPointer = getRotatedPointer(buildingPart.endPointer, centerPointer, deltaAngle);
                    }
                    else {
                        buildingPart.parentId = 0;
                    }
                });
            }
        }
        else if (item instanceof Arc) {
            if (item.startPointer.x === pointer.x && item.startPointer.y === pointer.y) {
                const abDistance = dist(item.startPointer, item.endPointer);
                const cbDistance = dist(item.heightPointer, item.endPointer);
                let angleLine = Math.acos((item.startPointer.x - item.endPointer.x) / abDistance);
                if (item.startPointer.y > item.endPointer.y) {
                    angleLine = 2 * Math.PI - angleLine;
                }
                let angleCBX = Math.acos((item.heightPointer.x - item.endPointer.x) / cbDistance);
                if (item.heightPointer.y > item.endPointer.y) {
                    angleCBX = 2 * Math.PI - angleCBX;
                }
                const newAADistance = dist(item.startPointer, item.startPointer.translate(dx, dy));
                if (!item.startPointer.translate(dx, dy).equals(item.endPointer)) {
                    item.startPointer = item.startPointer.translate(dx, dy);
                    const transDistance = dist(item.startPointer, item.endPointer);
                    let newAngleLine = Math.acos((item.startPointer.x - item.endPointer.x) / transDistance);
                    if (item.startPointer.y > item.endPointer.y) {
                        newAngleLine = 2 * Math.PI - newAngleLine;
                    }
                    const angleRotate = (angleLine > newAngleLine ? -1 : 1) *
                        Math.acos((abDistance * abDistance + transDistance * transDistance - newAADistance * newAADistance) /
                            (2 * abDistance * transDistance));
                    if (angleRotate) {
                        const rate = transDistance / abDistance;
                        const transCBDistance = cbDistance * rate;
                        const deltaX = transCBDistance * Math.cos(angleCBX);
                        const deltaY = transCBDistance * Math.sin(angleCBX);
                        const rotateDeltaX = deltaX * Math.cos(angleRotate) - deltaY * Math.sin(angleRotate);
                        const rotateDeltaY = deltaX * Math.sin(angleRotate) + deltaY * Math.cos(angleRotate);
                        item.heightPointer = new Pointer(item.endPointer.x + rotateDeltaX, item.endPointer.y - rotateDeltaY);
                        item.updatePointsArray();
                    }
                }
            }
            else if (item.endPointer.x === pointer.x && item.endPointer.y === pointer.y) {
                const abDistance = dist(item.startPointer, item.endPointer);
                const caDistance = dist(item.heightPointer, item.startPointer);
                let angleLine = Math.acos((item.endPointer.x - item.startPointer.x) / abDistance);
                if (item.endPointer.y > item.startPointer.y) {
                    angleLine = 2 * Math.PI - angleLine;
                }
                let angleCAX = Math.acos((item.heightPointer.x - item.startPointer.x) / caDistance);
                if (item.heightPointer.y > item.startPointer.y) {
                    angleCAX = 2 * Math.PI - angleCAX;
                }
                const newBBDistance = dist(item.endPointer, item.endPointer.translate(dx, dy));
                if (!item.endPointer.translate(dx, dy).equals(item.startPointer)) {
                    item.endPointer = item.endPointer.translate(dx, dy);
                    const transDistance = dist(item.startPointer, item.endPointer);
                    let newAngleLine = Math.acos((item.endPointer.x - item.startPointer.x) / transDistance);
                    if (item.endPointer.y > item.startPointer.y) {
                        newAngleLine = 2 * Math.PI - newAngleLine;
                    }
                    const angleRotate = (angleLine > newAngleLine ? -1 : 1) *
                        Math.acos((abDistance * abDistance + transDistance * transDistance - newBBDistance * newBBDistance) /
                            (2 * abDistance * transDistance));
                    if (angleRotate) {
                        const rate = transDistance / abDistance;
                        const transCADistance = caDistance * rate;
                        const deltaX = transCADistance * Math.cos(angleCAX);
                        const deltaY = transCADistance * Math.sin(angleCAX);
                        const rotateDeltaX = deltaX * Math.cos(angleRotate) - deltaY * Math.sin(angleRotate);
                        const rotateDeltaY = deltaX * Math.sin(angleRotate) + deltaY * Math.cos(angleRotate);
                        item.heightPointer = new Pointer(item.startPointer.x + rotateDeltaX, item.startPointer.y - rotateDeltaY);
                        item.updatePointsArray();
                    }
                }
            }
        }
        else if (item instanceof Door) {
            const parentSegment = getParentLine(segments, item.parentId);
            if (parentSegment) {
                const dPointer1 = getDistancePointer(parentSegment, pointer);
                const dPointer2 = getDistancePointer(parentSegment, pointer.translate(dx, dy));
                const tempVector = new Line(pointer, pointer.translate(dx, dy));
                const angle = tempVector.getLineAngle() - parentSegment.getLineAngle();
                const deltaD = tempVector.getLineLength() * Math.cos(angle);
                let ySign = dPointer2.y - dPointer1.y >= 0 ? 1 : -1;
                let xSign = dPointer2.x - dPointer1.x >= 0 ? 1 : -1;
                deltaX = xSign * Math.abs(deltaD * Math.cos(parentSegment.getLineAngle()));
                deltaY = ySign * Math.abs(deltaD * Math.sin(parentSegment.getLineAngle()));
                if (item.startPointer.equals(pointer)) {
                    const translatedPointer = item.startPointer.translate(deltaX, deltaY);
                    if (dist(translatedPointer, item.endPointer) < PRECISION * 2) {
                        const minPointer = new Pointer(item.endPointer.x + PRECISION * 2 * Math.cos(parentSegment.getLineAngle() - Math.PI), item.endPointer.y - PRECISION * 2 * Math.sin(parentSegment.getLineAngle() - Math.PI));
                        deltaX = minPointer.x - pointer.x;
                        deltaY = minPointer.y - pointer.y;
                        item.startPointer = minPointer;
                    }
                    else {
                        item.startPointer = translatedPointer;
                    }
                }
                else if (item.endPointer.equals(pointer)) {
                    const translatedPointer = item.endPointer.translate(deltaX, deltaY);
                    if (dist(item.startPointer, translatedPointer) < PRECISION * 2) {
                        const minPointer = new Pointer(item.startPointer.x + PRECISION * 2 * Math.cos(parentSegment.getLineAngle()), item.startPointer.y - PRECISION * 2 * Math.sin(parentSegment.getLineAngle()));
                        deltaX = minPointer.x - pointer.x;
                        deltaY = minPointer.y - pointer.y;
                        item.endPointer = minPointer;
                    }
                    else {
                        item.endPointer = translatedPointer;
                    }
                }
            }
        }
    });
    pointer = pointer.translate(deltaX, deltaY);
    // sortLinesByAngle();
    return pointer;
}
function matrixTimes(a, b, center) {
    return new Pointer(a.x * center.x + a.y * center.y, b.x * center.x + b.y * center.y);
}
function rotateMatrix(x) {
    return [new Pointer(Math.cos(x), -Math.sin(x)), new Pointer(Math.sin(x), Math.cos(x))];
}
function vectorAdd(a, b) {
    return [a.x + b.x, a.y + b.y];
}
function getEllipseArc(center, radius, startAngle, deltaAngle) {
    const delta = deltaAngle % (2 * Math.PI);
    const rotMatrix = rotateMatrix(0);
    const [sX, sY] = vectorAdd(matrixTimes(rotMatrix[0], rotMatrix[1], new Pointer(radius * Math.cos(startAngle), radius * Math.sin(startAngle))), center);
    const [eX, eY] = vectorAdd(matrixTimes(rotMatrix[0], rotMatrix[1], new Pointer(radius * Math.cos(startAngle + delta), radius * Math.sin(startAngle + delta))), center);
    const fA = delta > Math.PI ? 1 : 0;
    const fS = delta > 0 ? 1 : 0;
    const path = 'M ' + sX + ' ' + sY + ' A ' + [radius, radius, (0 / (2 * Math.PI)) * 360, fA, fS, eX, eY].join(' ');
    return path;
}
export function getHelpAnglePath(centerPointer, segments) {
    let path = '';
    if (segments.length > 1) {
        const radius = 15;
        const lines = segments.filter((segment) => isLine(segment)).map((s) => s);
        sortLinesByAngle(centerPointer, lines);
        for (let i = 0; i < lines.length; i++) {
            //check neighber angle
            let angleRight = Math.abs(getAngleBetweenTwoLines2(centerPointer, lines[i], lines[i + 1 === lines.length ? 0 : i + 1]));
            if (angleRight > Math.PI)
                angleRight = 2 * Math.PI - angleRight;
            let angleLeft = Math.abs(getAngleBetweenTwoLines2(centerPointer, lines[i], lines[i - 1 === -1 ? lines.length - 1 : i - 1]));
            if (angleLeft > Math.PI)
                angleLeft = 2 * Math.PI - angleLeft;
            let nextLine;
            if (angleRight < angleLeft) {
                nextLine = lines[i + 1 === lines.length ? 0 : i + 1];
            }
            else {
                nextLine = lines[i - 1 === -1 ? lines.length - 1 : i - 1];
            }
            let startLineAngle = lines[i].getLineAngle();
            if (lines[i].endPointer.equals(centerPointer)) {
                startLineAngle = Math.PI + startLineAngle;
                if (startLineAngle > Math.PI) {
                    startLineAngle = startLineAngle - 2 * Math.PI;
                }
            }
            if (startLineAngle < 0) {
                startLineAngle = -startLineAngle;
            }
            else if (startLineAngle > 0) {
                startLineAngle = Math.PI * 2 - startLineAngle;
            }
            let nextLineAngle = nextLine.getLineAngle();
            if (nextLine.endPointer.equals(centerPointer)) {
                nextLineAngle = Math.PI + nextLineAngle;
                if (nextLineAngle > Math.PI) {
                    nextLineAngle = nextLineAngle - 2 * Math.PI;
                }
            }
            if (nextLineAngle < 0) {
                nextLineAngle = -nextLineAngle;
            }
            else if (nextLineAngle > 0) {
                nextLineAngle = Math.PI * 2 - nextLineAngle;
            }
            let angle = nextLineAngle > startLineAngle ? startLineAngle : nextLineAngle;
            let delta = Math.abs(nextLineAngle - startLineAngle);
            if (delta > Math.PI) {
                if (nextLineAngle > startLineAngle) {
                    angle = nextLineAngle;
                }
                else {
                    angle = startLineAngle;
                }
                delta = Math.PI * 2 - delta;
            }
            const angleText = (Math.abs(delta * 180) / Math.PI).toFixed(0);
            if (Number(angleText) % 180 !== 0) {
                if (i != lines.length - 2) {
                    path = path + getEllipseArc(centerPointer, radius, angle, delta) + ', ';
                }
                else {
                    path = path + getEllipseArc(centerPointer, radius, angle, delta);
                }
            }
        }
    }
    return path;
}
function getAngleBetweenTwoLines2(centerPointer, a, b) {
    let aVector = new Pointer(a.endPointer.x - a.startPointer.x, a.startPointer.y - a.endPointer.y);
    if (a.endPointer.equals(centerPointer)) {
        aVector = aVector.translateWithRate(-1);
    }
    let bVector = new Pointer(b.endPointer.x - b.startPointer.x, b.startPointer.y - b.endPointer.y);
    if (b.endPointer.equals(centerPointer)) {
        bVector = bVector.translateWithRate(-1);
    }
    return Math.atan2(bVector.y, bVector.x) - Math.atan2(aVector.y, aVector.x);
}
function sortLinesByAngle(pointer, segments) {
    segments.sort((a, b) => getAngleBetweenTwoLines(a.startPointer.equals(pointer) ? a : a.reverse(), segments[0].startPointer.equals(pointer) ? segments[0] : segments[0].reverse()) -
        getAngleBetweenTwoLines(b.startPointer.equals(pointer) ? b : b.reverse(), segments[0].startPointer.equals(pointer) ? segments[0] : segments[0].reverse()));
}
export function getHelpAngleText(centerPointer, segments) {
    const angleHelpers = [];
    const lines = segments.filter((segment) => isLine(segment)).map((s) => s);
    sortLinesByAngle(centerPointer, lines);
    for (let i = 0; i < lines.length; i++) {
        //check neighber angle
        let angleRight = Math.abs(getAngleBetweenTwoLines2(centerPointer, lines[i], lines[i + 1 === lines.length ? 0 : i + 1]));
        if (angleRight > Math.PI)
            angleRight = 2 * Math.PI - angleRight;
        let angleLeft = Math.abs(getAngleBetweenTwoLines2(centerPointer, lines[i], lines[i - 1 === -1 ? lines.length - 1 : i - 1]));
        if (angleLeft > Math.PI)
            angleLeft = 2 * Math.PI - angleLeft;
        let nextLine;
        if (angleRight < angleLeft) {
            nextLine = lines[i + 1 === lines.length ? 0 : i + 1];
        }
        else {
            nextLine = lines[i - 1 === -1 ? lines.length - 1 : i - 1];
        }
        const angle = getHelperTextAngle(nextLine.startPointer, nextLine.endPointer);
        const anotherPointer = centerPointer.equals(nextLine.startPointer) ? nextLine.endPointer : nextLine.startPointer;
        const dx = (anotherPointer.x < centerPointer.x ? -1 : 1) * 10 * Math.cos((Math.abs(angle) * Math.PI) / 180);
        const dy = (anotherPointer.y < centerPointer.y ? -1 : 1) * 10 * Math.sin((Math.abs(angle) * Math.PI) / 180);
        let betweenAngle = getAngleBetweenTwoLines2(centerPointer, lines[i], nextLine);
        let angleSign = -1;
        if ((betweenAngle >= 0 && betweenAngle <= Math.PI) || (betweenAngle <= -Math.PI && betweenAngle >= -2 * Math.PI)) {
            angleSign = 1;
        }
        betweenAngle = angleSign * Math.min(Math.abs(betweenAngle), Math.PI * 2 - Math.abs(betweenAngle));
        const textPointer = centerPointer.translate(dx * Math.cos(betweenAngle / 2) - dy * Math.sin(betweenAngle / 2), dx * Math.sin(betweenAngle / 2) + dy * Math.cos(betweenAngle / 2));
        const angleText = (Math.abs(betweenAngle * 180) / Math.PI).toFixed(0);
        if (Number(angleText) % 180 !== 0) {
            angleHelpers.push({
                angle: (angle * 180) / Math.PI,
                textPointer: textPointer,
                angleText: angleText,
            });
        }
    }
    return angleHelpers;
}
export function getDistanceLineAndPointer(line, pointer) {
    return Math.abs(((line.endPointer.x - line.startPointer.x) * (line.startPointer.y - pointer.y) -
        (line.startPointer.x - pointer.x) * (line.endPointer.y - line.startPointer.y)) /
        Math.sqrt(Math.pow(line.endPointer.x - line.startPointer.x, 2) + Math.pow(line.endPointer.y - line.startPointer.y, 2)));
}
export function getDistancePointer(line, pointer) {
    let result;
    if (Math.abs(line.startPointer.y - line.endPointer.y) < TOLERANCE_DISTANCE) {
        result = new Pointer(pointer.x, line.startPointer.y);
    }
    else if (Math.abs(line.startPointer.x - line.endPointer.x) < TOLERANCE_DISTANCE) {
        result = new Pointer(line.startPointer.x, pointer.y);
    }
    else {
        const a = Math.tan(Math.atan2(line.endPointer.y - line.startPointer.y, line.endPointer.x - line.startPointer.x));
        const b1 = pointer.y - (-1 / a) * pointer.x;
        const b = line.startPointer.y - a * line.startPointer.x;
        const x = (b1 - b) / (a + 1 / a);
        const y = a * x + b;
        result = new Pointer(x, y);
    }
    return result;
}
export function getSnapBuildingPartPoints(segments, pointer) {
    let result;
    let line;
    let distance = Infinity;
    for (const segment of segments) {
        if (isLine(segment)) {
            const dPointer = getDistancePointer(segment, pointer);
            const d = dist(dPointer, pointer);
            if (dPointer.x >= Math.min(segment.startPointer.x, segment.endPointer.x) &&
                dPointer.x <= Math.max(segment.startPointer.x, segment.endPointer.x) &&
                dPointer.y >= Math.min(segment.startPointer.y, segment.endPointer.y) &&
                dPointer.y <= Math.max(segment.startPointer.y, segment.endPointer.y) &&
                d < distance) {
                distance = d;
                line = segment;
                result = dPointer;
            }
        }
    }
    if (distance < PRECISION * 2) {
        return { line: line, pointer: result };
    }
    else {
        return { line: undefined, pointer: undefined };
    }
}
export function getParentLine(segments, parentId) {
    let result = undefined;
    for (const s of segments) {
        if (isLine(s) && s.id === parentId) {
            result = s;
            break;
        }
    }
    return result;
}
export function getParentClosedArea(segments, parentId) {
    let result = undefined;
    for (const s of segments) {
        if (isClosedArea(s) && s.id === parentId) {
            result = s;
            break;
        }
    }
    return result;
}
export function getAllBuildingPartsInSegment(segments, segment) {
    let result = [];
    for (const s of segments) {
        if (isBuildingPart(s) || isDoor(s)) {
            if (s.parentId === segment.id) {
                result.push(s);
            }
        }
    }
    return result;
}
export function getAllBuildingPartsInRoom(segments, room) {
    let result = [];
    for (const s of segments) {
        if (isBuildingPart(s)) {
            if (s.closedAreaId === room.id) {
                result.push(s);
            }
        }
    }
    return result;
}
export function getRemovedBuiltInSegments(segments, segment) {
    let result = [];
    for (const s of segments) {
        if (!(s instanceof Door || s instanceof BuildingPart || s instanceof TileWrapper || s instanceof ClosedArea) ||
            s.parentId !== segment.id) {
            result.push(s);
        }
    }
    return result;
}
export function getHelperTextAngle(startPointer, endPointer) {
    let angle = 0;
    if (endPointer.x === startPointer.x) {
        if (endPointer.y < startPointer.y) {
            angle = Math.PI / 2;
        }
        else {
            angle = -Math.PI / 2;
        }
    }
    else {
        angle = Math.atan(-(endPointer.y - startPointer.y) / (endPointer.x - startPointer.x));
    }
    return -(angle * 180) / Math.PI;
}
export function getHelperPath(startPointer, endPointer, helperLength = 7) {
    let path = '';
    const helpStartPointer = new Pointer(startPointer.x + ((endPointer.y - startPointer.y) * helperLength) / dist(startPointer, endPointer), startPointer.y - ((endPointer.x - startPointer.x) * helperLength) / dist(startPointer, endPointer));
    const helperCenterPointer = new Pointer((startPointer.x + helpStartPointer.x) / 2, (startPointer.y + helpStartPointer.y) / 2);
    path = path + 'M' + startPointer.x + ',' + startPointer.y + ' ' + 'L' + helpStartPointer.x + ',' + helpStartPointer.y;
    const angleRotate = (45 / 180) * Math.PI;
    let deltaX = (startPointer.x - helperCenterPointer.x) * Math.cos(angleRotate) -
        (startPointer.y - helperCenterPointer.y) * Math.sin(angleRotate);
    let deltaY = (startPointer.x - helperCenterPointer.x) * Math.sin(angleRotate) +
        (startPointer.y - helperCenterPointer.y) * Math.cos(angleRotate);
    const helper1 = helperCenterPointer.translate(deltaX, deltaY);
    deltaX =
        (helpStartPointer.x - helperCenterPointer.x) * Math.cos(angleRotate) -
            (helpStartPointer.y - helperCenterPointer.y) * Math.sin(angleRotate);
    deltaY =
        (helpStartPointer.x - helperCenterPointer.x) * Math.sin(angleRotate) +
            (helpStartPointer.y - helperCenterPointer.y) * Math.cos(angleRotate);
    const helper2 = helperCenterPointer.translate(deltaX, deltaY);
    path = path + ' ' + 'M' + helper1.x + ',' + helper1.y + ' ' + 'L' + helper2.x + ',' + helper2.y;
    const helpEndPointer = new Pointer(endPointer.x - ((startPointer.y - endPointer.y) * helperLength) / dist(endPointer, startPointer), endPointer.y + ((startPointer.x - endPointer.x) * helperLength) / dist(endPointer, startPointer));
    path = path + ' ' + 'M' + endPointer.x + ',' + endPointer.y + ' ' + 'L' + helpEndPointer.x + ',' + helpEndPointer.y;
    const helperCenterPointer2 = new Pointer((endPointer.x + helpEndPointer.x) / 2, (endPointer.y + helpEndPointer.y) / 2);
    deltaX =
        (endPointer.x - helperCenterPointer2.x) * Math.cos(angleRotate) -
            (endPointer.y - helperCenterPointer2.y) * Math.sin(angleRotate);
    deltaY =
        (endPointer.x - helperCenterPointer2.x) * Math.sin(angleRotate) +
            (endPointer.y - helperCenterPointer2.y) * Math.cos(angleRotate);
    const helper3 = helperCenterPointer2.translate(deltaX, deltaY);
    deltaX =
        (helpEndPointer.x - helperCenterPointer2.x) * Math.cos(angleRotate) -
            (helpEndPointer.y - helperCenterPointer2.y) * Math.sin(angleRotate);
    deltaY =
        (helpEndPointer.x - helperCenterPointer2.x) * Math.sin(angleRotate) +
            (helpEndPointer.y - helperCenterPointer2.y) * Math.cos(angleRotate);
    const helper4 = helperCenterPointer2.translate(deltaX, deltaY);
    path = path + ' ' + 'M' + helper3.x + ',' + helper3.y + ' ' + 'L' + helper4.x + ',' + helper4.y;
    path =
        path +
            ' ' +
            'M' +
            helperCenterPointer.x +
            ',' +
            helperCenterPointer.y +
            ' ' +
            'L' +
            helperCenterPointer2.x +
            ',' +
            helperCenterPointer2.y;
    return path;
}
export function getTextPointer(startPointer, endPointer, helperLength = 7) {
    const angle = -getHelperTextAngle(startPointer, endPointer);
    const dx = helperLength * Math.cos((angle * Math.PI) / 180);
    const dy = helperLength * Math.sin((angle * Math.PI) / 180);
    let textPointer = new Pointer((startPointer.x + endPointer.x) / 2, (startPointer.y + endPointer.y) / 2);
    textPointer = textPointer.translate((endPointer.x < startPointer.x ? 1.5 : -1) * dy, (endPointer.x < startPointer.x ? 1.5 : -1) * dx);
    return textPointer;
}
export function typeOfSegment(segment) {
    return segment?.class;
}
export function isArc(segment) {
    return segment?.class === SegmentClass.ARC;
}
export function isBuildingPart(segment) {
    return segment?.class === SegmentClass.BUILDING_PART;
}
export function isDoor(segment) {
    return segment?.class === SegmentClass.DOOR;
}
export function isTileWrapper(segment) {
    return segment?.class === SegmentClass.TILE_WRAPPER;
}
export function isClosedArea(segment) {
    return segment?.class === SegmentClass.CLOSED_AREA;
}
export function castTileWrapper(segment) {
    return segment;
}
export function getBuildingElement(id) {
    return BuildingJson.find((item) => item.ids.includes(id));
}
export function getFixedBuildingElement(isWall, type, windowType) {
    if (isWall)
        return WallBuildingJson.find((item) => item.type === type && (!item.windowType || item.windowType === windowType));
    return FixedBuildingJson.find((item) => item.type === type && (!item.windowType || item.windowType === windowType));
}
export function getAllClosedShape(segemnts) {
    const filteredSegments = segemnts.filter((s) => !isBuildingPart(s) && !(s instanceof TileWrapper) && !(s instanceof ClosedArea));
    let existStandAloneLine = false;
    do {
        existStandAloneLine = false;
        for (let index = 0; index < filteredSegments.length; index++) {
            if (filteredSegments.filter((l) => l.startPointer.equals(filteredSegments[index].startPointer) ||
                l.endPointer.equals(filteredSegments[index].startPointer)).length === 1 ||
                filteredSegments.filter((l) => l.startPointer.equals(filteredSegments[index].endPointer) ||
                    l.endPointer.equals(filteredSegments[index].endPointer)).length === 1) {
                existStandAloneLine = true;
                filteredSegments.splice(index, 1);
                break;
            }
        }
    } while (existStandAloneLine);
    const pointsArray = filteredSegments.reduce((result, line) => {
        const updatedResult = [...result];
        if (line instanceof Arc && line.heightPointer && !result.find((p) => p.equals(line.heightPointer))) {
            updatedResult.push(line.heightPointer);
        }
        if (!result.find((p) => p.equals(line.startPointer))) {
            updatedResult.push(line.startPointer);
        }
        if (!result.find((p) => p.equals(line.endPointer))) {
            updatedResult.push(line.endPointer);
        }
        return updatedResult;
    }, []);
    pointsArray.sort((a, b) => a.x - b.x);
    const graph = new Graph(pointsArray.length);
    for (const line of filteredSegments) {
        if (line instanceof Arc) {
            const v = pointsArray.findIndex((p) => p.equals(line.startPointer));
            const m = pointsArray.findIndex((p) => p.equals(line.heightPointer));
            const w = pointsArray.findIndex((p) => p.equals(line.endPointer));
            if (v >= 0 && m >= 0 && w >= 0) {
                graph.addEdge(v, m);
                graph.addEdge(m, w);
            }
        }
        else {
            const v = pointsArray.findIndex((p) => p.equals(line.startPointer));
            const w = pointsArray.findIndex((p) => p.equals(line.endPointer));
            if (v >= 0 && w >= 0) {
                graph.addEdge(v, w);
            }
        }
    }
    const closedShapes = graph.getCyclic().map((cyclic) => {
        return cyclic.reduce((res, point, index) => {
            res.points.push(pointsArray[point]);
            if (index > cyclic.length - 2) {
                return { results: res.results, points: res.points };
            }
            const line = filteredSegments.find((line) => {
                if (isLine(line)) {
                    return ((line.startPointer.equals(pointsArray[point]) &&
                        line.endPointer.equals(pointsArray[cyclic[index + 1]])) ||
                        (line.startPointer.equals(pointsArray[cyclic[index + 1]]) && line.endPointer.equals(pointsArray[point])));
                }
                else if (line instanceof Arc && !res.results.includes(line)) {
                    return (line.heightPointer.equals(pointsArray[point]) || line.heightPointer.equals(pointsArray[cyclic[index + 1]]));
                }
                return false;
            });
            if (line) {
                return { results: [...res.results, line], points: res.points };
            }
            return res;
        }, { results: [], points: [] });
    });
    closedShapes.sort((a, b) => a.points.length - b.points.length);
    return closedShapes.reduce((r, c) => {
        if (r.some((cs) => {
            const collision = intersect(c.points.map((p) => ({ x: p.x, y: p.y })), cs.points.map((p) => ({ x: p.x, y: p.y })));
            return collision && collision.length;
        })) {
            return r;
        }
        return [...r, c];
    }, []);
}
export function getShapeId(shape) {
    const r = [...shape.results];
    // r.sort((a, b) => a.id - b.id);
    return r
        .sort((a, b) => a.id.localeCompare(b.id))
        .reduce((id, segment) => {
        return id + segment.id;
    }, 'shape_');
}
export function getShapeIdFromSegments(segments) {
    return segments
        .sort((a, b) => a.id.localeCompare(b.id))
        .reduce((id, segment) => {
        return id + segment.id;
    }, 'shape_');
}
export function getShapePath(shape) {
    const shapeResults = [...shape.results];
    let path = '';
    let processedSegment = 0;
    let i = 0;
    if (shape.results.some((s) => s instanceof Arc && s.heightPointer.equals(shape.points[i]))) {
        i++;
    }
    while (processedSegment < shape.results.length) {
        let segment;
        let j = i;
        while (!segment) {
            j++;
            if (j === shape.points.length) {
                j = 0;
            }
            if (shape.results.some((s) => s instanceof Arc && s.heightPointer.equals(shape.points[j]))) {
                j++;
                if (j === shape.points.length) {
                    j = 0;
                }
            }
            if (j === i) {
                break;
            }
            segment = shapeResults.find((s) => (s.startPointer.equals(shape.points[i]) && s.endPointer.equals(shape.points[j])) ||
                (s.endPointer.equals(shape.points[i]) && s.startPointer.equals(shape.points[j])));
        }
        if (!segment) {
            path = '';
            break;
        }
        const segmentIndex = shapeResults.indexOf(segment);
        shapeResults.splice(segmentIndex, 1);
        processedSegment++;
        const segmentPath = segment.generatePath(true, shape.points[i]);
        path = path + (segmentPath.length > 0 ? (path ? ' L' : 'M') + segmentPath : '');
        i = j;
    }
    return path;
}
export function isInShape(_pointer, shape) {
    const minX = Math.min(...shape.points.map((p) => p.x));
    const maxX = Math.max(...shape.points.map((p) => p.x));
    const minY = Math.min(...shape.points.map((p) => p.y));
    const maxY = Math.max(...shape.points.map((p) => p.y));
    const pointer = _pointer.translate(0, 0);
    if (pointer.x < minX)
        pointer.x += TOLERANCE_DISTANCE / 2;
    if (pointer.x > maxX)
        pointer.x -= TOLERANCE_DISTANCE / 2;
    if (pointer.y < minY)
        pointer.y += TOLERANCE_DISTANCE / 2;
    if (pointer.y > maxY)
        pointer.y -= TOLERANCE_DISTANCE / 2;
    if (pointer.x >= minX && pointer.x <= maxX && pointer.y >= minY && pointer.y <= maxY) {
        //check if centerMass is in shape
        let crosseds = [];
        let massVector = new Line(new Pointer(pointer.x, pointer.y), new Pointer(maxX + 10000, pointer.y));
        for (const segment of shape.results) {
            if (segment.isInSegment(pointer))
                return true;
            const c = getCrossedPointBetweenSegments(massVector, segment);
            // if (c && c.x > pointer.x)
            if (c && crosseds.filter((v) => v.x === c.x && v.y === c.y).length === 0) {
                crosseds.push(c);
                let endPointA = null;
                if (c.equals(segment.startPointer)) {
                    endPointA = segment.endPointer;
                }
                else if (c.equals(segment.endPointer)) {
                    endPointA = segment.startPointer;
                }
                if (endPointA) {
                    const linked = shape.results.find((other) => other !== segment && (c.equals(other.startPointer) || c.equals(other.endPointer)));
                    if (linked) {
                        const endPointB = c.equals(linked.startPointer) ? linked.endPointer : linked.startPointer;
                        if ((endPointA.y >= pointer.y && endPointB.y >= pointer.y) ||
                            (endPointA.y < pointer.y && endPointB.y < pointer.y)) {
                            crosseds.push(c);
                        }
                    }
                }
            }
        }
        return crosseds.length % 2 === 1;
    }
    return false;
}
export function isInPolygon(pointer, points) {
    const minX = Math.min(...points.map((p) => p.x));
    const maxX = Math.max(...points.map((p) => p.x));
    const minY = Math.min(...points.map((p) => p.y));
    const maxY = Math.max(...points.map((p) => p.y));
    // if( pointer.x < minX ) pointer.x += TOLERANCE_DISTANCE / 2
    // if( pointer.x > maxX ) pointer.x -= TOLERANCE_DISTANCE / 2
    // if( pointer.y < minX ) pointer.y += TOLERANCE_DISTANCE / 2
    // if( pointer.y > maxY ) pointer.y -= TOLERANCE_DISTANCE / 2
    if (pointer.x >= minX && pointer.x <= maxX && pointer.y >= minY && pointer.y <= maxY) {
        //check if centerMass is in shape
        let crosseds = [];
        let massVector = new Line(new Pointer(pointer.x, pointer.y), new Pointer(maxX + 10, pointer.y));
        const segments = [];
        for (let i = 0; i < points.length; i++) {
            segments.push(new Line(points[i], points[(i + 1) % points.length]));
        }
        for (const segment of segments) {
            if (segment.isInSegment(pointer))
                return true;
            const c = getCrossedPointBetweenSegments(massVector, segment);
            // if (c && c.x > pointer.x)
            if (c && crosseds.filter((v) => v.x === c.x && v.y === c.y).length === 0) {
                crosseds.push(c);
                let endPointA = null;
                if (c.equals(segment.startPointer)) {
                    endPointA = segment.endPointer;
                }
                else if (c.equals(segment.endPointer)) {
                    endPointA = segment.startPointer;
                }
                if (endPointA) {
                    const linked = segments.find((other) => other !== segment && (c.equals(other.startPointer) || c.equals(other.endPointer)));
                    if (linked) {
                        const endPointB = c.equals(linked.startPointer) ? linked.endPointer : linked.startPointer;
                        if ((endPointA.y >= pointer.y && endPointB.y >= pointer.y) ||
                            (endPointA.y < pointer.y && endPointB.y < pointer.y)) {
                            crosseds.push(c);
                        }
                    }
                }
            }
        }
        return crosseds.length % 2 === 1;
    }
    return false;
}
export function getShapeBoundingRect(shape, rotation = 0) {
    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
    let pointers = [];
    for (const segment of shape.results) {
        if (isLine(segment)) {
            pointers.push(segment.startPointer);
            pointers.push(segment.endPointer);
        }
        else
            pointers.push(...segment.getPointsArray());
    }
    // const centerPointer = centroid(shape);
    for (const pointer of pointers) {
        minX = Math.min(pointer.x, minX);
        maxX = Math.max(pointer.x, maxX);
        minY = Math.min(pointer.y, minY);
        maxY = Math.max(pointer.y, maxY);
    }
    //center of the rectangle circumscribing the shape
    const centerPointer = new Pointer(minX + (maxX - minX) / 2, minY + (maxY - minY) / 2);
    rotation = -rotation;
    if (rotation % 360 !== 0) {
        pointers.forEach((p, index) => {
            pointers[index] = getRotatedPointer(new Pointer(p.x, p.y), centerPointer, (rotation * Math.PI) / 180);
        });
        // pointers.push(
        //   getRotatedPointer(
        //     new Pointer(minX, minY),
        //     centerPointer,
        //     (rotation * Math.PI) / 180
        //   )
        // );
        // pointers.push(
        //   getRotatedPointer(
        //     new Pointer(maxX, minY),
        //     centerPointer,
        //     (rotation * Math.PI) / 180
        //   )
        // );
        // pointers.push(
        //   getRotatedPointer(
        //     new Pointer(minX, maxY),
        //     centerPointer,
        //     (rotation * Math.PI) / 180
        //   )
        // );
        // pointers.push(
        //   getRotatedPointer(
        //     new Pointer(maxX, maxY),
        //     centerPointer,
        //     (rotation * Math.PI) / 180
        //   )
        // );
        // (minX = Infinity), (maxX = -Infinity), (minY = Infinity), (maxY = -Infinity);
        // for (const pointer of pointers) {
        //   minX = Math.min(pointer.x, minX);
        //   maxX = Math.max(pointer.x, maxX);
        //   minY = Math.min(pointer.y, minY);
        //   maxY = Math.max(pointer.y, maxY);
        // }
    }
    (minX = Infinity), (maxX = -Infinity), (minY = Infinity), (maxY = -Infinity);
    for (const pointer of pointers) {
        minX = Math.min(pointer.x, minX);
        maxX = Math.max(pointer.x, maxX);
        minY = Math.min(pointer.y, minY);
        maxY = Math.max(pointer.y, maxY);
    }
    return [minX, minY, maxX - minX, maxY - minY];
}
export function area(points) {
    let area = 0, i, j, point1, point2;
    for (i = 0, j = points.length - 1; i < points.length; j = i, i += 1) {
        point1 = points[i];
        point2 = points[j];
        area += point1.x * point2.y;
        area -= point1.y * point2.x;
    }
    area /= 2;
    return area;
}
export function centroid(shape) {
    const points = shape.points.map((v) => [v.x, v.y]);
    const result = polylabel([points]);
    return new Pointer(result[0], result[1]);
}
export function getCentroidFromId(shapes, shapeId) {
    for (const shape of shapes) {
        if (getShapeId(shape) === shapeId) {
            return centroid(shape);
        }
    }
}
export function getNewLinesByCrossed(segments, movingSegment) {
    const updatedCurrent = [];
    const crossedPointers = [];
    for (const segment of segments) {
        if (movingSegment !== segment) {
            if (isLine(segment)) {
                const cP = getCrossedPointBetweenSegments(segment, movingSegment);
                if (cP) {
                    if (dist(cP, segment.startPointer) < TOLERANCE_DISTANCE ||
                        dist(cP, segment.endPointer) < TOLERANCE_DISTANCE) {
                        updatedCurrent.push(segment);
                    }
                    else {
                        updatedCurrent.push(new Line(segment.startPointer, cP, segment.type, segment.parentId));
                        updatedCurrent.push(new Line(cP, segment.endPointer, segment.type, segment.parentId));
                    }
                    if (dist(cP, movingSegment.startPointer) > TOLERANCE_DISTANCE &&
                        dist(cP, movingSegment.endPointer) > TOLERANCE_DISTANCE) {
                        crossedPointers.push(cP);
                    }
                }
                else {
                    updatedCurrent.push(segment);
                }
            }
            else {
                updatedCurrent.push(segment);
            }
        }
    }
    crossedPointers.push(movingSegment.startPointer);
    crossedPointers.push(movingSegment.endPointer);
    const sorted = crossedPointers.sort((a, b) => a.x - b.x);
    if (sorted.length > 2) {
        sorted.forEach((cP, index) => {
            if (index < sorted.length - 1) {
                if (movingSegment instanceof Arc) {
                    const pointsArray = movingSegment.getPointsArray();
                    let minDist = Infinity;
                    const startIndex = pointsArray.reduce((r, pt, ptIndex) => {
                        const currentDist = dist(pt, cP);
                        if (currentDist < minDist) {
                            r = ptIndex;
                            minDist = currentDist;
                        }
                        return r;
                    }, -1);
                    minDist = Infinity;
                    const endIndex = pointsArray.reduce((r, pt, ptIndex) => {
                        const currentDist = dist(pt, sorted[index + 1]);
                        if (currentDist < minDist) {
                            r = ptIndex;
                            minDist = currentDist;
                        }
                        return r;
                    }, -1);
                    if (startIndex != -1 && endIndex != -1) {
                        const heightPointer = pointsArray[Math.round((startIndex + endIndex) / 2)];
                        updatedCurrent.push(new Arc(cP, sorted[index + 1], heightPointer));
                    }
                }
                else {
                    updatedCurrent.push(new Line(cP, sorted[index + 1], movingSegment.type, movingSegment.parentId));
                }
            }
        });
    }
    else {
        updatedCurrent.push(movingSegment);
    }
    updatedCurrent.sort((a, b) => a.id.localeCompare(b.id));
    return updatedCurrent;
}
export const getSegmentsExceptDashedLine = (segments) => {
    return segments.filter((v) => (isLine(v) ? !v.isDashed() : true));
};
export function getUpdatedTiles(current, closedAreas, tiles, lastLayout, layoutGeometryId, movedObjectIds, selectedObject, isWallType) {
    if (current.length === 0) {
        return {
            closedAreas: [],
            tileWrappers: [],
            segments: [],
        };
    }
    const updatedClosedAreaShapes = getAllClosedShape(getSegmentsExceptDashedLine(current));
    const updatedClosedAreas = [];
    closedAreas.forEach((closedArea) => {
        const key = getShapeId(closedArea.shape);
        const shape = updatedClosedAreaShapes.find((s) => getShapeId(s) === key);
        if (shape) {
            closedArea.shape = shape;
            if (closedArea.name.length === 0)
                closedArea.altName = getNewSegmentName(SegmentClass.CLOSED_AREA, updatedClosedAreas);
            updatedClosedAreas.push(closedArea);
        }
        else {
            const formerShape = closedArea.shape;
            if (formerShape) {
                for (const us of updatedClosedAreaShapes) {
                    const intersectPolygons = intersect(us.points.map((p) => ({ x: p.x, y: p.y })), formerShape.points.map((p) => ({ x: p.x, y: p.y })));
                    if (intersectPolygons && intersectPolygons.length) {
                        for (const intersectPolygon of intersectPolygons) {
                            if ((intersectPolygon.length === us.points.length - 1 ||
                                intersectPolygon.length === formerShape.points.length - 1) &&
                                intersectPolygon.every((p) => us.points.some((pointer) => Math.abs(pointer.x - p.x) < TOLERANCE_DISTANCE && Math.abs(pointer.y - p.y) < TOLERANCE_DISTANCE))) {
                                const newId = getShapeId(us);
                                if (!updatedClosedAreas.some((t) => getShapeId(t.shape) === newId)) {
                                    const newClosedArea = closedArea.clone();
                                    newClosedArea.id = uuidv4();
                                    newClosedArea.shape = us;
                                    newClosedArea.name = '';
                                    newClosedArea.altName = getNewSegmentName(SegmentClass.CLOSED_AREA, updatedClosedAreas);
                                    // updateBuildingPartWithRoom(current, closedArea, newClosedArea);
                                    updatedClosedAreas.push(newClosedArea);
                                }
                            }
                            else {
                                // console.log(
                                //   "++++++++++++++++++++",
                                //   intersectPolygon,
                                //   us.points
                                // );
                            }
                        }
                    }
                }
            }
        }
    });
    const updatedClosedShape = getAllClosedShape(current);
    const updatedTiles = [];
    tiles.forEach((tileWrapper) => {
        const key = getShapeId(tileWrapper.shape);
        const shape = updatedClosedShape.find((s) => getShapeId(s) === key);
        if (shape) {
            //when area has same lines
            if (tileWrapper.name.length === 0)
                tileWrapper.altName = getNewSegmentName(SegmentClass.TILE_WRAPPER, updatedTiles);
            if (movedObjectIds && movedObjectIds.some((id) => key.includes(id))) {
                // when area has been changed by movedObjects
                tileWrapper.shape = shape;
                tileWrapper.updateLayout = true;
            }
            updatedTiles.push(tileWrapper);
        }
        else {
            const formerShape = tileWrapper.shape;
            const matchedShapes = [];
            if (formerShape) {
                for (const us of updatedClosedShape) {
                    let pointsToCheck = [];
                    if (selectedObject && key.includes(selectedObject.id)) {
                        pointsToCheck = us.points.filter((pt) => !pt.equals(selectedObject.startPointer) && !pt.equals(selectedObject.endPointer));
                    }
                    if (pointsToCheck.length > 0 &&
                        pointsToCheck.every((pt) => formerShape.points.some((pt2) => pt.equals(pt2)))) {
                        // when area has same points except moved points
                        matchedShapes.push(us);
                    }
                    else {
                        const intersectPolygons = intersect(us.points.map((p) => ({ x: p.x, y: p.y })), formerShape.points.map((p) => ({ x: p.x, y: p.y })));
                        if (intersectPolygons && intersectPolygons.length) {
                            for (const intersectPolygon of intersectPolygons) {
                                if ((intersectPolygon.length === us.points.length - 1 ||
                                    intersectPolygon.length === formerShape.points.length - 1) &&
                                    (intersectPolygon.every((p) => us.points.some((pointer) => Math.abs(pointer.x - p.x) < TOLERANCE_DISTANCE &&
                                        Math.abs(pointer.y - p.y) < TOLERANCE_DISTANCE)) ||
                                        intersectPolygon.every((p) => formerShape.points.some((pointer) => Math.abs(pointer.x - p.x) < TOLERANCE_DISTANCE &&
                                            Math.abs(pointer.y - p.y) < TOLERANCE_DISTANCE)))) {
                                    matchedShapes.push(us);
                                }
                                else {
                                    // console.log(
                                    //   "++++++++++++++++++++",
                                    //   intersectPolygon,
                                    //   us.points
                                    // );
                                }
                            }
                        }
                    }
                }
                matchedShapes.forEach((us) => {
                    const newId = getShapeId(us);
                    if (!updatedTiles.some((t) => getShapeId(t.shape) === newId)) {
                        const line = tileWrapper.parentId ? getParentLine(us.results, tileWrapper.parentId) : undefined;
                        const boundingRect = getShapeBoundingRect(us, line ? (line.getLineAngle() * 180) / Math.PI : tileWrapper.rotation);
                        const newTileWrapper = tileWrapper.clone();
                        newTileWrapper.id = uuidv4();
                        newTileWrapper.shape = us;
                        newTileWrapper.tileLayout = tileWrapper.tileLayout.resize(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
                        newTileWrapper.tileLayout.id = uuidv4();
                        newTileWrapper.name = '';
                        newTileWrapper.altName = getNewSegmentName(SegmentClass.TILE_WRAPPER, updatedTiles);
                        updatedTiles.push(newTileWrapper);
                    }
                });
            }
        }
    });
    updatedClosedAreaShapes.forEach((cs) => {
        if (!updatedClosedAreas.some((t) => getShapeId(t.shape) === getShapeId(cs))) {
            if (!checkClockwise(cs)) {
                for (let i = 0; i < cs.results.length; i++) {
                    const temp = cs.results[i].startPointer;
                    cs.results[i].startPointer = cs.results[i].endPointer;
                    cs.results[i].endPointer = temp;
                }
            }
            const newRoomName = getNewSegmentName(SegmentClass.CLOSED_AREA, updatedClosedAreas);
            const newClosedArea = new ClosedArea(new Pointer(0, 0), new Pointer(0, 0), uuidv4(), 0, cs, 0, '');
            newClosedArea.altName = newRoomName;
            updatedClosedAreas.push(newClosedArea);
        }
    });
    updatedClosedShape.forEach((cs) => {
        if (!updatedTiles.some((t) => getShapeId(t.shape) === getShapeId(cs))) {
            if (!checkClockwise(cs)) {
                for (let i = 0; i < cs.results.length; i++) {
                    const temp = cs.results[i].startPointer;
                    cs.results[i].startPointer = cs.results[i].endPointer;
                    cs.results[i].endPointer = temp;
                }
            }
            const boundingRect = getShapeBoundingRect(cs);
            const newAreaName = getNewSegmentName(SegmentClass.TILE_WRAPPER, updatedTiles);
            const newLayout = lastLayout.resize(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
            newLayout.id = uuidv4();
            const newTileWrapper = new TileWrapper(new Pointer(0, 0), new Pointer(0, 0), uuidv4(), 0, 0, cs, newLayout, 0, new Pointer(0, 0), undefined, 0, '');
            newTileWrapper.altName = newAreaName;
            newTileWrapper.setLayoutInfo(layoutGeometryId, lastLayout.getGraph().tiles);
            // newTileWrapper.initializeOverrideAspectRatio(layoutGeometries, lastLayout.shapes)
            updatedTiles.push(newTileWrapper);
        }
    });
    updatedTiles.forEach((tileWrapper) => {
        const segments = getSegmentsExceptDashedLine(tileWrapper.shape.results);
        if (tileWrapper.closedAreaId) {
            const parentClosedArea = updatedClosedAreas.filter((v) => v.id === tileWrapper.closedAreaId);
            if (parentClosedArea.length === 0 ||
                !segments.every((seg) => parentClosedArea[0].shape.results.filter((v) => v.id === seg.id).length)) {
                tileWrapper.closedAreaId = 0;
            }
        }
        if (tileWrapper.closedAreaId === 0) {
            updatedClosedAreas.forEach((closedArea) => {
                if (tileWrapper.closedAreaId)
                    return;
                if (segments.every((seg) => closedArea.shape.results.filter((v) => v.id === seg.id).length)) {
                    tileWrapper.closedAreaId = closedArea.id;
                }
            });
        }
    });
    updatedClosedAreas.forEach((closedArea) => {
        closedArea.tileWrappers = updatedTiles.filter((v) => v.closedAreaId === closedArea.id);
    });
    const updatedSegments = [];
    current.forEach((segment) => {
        if (isClosedArea(segment) || isTileWrapper(segment))
            return;
        if (isLine(segment)) {
            if (segment.isDashed()) {
                const line = segment;
                let i = 0;
                line.parentId = 0;
                while (i < updatedClosedAreas.length) {
                    if (checkLineInClosedArea(updatedClosedAreas[i], line)) {
                        line.parentId = updatedClosedAreas[i].id;
                        break;
                    }
                    i++;
                }
                if (line.parentId) {
                    updatedSegments.push(line);
                }
            }
            else {
                if (segment.name.length === 0) {
                    segment.name = getNewSegmentName(SegmentClass.LINE, current);
                }
                updatedSegments.push(segment);
            }
        }
        else if ((isWallType && isDoor(segment)) || isBuildingPart(segment)) {
            const buildingPart = segment;
            if (updatedClosedAreas.some((closedArea) => closedArea.id === buildingPart.closedAreaId)) {
                updatedSegments.push(buildingPart);
            }
            else {
                const closedArea = updatedClosedAreas.find((closedArea) => checkBuildingPartInRoom(buildingPart, closedArea));
                if (closedArea) {
                    buildingPart.closedAreaId = closedArea.id;
                }
                else {
                    buildingPart.closedAreaId = 0;
                }
                updatedSegments.push(buildingPart);
            }
        }
        else if (isDoor(segment)) {
            const door = segment;
            if (updatedClosedAreaShapes.some(({ results }) => results.find((v) => v.id === door.parentId))) {
                updatedSegments.push(door);
            }
            else {
                let lineWithDoor = undefined;
                updatedClosedAreaShapes.forEach(({ results }) => {
                    if (!!lineWithDoor)
                        return;
                    results.forEach((v) => {
                        if (!!lineWithDoor)
                            return;
                        if (v.isInSegment(door.startPointer)) {
                            lineWithDoor = v;
                        }
                    });
                });
                if (lineWithDoor) {
                    door.closedAreaId = lineWithDoor.id;
                    door.parentId = lineWithDoor.id;
                }
                else {
                    door.closedAreaId = 0;
                    door.parentId = 0;
                }
                updatedSegments.push(door);
            }
        }
        else {
            updatedSegments.push(segment);
        }
    });
    return {
        closedAreas: updatedClosedAreas,
        tileWrappers: updatedTiles,
        segments: updatedSegments,
    };
}
export function getClosedAreas(segemnts) {
    return segemnts.reduce((r, c) => {
        if (c instanceof ClosedArea) {
            return [...r, c];
        }
        return r;
    }, []);
}
export function getTileLayouts(segemnts) {
    return segemnts.reduce((r, c) => {
        if (c instanceof TileWrapper) {
            return [...r, c];
        }
        return r;
    }, []);
}
export function getRotatedPointer(pointer, centerPointer, angle) {
    let deltaX = pointer.x - centerPointer.x;
    let deltaY = pointer.y - centerPointer.y;
    return new Pointer(deltaX * Math.cos(angle) - deltaY * Math.sin(angle) + centerPointer.x, deltaX * Math.sin(angle) + deltaY * Math.cos(angle) + centerPointer.y);
}
export function getSvgPointersOfTile(tile, tileWrapper) {
    const bounding = getShapeBoundingRect(tileWrapper.shape);
    const transform = new Matrix2D(tile.transform.elements.map((e) => e * TILE_TRANSFORM_SCALE));
    const svgPointers = [];
    for (const segment of tile.shape.path.segments) {
        const vector = transform.mulVec({
            x: segment.start.x,
            y: segment.start.y, // + segment.end.y) / 2,
        });
        const rotatedPointer = getRotatedPointer(new Pointer(vector.x, vector.y), new Pointer(0, 0), (tileWrapper.rotation * Math.PI) / 180);
        const svgPointer = rotatedPointer.translate(bounding[0] + bounding[2] / 2 + tileWrapper.offset.x, bounding[1] + bounding[3] / 2 + tileWrapper.offset.y);
        svgPointers.push(svgPointer);
    }
    const centerSvgPointer = svgPointers.reduce((r, p) => r.translate(p.x, p.y), new Pointer(0, 0));
    svgPointers.push(centerSvgPointer.translateWithRate(1 / svgPointers.length));
    return svgPointers;
}
export function getTileSnapPointers(tileWrapper, snapTile) {
    const snapTilepointers = [];
    const snapLinePointers = [];
    let resultElementLinePointer;
    let deltaAngle = 0;
    let lines = [];
    let minDistX = Infinity, minDistY = Infinity;
    let resultDistance = Infinity;
    const svgPointers = getSvgPointersOfTile(snapTile, tileWrapper);
    if (isInShape(svgPointers[svgPointers.length - 1], tileWrapper.shape)) {
        let isPrevSnapped = false;
        svgPointers.forEach((sP) => {
            if (!snapTilepointers.some((p) => p.equals(sP))) {
                const { line: resultLine, pointer: linePointer } = getSnapBuildingPartPoints(tileWrapper.shape.results, sP);
                const distance = linePointer ? dist(linePointer, sP) : 0;
                if (resultLine &&
                    linePointer &&
                    distance < Layout.DEFAULT_MARGIN * TILE_TRANSFORM_SCALE + 0.75 &&
                    ((distance < resultDistance && !isPrevSnapped) || tileWrapper.parentId === resultLine.id)) {
                    resultDistance = distance;
                    snapTilepointers.pop();
                    snapLinePointers.pop();
                    lines.pop();
                    snapTilepointers.push(sP);
                    snapLinePointers.push(linePointer);
                    lines.push(resultLine);
                    if (linePointer.x !== sP.x && Math.abs(linePointer.x - sP.x) <= Math.abs(minDistX)) {
                        minDistX = linePointer.x - sP.x;
                    }
                    if (linePointer.y - sP.y && Math.abs(linePointer.y - sP.y) <= Math.abs(minDistY)) {
                        minDistY = linePointer.y - sP.y;
                    }
                    isPrevSnapped = tileWrapper.parentId === resultLine.id;
                }
            }
        });
    }
    if (snapTilepointers.length === 1) {
        let leftPointer, rightPointer;
        const index = svgPointers.findIndex((sP) => sP.equals(snapTilepointers[0]));
        if (index > 0 && index < svgPointers.length - 1) {
            leftPointer = svgPointers[index - 1];
        }
        else if (index === 0) {
            leftPointer = svgPointers[svgPointers.length - 2];
        }
        else if (index === svgPointers.length - 1) {
            leftPointer = svgPointers[0].translate(svgPointers[1].x, svgPointers[1].y).translateWithRate(0.5);
        }
        if (index < svgPointers.length - 2) {
            rightPointer = svgPointers[index + 1];
        }
        else if (index === svgPointers.length - 2) {
            rightPointer = svgPointers[0];
        }
        else if (index === svgPointers.length - 1) {
            rightPointer = svgPointers[1].translate(svgPointers[2].x, svgPointers[2].y).translateWithRate(0.5);
        }
        if (getAngleBetweenTwoLinesInPI(lines[0], new Line(snapTilepointers[0], leftPointer)) <
            getAngleBetweenTwoLinesInPI(lines[0], new Line(snapTilepointers[0], rightPointer))) {
            resultElementLinePointer = leftPointer;
        }
        else {
            resultElementLinePointer = rightPointer;
        }
        const snapElementLine = new Line(snapTilepointers[0], resultElementLinePointer);
        let centerAngle = snapElementLine.getLineAngle();
        if (centerAngle < 0)
            centerAngle += Math.PI;
        let lineAngle = lines[0].getLineAngle();
        if (lineAngle < 0)
            lineAngle += Math.PI;
        deltaAngle = Math.abs((((lineAngle - centerAngle) * 180) / Math.PI) % 90);
        if (deltaAngle > 45) {
            deltaAngle = 90 - deltaAngle;
        }
        const snapRotatedPointer = getRotatedPointer(resultElementLinePointer, snapTilepointers[0], (deltaAngle * Math.PI) / 180);
        let snappedAngle = new Line(snapTilepointers[0], snapRotatedPointer).getLineAngle();
        if (snappedAngle < 0)
            snappedAngle += Math.PI;
        if (Math.abs(snappedAngle - lineAngle) > TOLERANCE_ANGLE) {
            deltaAngle *= -1;
        }
    }
    return {
        lines,
        snapTilepointers,
        snapLinePointers,
        minDistX,
        minDistY,
        resultElementLinePointer,
        deltaAngle,
    };
}
export function tileInShape(tile, tileWrapper) {
    let svgPointers = getSvgPointersOfTile(tile, tileWrapper);
    if (tileWrapper.parentId && tileWrapper.snapTile) {
        const snapInfo = tileWrapper.getSnapInfos();
        svgPointers = svgPointers.map((p) => getRotatedPointer(p, snapInfo.snapLinePointer1, (snapInfo.deltaAngle * Math.PI) / 180));
    }
    const pointersInShape = svgPointers.some((p) => isInShape(p, tileWrapper.shape));
    if (pointersInShape)
        return true;
    const intersectPolygons = intersect(svgPointers.map((p) => ({ x: p.x, y: p.y })), tileWrapper.shape.points.map((p) => ({ x: p.x, y: p.y })));
    return intersectPolygons && intersectPolygons.length;
}
export function getSquareWithUnit(area, unit, accurate) {
    if (unit === METRIC_UNITS[0]) {
        return (accurate ? Math.round(area) / 10000 : Math.round(area / 100) / 100) + 'm²';
    }
    else if (unit === METRIC_UNITS[1]) {
        const areaImp = area / (FEET * FEET);
        return (accurate ? Math.round(areaImp) / 10000 : Math.round(areaImp * 100) / 100) + 'ft²';
    }
}
export function getMetricWithUnit(length, unit, forInput = false, isSmall = true) {
    if (unit === METRIC_UNITS[0]) {
        const ret = Number((length / (isSmall ? 1 : 100)).toFixed(2));
        if (forInput)
            return ret;
        return ret + (isSmall ? METRIC_SMALL_UNITS[0] : unit);
    }
    else {
        if (forInput) {
            return Number((Math.round(length * 100) / 100 / ((isSmall ? 1 : 12) * INCH)).toFixed(3));
        }
        let result = '';
        const ft = isSmall ? 0 : Math.floor(length / FEET);
        if (ft) {
            result += `${ft}'`;
        }
        const left = Math.round((length - ft * FEET) * 100000) / 100000;
        const inch = Math.floor(left / INCH);
        if (inch) {
            result += ft ? ` ${inch}` : inch;
        }
        const inchLeft = Math.round((left - inch * INCH) * 100000) / 100000;
        if (inchLeft >= (INCH * 15) / 16) {
            result += ` 15/16"`;
        }
        else if (inchLeft >= (INCH * 14) / 16) {
            result += ` 7/8"`;
        }
        else if (inchLeft >= (INCH * 13) / 16) {
            result += ` 13/16"`;
        }
        else if (inchLeft >= (INCH * 12) / 16) {
            result += ` 3/4"`;
        }
        else if (inchLeft >= (INCH * 11) / 16) {
            result += ` 11/16"`;
        }
        else if (inchLeft >= (INCH * 10) / 16) {
            result += ` 5/8"`;
        }
        else if (inchLeft >= (INCH * 9) / 16) {
            result += ` 9/16"`;
        }
        else if (inchLeft >= (INCH * 8) / 16) {
            result += ` 1/2"`;
        }
        else if (inchLeft >= (INCH * 7) / 16) {
            result += ` 7/16"`;
        }
        else if (inchLeft >= (INCH * 6) / 16) {
            result += ` 3/8"`;
        }
        else if (inchLeft >= (INCH * 5) / 16) {
            result += ` 5/16"`;
        }
        else if (inchLeft >= (INCH * 4) / 16) {
            result += ` 1/4"`;
        }
        else if (inchLeft >= (INCH * 3) / 16) {
            result += ` 3/16"`;
        }
        else if (inchLeft >= (INCH * 2) / 16) {
            result += ` 1/8"`;
        }
        else if (inchLeft >= (INCH * 1) / 16) {
            result += ` 1/16"`;
        }
        else {
            result += inch ? '"' : '';
        }
        return result;
    }
}
export function parseImperialUnit(length) {
    const regex = new RegExp(/([0-9]+' )?(([0-9]+")|(([0-9]+ )?([1-9]+)\/([0-9]+)"))|([0-9]+')/);
    if (regex.test(length)) {
        const result = regex.exec(length);
        const feet = parseInt(result[1] || result[8] || '0');
        const inches = parseInt(result[3] || result[5] || '0');
        const m_inches = parseInt(result[6] || '0');
        const n_inches = parseInt(result[7] || '1');
        return feet * 12 + inches + m_inches / n_inches;
    }
    return 0;
}
// export const throttleRedraw = throttle(
//   (tileWrapper: TileWrapper, layout: RealizedLayout) => {
//     const bounding = getShapeBoundingRect(
//       tileWrapper.shape,
//       tileWrapper.rotation
//     );
//     tileWrapper.tileLayout = layout.resize(
//       bounding[2] / TILE_SCALE,
//       bounding[3] / TILE_SCALE
//     drawSend({ type: "MOUSE_MOVE" });
//   },
//   200
// );
export const getRoundedAngle = (angleRad) => {
    const angle = Math.round((angleRad * 180) / Math.PI);
    return (angle * Math.PI) / 180;
};
export const getSplitLineOfTileWrapper = (segment) => {
    let result = [];
    const boundingRect = getShapeBoundingRect(segment.shape);
    for (const seg of segment.shape.results) {
        if (isLine(seg)) {
            const lineSegment = seg;
            const angle = Math.round((lineSegment.getLineAngle() * 180) / Math.PI) % 180;
            if (angle % 90 === 0) {
                let extendedLine = lineSegment.clone();
                if (angle === 0) {
                    extendedLine.startPointer.x = boundingRect[0];
                    extendedLine.endPointer.x = boundingRect[0] + boundingRect[2];
                }
                else {
                    extendedLine.startPointer.y = boundingRect[1];
                    extendedLine.endPointer.y = boundingRect[1] + boundingRect[3];
                }
                let leftExtend;
                let rightExtend;
                segment.shape.results.forEach((other) => {
                    if (other === seg)
                        return;
                    if (isLine(other)) {
                        const otherSegment = other;
                        const angleOther = Math.round((otherSegment.getLineAngle() * 180) / Math.PI) % 180;
                        if (angle === angleOther)
                            return;
                    }
                    const crossPoint = getCrossedPointBetweenSegments(extendedLine, other);
                    if (crossPoint &&
                        dist(crossPoint, lineSegment.startPointer) > 1 &&
                        dist(crossPoint, lineSegment.endPointer) > 1) {
                        if (angle === 0) {
                            // horizontal
                            const leftPoint = lineSegment.getLeftPointer();
                            const rightPoint = lineSegment.getRightPointer();
                            if (crossPoint.x < leftPoint.x) {
                                leftExtend = leftExtend
                                    ? leftExtend.startPointer.x < crossPoint.x
                                        ? new Line(crossPoint, leftPoint)
                                        : leftExtend
                                    : new Line(crossPoint, leftPoint);
                            }
                            else if (crossPoint.x > rightPoint.x) {
                                rightExtend = rightExtend
                                    ? rightExtend.endPointer.x > crossPoint.x
                                        ? new Line(rightPoint, crossPoint)
                                        : rightExtend
                                    : new Line(rightPoint, crossPoint);
                            }
                        }
                        else {
                            // vertical
                            const topPoint = lineSegment.getLeftPointer();
                            const bottomPoint = lineSegment.getRightPointer();
                            if (crossPoint.y < topPoint.y) {
                                leftExtend = leftExtend
                                    ? leftExtend.startPointer.y < crossPoint.y
                                        ? new Line(crossPoint, topPoint)
                                        : leftExtend
                                    : new Line(crossPoint, topPoint);
                            }
                            else if (crossPoint.y > bottomPoint.y) {
                                rightExtend = rightExtend
                                    ? rightExtend.endPointer.y > crossPoint.y
                                        ? new Line(bottomPoint, crossPoint)
                                        : rightExtend
                                    : new Line(bottomPoint, crossPoint);
                            }
                        }
                    }
                });
                if (leftExtend && isInShape(leftExtend.getCenterPointer(), segment.shape)) {
                    result.push(leftExtend);
                }
                if (rightExtend && isInShape(rightExtend.getCenterPointer(), segment.shape)) {
                    result.push(rightExtend);
                }
            }
        }
    }
    const filtered = result.reduce((r, c) => {
        if (r.some((cs) => dist(cs.startPointer, c.startPointer) < 1 && dist(cs.endPointer, c.endPointer) < 1)) {
            return r;
        }
        return [...r, c];
    }, []);
    const splitLines = [];
    // remove existing lines in shape
    for (const splitLine of filtered) {
        if (!segment.shape.results.find((r) => {
            if (isLine(r)) {
                const line = r;
                return ((line.startPointer.equals(splitLine.startPointer) && line.endPointer.equals(splitLine.endPointer)) ||
                    (line.startPointer.equals(splitLine.endPointer) && line.endPointer.equals(splitLine.startPointer)));
            }
            return false;
        })) {
            splitLines.push(splitLine);
        }
    }
    // remove overlapped lines in shape
    // should be implemented
    return []; // splitLines;
};
export const getTileWrapperSnapPoints = (segment) => {
    const splitLines = getSplitLineOfTileWrapper(segment);
    let snapPoints;
    let newSegments = [...segment.shape.results];
    newSegments = newSegments
        .map((seg) => {
        let crosseds = [];
        if (!isLine(seg))
            return [];
        splitLines.forEach((other) => {
            if (other === seg)
                return;
            const crossPoint = getCrossedPointBetweenSegments(seg, other);
            if (crossPoint && !crossPoint.equals(seg.startPointer) && !crossPoint.equals(seg.endPointer)) {
                crosseds.push(crossPoint);
            }
        });
        crosseds.sort((a, b) => (a.x === b.x ? a.y - b.y : a.x - b.x));
        const result = [];
        let startPointer = seg.startPointer;
        for (let i = 0; i < crosseds.length; i++) {
            result.push(new Line(startPointer, crosseds[i]));
            startPointer = crosseds[i];
        }
        result.push(new Line(startPointer, seg.endPointer));
        return result;
    })
        .flat();
    const closedShapes = getAllClosedShape(newSegments);
    const snapPointsOfLine = newSegments
        .map((seg) => (isLine(seg) ? seg.getCenterPointer() : null))
        .filter((seg) => seg !== null);
    snapPoints = closedShapes.map((shape) => centroid(shape));
    snapPoints = [...snapPoints, ...snapPointsOfLine];
    return {
        lines: splitLines,
        snapPoints: snapPoints,
    };
};
export function getClosedAreaWithPointer(segments, pointer) {
    for (let i = 0; i < segments.length; i++) {
        if (isClosedArea(segments[i])) {
            const closedArea = segments[i];
            if (isInShape(pointer, closedArea.shape)) {
                return closedArea;
            }
        }
    }
    return null;
}
export function getClosedAreaByLine(segments, line) {
    const rooms = [];
    for (let i = 0; i < segments.length; i++) {
        if (isClosedArea(segments[i])) {
            const room = segments[i];
            const result = room.shape.results.find((item) => item.id === line.id);
            if (result) {
                rooms.push(room);
            }
        }
    }
    return rooms;
}
export function checkPointerInClosedArea(segments, pointer) {
    return getClosedAreaWithPointer(segments, pointer) !== null;
}
export function checkLineInClosedArea(closedArea, line) {
    return isInShape(line.startPointer, closedArea.shape) && isInShape(line.endPointer, closedArea.shape);
}
export function refreshClosedArea(segments, closedArea) {
    let { results: closedSegments, points } = closedArea.shape;
    /////////////////////////
    // remove unnecessary points
    points.forEach((point) => {
        const segmentsOnPoint = getSegmentsOfPoint(segments, point);
        if (segmentsOnPoint.length === 2 && isLine(segmentsOnPoint[0]) && isLine(segmentsOnPoint[1])) {
            let line1 = segmentsOnPoint[0];
            let line2 = segmentsOnPoint[1];
            let angle1 = line1.getLineAngle();
            let angle2 = line2.getLineAngle();
            if (angle1 < 0)
                angle1 += Math.PI;
            if (angle2 < 0)
                angle2 += Math.PI;
            if (!line1.isDashed() && !line2.isDashed() && Math.abs(angle1 - angle2) < TOLERANCE_ANGLE) {
                if (line1.startPointer.equals(point)) {
                    line1.startPointer = line2.startPointer.equals(point)
                        ? line2.endPointer.translate(0, 0)
                        : line2.startPointer.translate(0, 0);
                }
                else if (line1.endPointer.equals(point)) {
                    line1.endPointer = line2.startPointer.equals(point)
                        ? line2.endPointer.translate(0, 0)
                        : line2.startPointer.translate(0, 0);
                }
                const removeIdx = segments.findIndex((v) => v.id === line2.id);
                if (removeIdx !== -1) {
                    segments.splice(removeIdx, 1);
                }
                closedSegments = closedSegments.filter((v) => v.id !== line2.id);
            }
        }
    });
}
export function splitLinesForDashed(segments) {
    /////////////////////////
    // add cross points
    const dashedLines = segments.filter((v) => isLine(v) && v.isDashed());
    dashedLines.forEach((dashedLine) => {
        const newSegments = [];
        segments.forEach((segment, index) => {
            if (isLine(segment) && !segment.isDashed()) {
                let crossedPoint = getCrossedPointBetweenTwoLines(dashedLine, segment);
                if (!crossedPoint) {
                    if (segment.isInSegment(dashedLine.startPointer) &&
                        segment.isInSegment(dashedLine.startPointer, false)) {
                        crossedPoint = dashedLine.startPointer;
                    }
                    else if (segment.isInSegment(dashedLine.endPointer) &&
                        segment.isInSegment(dashedLine.endPointer, false)) {
                        crossedPoint = dashedLine.endPointer;
                    }
                }
                if (crossedPoint && !crossedPoint.equals(segment.startPointer) && !crossedPoint.equals(segment.endPointer)) {
                    const newLine = segment.clone();
                    newLine.id = uuidv4();
                    newLine.startPointer = crossedPoint.translate(0, 0);
                    segment.endPointer = crossedPoint.translate(0, 0);
                    segments.splice(index, 0, newLine);
                }
            }
        });
    });
}
export function checkBuildingPartInRoom(buildingPart, closedArea) {
    const [pointer1, pointer2, pointer3, pointer4] = buildingPart.getRectPoints();
    let isInRoom = true;
    if (!isInShape(pointer1, closedArea.shape)) {
        isInRoom = false;
    }
    if (!isInShape(pointer2, closedArea.shape)) {
        isInRoom = false;
    }
    if (!isInShape(pointer3, closedArea.shape)) {
        isInRoom = false;
    }
    if (!isInShape(pointer4, closedArea.shape)) {
        isInRoom = false;
    }
    return isInRoom;
}
export function updateBuildingPartWithRoom(segments, oldRoom, newRoom) {
    for (let i = 0; i < segments.length; i++) {
        if (isBuildingPart(segments[i])) {
            const buildingPart = segments[i];
            if (buildingPart.closedAreaId === oldRoom.id) {
                buildingPart.closedAreaId = newRoom.id;
            }
        }
    }
}
export const createDoor = (room, line, type, name, isWall) => {
    const defaultLineWidth = type === DOORTYPE.DOOR ? 90 : 120;
    const defaultLineLength = type === DOORTYPE.DOOR ? 210 : 120;
    let startPointer;
    let endPointer;
    if (isWall) {
        if (!room)
            return null;
        const middlePointer = centroid(room.shape);
        startPointer = middlePointer.translate(-defaultLineWidth / 2, -defaultLineLength / 2);
        endPointer = middlePointer.translate(defaultLineWidth / 2, defaultLineLength / 2);
    }
    else {
        if (!line)
            return null;
        const middlePointer = line.getCenterPointer();
        startPointer = middlePointer.translate((Math.cos(Math.PI - line.getLineAngle()) * defaultLineWidth) / 2, (Math.sin(Math.PI - line.getLineAngle()) * defaultLineWidth) / 2);
        endPointer = middlePointer.translate((Math.cos(-line.getLineAngle()) * defaultLineWidth) / 2, (Math.sin(-line.getLineAngle()) * defaultLineWidth) / 2);
    }
    const door = new Door(startPointer, endPointer, isWall ? 0 : line.id, isWall ? room.id : 0, defaultLineWidth, defaultLineLength, 0, type, 0, uuidv4(), 1, name);
    return door;
};
export const convertUnit = (value, unit, isSmall = true) => {
    return value * (unit === METRIC_UNITS[0] ? (isSmall ? 1 : 100) : (isSmall ? 1 : 12) * INCH);
};
export const rgbToHex = (r, g, b) => {
    if (r > 255 || g > 255 || b > 255)
        throw 'Invalid color component';
    return ((r << 16) | (g << 8) | b).toString(16);
};
export const getNewSegmentName = (base, segments) => {
    let index = 1;
    while (segments.some((segment) => segment.name === `${base}${index}` ||
        ((segment.class === base || (segment.class === SegmentClass.ARC && base === SegmentClass.LINE)) &&
            segment.altName === `${index}`))) {
        index++;
    }
    if (base === SegmentClass.ARC ||
        base === SegmentClass.LINE ||
        base === SegmentClass.CLOSED_AREA ||
        base === SegmentClass.TILE_WRAPPER)
        return `${index}`;
    return `${base}${index}`;
};
export const createCustomEventDispatcher = () => {
    const component = get_current_component();
    return (type, target, detail) => {
        const callbacks = component.$$.callbacks[type];
        if (callbacks) {
            const event = new CustomEvent(type, { detail });
            // the key is to call `dispatchEvent` manually to set `event.target`
            target.dispatchEvent(event);
            callbacks.slice().forEach((fn) => {
                fn.call(component, event);
            });
        }
    };
};
export const getLayoutShapes = (tileLayout, geometry, baseShapes) => {
    if (!geometry?.svg_path)
        return [];
    const svgPath = JSON.parse(geometry.svg_path);
    return svgPath.shape_index.reduce((filtered, tileId, index) => {
        const baseShape = baseShapes.find((bs) => bs.tileId === tileId);
        const geometryShape = geometry.tile_shapes.find((gs) => gs.id === tileId);
        if (baseShape && geometryShape) {
            // check if is a replaced tile or a base shape
            const isReplacedTile = tileLayout.overrideAspectRatio.find((e) => e.shapeIndex === index);
            if (isReplacedTile) {
                filtered.push(isReplacedTile.tile);
                return filtered;
            }
            const shapeInstance = tileLayout.tiles.get(index);
            if (!shapeInstance || !shapeInstance.shape)
                return filtered;
            // override base shape dimensions with those of layout geometry shape
            const svg = baseShape.path.resizeAndCenter([
                (geometryShape.default_width ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
                (geometryShape.default_height ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
            ]);
            const factors = tileLayout.getMultiplyFactors(geometry, index);
            const widthMultiplyFactor = factors.widthMultiplyFactor;
            const heightMultiplyFactor = factors.heightMultiplyFactor;
            if (svg)
                filtered.push(new Shape(svg, baseShape.shapeId, baseShape.tileId, baseShape.name, {}, baseShape.slug, shapeInstance.shape.width * widthMultiplyFactor, shapeInstance.shape.height * heightMultiplyFactor, baseShape.rotation));
            // result = cloneShape(baseShape);
        }
        return filtered;
    }, []);
};
export const mergeBoundingRect = (original, newOne) => {
    let x1 = Math.min(original[0], newOne[0]);
    let y1 = Math.min(original[1], newOne[1]);
    let x2 = Math.max(original[0] + original[2], newOne[0] + newOne[2]);
    let y2 = Math.max(original[1] + original[3], newOne[1] + newOne[3]);
    return [x1, y1, x2 - x1, y2 - y1];
};
export const rotateLine = (line, center, deg) => {
    if (deg === 0)
        return line;
    const rad = (deg * Math.PI) / 180;
    return new Line(getRotatedPointer(line.startPointer, center, rad), getRotatedPointer(line.endPointer, center, rad));
};
export const getBoundingRect = (pointers) => {
    const xPoints = pointers.map((p) => p.x);
    const yPoints = pointers.map((p) => p.y);
    const x1 = Math.min(...xPoints);
    const y1 = Math.min(...yPoints);
    const x2 = Math.max(...xPoints);
    const y2 = Math.max(...yPoints);
    return [x1, y1, x2 - x1, y2 - y1];
};
export const formatNumberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const dataUrlToBlob = (url) => {
    const arr = url.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const str = Buffer.from(arr[1], 'base64').toString(); //atob(arr[1]);
    let length = str.length;
    const uintArr = new Uint8Array(length);
    while (length--) {
        uintArr[length] = str.charCodeAt(length);
    }
    return new Blob([uintArr], { type: mime });
};
const svg2png = (image) => {
    return new Promise((resolve, reject) => {
        // Create new image element
        const ele = new Image();
        ele.addEventListener('load', () => {
            // Create new canvas
            const canvas = document.createElement('canvas');
            // Draw the image that is scaled to `ratio`
            const context = canvas.getContext('2d');
            const w = ele.width;
            const h = ele.height;
            canvas.width = w;
            canvas.height = h;
            context.drawImage(ele, 0, 0, w, h);
            // Get the data of resized image
            'toBlob' in canvas
                ? canvas.toBlob((blob) => {
                    resolve(blob);
                })
                : resolve(dataUrlToBlob(canvas.toDataURL()));
            URL.revokeObjectURL(ele.src);
        });
        // Set the source
        ele.src = URL.createObjectURL(image);
    });
};
const converterEngine = (input) => {
    // fn BLOB => Binary => Base64 ?
    var uInt8Array = new Uint8Array(input), i = uInt8Array.length;
    var biStr = []; //new Array(i);
    while (i--) {
        biStr[i] = String.fromCharCode(uInt8Array[i]);
    }
    var base64 = window.btoa(biStr.join(''));
    return base64;
};
export const getImageBase64 = (url) => {
    // to comment better
    return new Promise((response, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true); // url is the url of a PNG/JPG image.
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (e) {
            const img64 = converterEngine(this.response); // convert BLOB to base64
            response('data:image/png;base64,' + img64); // callback : err, data
        };
        xhr.onerror = function () {
            reject('B64 ERROR');
        };
        xhr.send();
    });
};
export const createPreviewLayoutSvg = async (tilesToRender, layout, baseShapes, withTexture = false, defaultSize = 900, inlineImage = false) => {
    const graph = layout.getGraph();
    let defs = '';
    if (withTexture) {
        const baseShapeWithTexture = baseShapes.filter((bs) => !isEmpty(bs.tileData));
        let length = baseShapeWithTexture.length;
        for (let i = 0; i < length; ++i) {
            const cur = baseShapeWithTexture[i];
            if (i === 0)
                defs += '<defs>';
            defs += `<clipPath id="tile-clip-path-${cur.tileId}"><path d="${cur.path.toSvgPath()}" /></clipPath>`;
            let subacc = [];
            if (!isEmpty(cur.tileData)) {
                subacc = await Promise.all(cur.tileData?.images.map(async (image, subindex) => {
                    const img = inlineImage ? await getImageBase64(image) : image;
                    return `<filter id="filter-${cur.tileData.filterId}-${subindex}" filterUnits="objectBoundingBox" x="0" y="0" width="1" height="1"><feImage xlink:href="${img}" preserveAspectRatio="none"></feImage></filter>`;
                }));
            }
            defs += subacc.join();
            if (i === length - 1)
                defs += '</defs>';
        }
    }
    const { minX, maxX, minY, maxY } = tilesToRender.slice(0, graph.tiles.length).reduce((acc, cur) => {
        acc = {
            minX: Math.min(cur.bounds.min.x, acc.minX),
            maxX: Math.max(cur.bounds.max.x, acc.maxX),
            minY: Math.min(cur.bounds.min.y, acc.minY),
            maxY: Math.max(cur.bounds.max.y, acc.maxY),
        };
        return acc;
    }, { minX: 0, maxX: 0, minY: 0, maxY: 0 });
    const maxLen = Math.max(maxY - minY, maxX - minX) * 1.4; // 1.4 is for more extended show
    const centerPosX = (minX + maxX) / 2;
    const centerPosY = (minY + maxY) / 2;
    const tile_paths = tilesToRender.map((tile, index) => {
        if (tile.bounds.min.x > centerPosX + maxLen ||
            tile.bounds.max.x < centerPosX - maxLen ||
            tile.bounds.min.y > centerPosY + maxLen ||
            tile.bounds.max.y < centerPosY - maxLen) {
            return '';
        }
        const filter = withTexture && !!tile.shape.tileData.images?.length && !!tile.shape.tileData.filterId
            ? `url(#filter-${tile.shape.tileData.filterId}-${Math.floor(Math.random() * tile.shape.tileData.images.length)})`
            : undefined;
        let path = `<path d="${tile.shape.path.toSvgPath()}" stroke-width="0.01" stroke="${DEFAULT_PREVIEW_IMAGE_BORDER_COLOR}" fill="${index < graph.svg_path.shape_index.length ? DEFAULT_PREVIEW_IMAGE_FILL_COLOR : 'white'}" transform="${withTexture ? '' : tile.transform.asSVGMatrixString()}"></path>`;
        if (tile.shape.rotation) {
            path = `<g transform="rotate(-${tile.shape.rotation})"><g filter="${filter}"><g transform="rotate(${tile.shape.rotation})">${path}</g></g></g>`;
        }
        else {
            path = `<g filter="${filter}">${path}</g>`;
        }
        return withTexture
            ? `<g clip-path="url(#tile-clip-path-${tile.shape.tileId})" transform="${tile.transform.asSVGMatrixString()}">${path}</g>`
            : path;
    });
    return (`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
    viewBox="${centerPosX - maxLen} ${centerPosY - maxLen} ${maxLen * 2} ${maxLen * 2}" 
    preserveAspectRatio="xMidYMid meet" width="${defaultSize}" height="${defaultSize}">` +
        defs +
        tile_paths.join('') +
        '</svg>');
};
export const createPreviewLayout = (tilesToRender, layout, baseShapes, withTexture = false, defaultSize = 240, inlineImage = false) => {
    return new Promise(async (success, reject) => {
        const svg = await createPreviewLayoutSvg(tilesToRender, layout, baseShapes, withTexture, defaultSize, inlineImage);
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        // const reader = new FileReader();
        // reader.readAsDataURL(blob);
        // reader.onloadend = () => {
        //   success({
        //     blob: blob,
        //     base64: reader.result
        //   });
        // };
        // svg to png
        svg2png(blob).then((b) => {
            const reader = new FileReader();
            reader.readAsDataURL(b);
            reader.onloadend = () => {
                success({
                    blob: b,
                    base64: reader.result,
                });
            };
        });
    });
};
export const getBBFromDHidden = (d) => {
    // create temporary and hidden svg
    let ns = 'http://www.w3.org/2000/svg';
    let svg = document.createElementNS(ns, 'svg');
    let path = document.createElementNS(ns, 'path');
    svg.setAttribute('style', 'width:0!important; height:0!important; position:fixed!important; overflow:hidden!important; visibility:hidden!important; opacity:0!important');
    path.setAttribute('d', d);
    svg.append(path);
    document.body.append(svg);
    let bb = path.getBBox();
    //remove temporary svg
    svg.remove();
    return bb;
};
export const isWallProject = (projectInfo) => {
    return projectInfo?.options?.includes(PROJECT_TYPE.WALL_PLAN);
};
export const getAlphaIndex = (index) => {
    const ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (index < 26) {
        return ALPHABETS.slice(index, index + 1);
    }
    const firstIndex = Math.floor(index / 26);
    const secondIndex = index % 26;
    return ALPHABETS.slice(firstIndex, firstIndex + 1) + ALPHABETS.slice(secondIndex, secondIndex + 1);
};
export const createShape = (tileInfo, baseShapes) => {
    const baseTileShape = baseShapes.find((gt) => gt.shapeId === tileInfo.tile_shape.id);
    //apply 90° rotation
    if (tileInfo.rotation === 90 || tileInfo.rotation === 270)
        [tileInfo.width, tileInfo.height] = [tileInfo.height, tileInfo.width];
    let svg = baseTileShape.path.resizeAndCenter([
        (tileInfo.width ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
        (tileInfo.height ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
    ]);
    return new Shape(svg, baseTileShape.shapeId, tileInfo.id, tileInfo.name, {
        images: tileInfo.images,
        filterId: `tile-fill-${baseTileShape.shapeId}-${tileInfo.id}`,
        info: tileInfo,
    }, tileInfo.slug, tileInfo.width, tileInfo.height, tileInfo.rotation);
};
export const getGeometryForTileWrapper = async (layoutGeometryId, layoutGeometries, baseShapes, drawSend) => {
    let geometry = layoutGeometries[layoutGeometryId];
    if (geometry)
        return geometry;
    const data = await getLayoutGeometry(layoutGeometryId);
    geometry = {
        ...data,
        tile_shapes: data.tile_shapes
            .map((tileShapeId) => {
            const baseShape = baseShapes.find((v) => v.shapeId === tileShapeId);
            if (!baseShape)
                return null;
            return {
                id: tileShapeId,
                name: baseShape.name,
                svg_path: baseShape.path,
                default_width: baseShape.width,
                default_height: baseShape.height,
            };
        })
            .filter((v) => !!v),
    };
    if (drawSend) {
        drawSend({
            type: 'ADD_LAYOUT_GEOMETRY',
            layoutGeometry: geometry,
        });
    }
    return geometry;
};
export const calibrateFurniture = (buildingPart, closedArea, wOffset, hOffset) => {
    if (wOffset === 0 && hOffset === 0)
        return;
    const originalStart = buildingPart.startPointer.translate(0, 0);
    const originalEnd = buildingPart.endPointer.translate(0, 0);
    let i = 0;
    while (!checkBuildingPartInRoom(buildingPart, closedArea) && i < 8) {
        if (wOffset === 0 && i % 2 === 0)
            i++;
        if (hOffset === 0 && i % 2 === 1)
            i++;
        switch (i) {
            case 0: // left
                buildingPart.startPointer = originalStart.translate(wOffset, 0);
                buildingPart.endPointer = originalEnd.translate(wOffset, 0);
                break;
            case 1: // top
                buildingPart.startPointer = originalStart.translate(0, hOffset);
                buildingPart.endPointer = originalEnd.translate(0, hOffset);
                break;
            case 2: // right
                buildingPart.startPointer = originalStart.translate(-wOffset, 0);
                buildingPart.endPointer = originalEnd.translate(-wOffset, 0);
                break;
            case 3: // bottom
                buildingPart.startPointer = originalStart.translate(0, -hOffset);
                buildingPart.endPointer = originalEnd.translate(0, -hOffset);
                break;
            case 4: // left-top
                buildingPart.startPointer = originalStart.translate(wOffset, hOffset);
                buildingPart.endPointer = originalEnd.translate(wOffset, hOffset);
                break;
            case 5: // left-bottom
                buildingPart.startPointer = originalStart.translate(wOffset, -hOffset);
                buildingPart.endPointer = originalEnd.translate(wOffset, -hOffset);
                break;
            case 6: // right-top
                buildingPart.startPointer = originalStart.translate(-wOffset, hOffset);
                buildingPart.endPointer = originalEnd.translate(-wOffset, hOffset);
                break;
            case 7: // right-bottom
                buildingPart.startPointer = originalStart.translate(-wOffset, -hOffset);
                buildingPart.endPointer = originalEnd.translate(-wOffset, -hOffset);
                break;
        }
        i++;
    }
};
export const checkClockwise = (shape) => {
    const { results } = shape;
    let sum = 0;
    for (let i = 0; i < results.length; i++) {
        sum +=
            (results[i].endPointer.x - results[i].startPointer.x) * (results[i].endPointer.y + results[i].startPointer.y);
    }
    return sum < 0;
};
