<script lang="ts">
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import { drawState, drawService, drawSend } from "../../../layout.store";
  import { _ } from "src/services/i18n";
  import { FURNITURE_FILTERS, SORT_TYPES, TILES_FILTERS, TILES_SHAPES } from "src/global/variable";
  import Loading from "../../util/Loading.svelte";
  import { queryParamsFurnitures, getFurnituresList, getWishlistFurnitures, getAvailableWishlistFurnituresFilters, getAvailableFurnituresFilters, getFurniture } from "src/services/api";
  import { COLLAPSING_STATUS, FilterCategory, Filter } from "src/model/tile/Filter";
  import { BuildingPart, type ClosedArea, type Pointer } from "src/model";
  import Input from "../../base/Input.svelte";
  import Pagination from "../../base/Pagination.svelte";
  import { debounce, isEmpty, merge } from "lodash";
  import { centroid, createShape, formatNumberWithCommas, getNewSegmentName, isInShape, isWallProject } from "src/helpers";
  import Checkbox from "../../base/Checkbox.svelte";
  import Tooltip from "../../base/Tooltip.svelte";
  import Dropdown from "../../base/Dropdown.svelte";
  import FurnitureCard from "../../dialogs/FurnitureCard.svelte";
  import type { FurnitureInfoResponse } from "src/global/types";
  import { currentUser, furnitureFilterCategories, wishlistSlug } from "src/store";
  import { v4 as uuidv4 } from 'uuid';

  export let room: ClosedArea;  

  const dispatch = createEventDispatcher();
  
  let brandFilter = '';
  let furnituresLoading: boolean = false;
  
  let tileContainerRef: any;

  let tileSlugId: string | null = null;
  let isFilterLoaded: boolean = false;

  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  $: isNoBrand = !$furnitureFilterCategories[TILES_FILTERS.PRODUCERS]?.filters.some((v) => v.checked || v.indeterminate)

  let furnituresList: {
    total: number,
    total_pages: number,
    elements: FurnitureInfoResponse[]
  };

  let pagination, itemsPerPage: number = 12, currentPage: number = 1, sort: string = SORT_TYPES.NEWEST;

  let onlyWishlistsFurnitures: boolean = false;
  let onlyMyModels: boolean = false;
  
  const filterOrder = [
    FURNITURE_FILTERS.ELEMENT_CATEGORIES,
    // FURNITURE_FILTERS.PRODUCERS,
  ]

  let selectedFilters: queryParamsFurnitures = new queryParamsFurnitures();

  $: sortOptions = Object.entries(SORT_TYPES).map(([key, value]) => (
    { label: $_(`tile.sort.${value}`), value}
  ))
  
  const handleFilterByName = debounce(e => {

    if (e.target.value.length > 0)
      selectedFilters.text_search = e.target.value;
    else
      selectedFilters.text_search = undefined;

    updateFurnitureList();
  }, 300);

  const updateFurnitureList = debounce((resetPagination: boolean = true) => {
    furnituresLoading = true;
    let queryParams = new queryParamsFurnitures();

    Object.assign(queryParams, selectedFilters);
    queryParams.limit = itemsPerPage;
    queryParams.page = resetPagination ? currentPage = 1 : currentPage;
    queryParams.sort = sort;

    if (onlyWishlistsFurnitures && $wishlistSlug)
      getWishlistFurnitures($wishlistSlug, queryParams).then((res) => updateFurnitureListSuccess(res)).catch(() => furnituresLoading = false)
    else
      getFurnituresList(queryParams).then((res) => updateFurnitureListSuccess(res)).catch(() => furnituresLoading = false)

    function updateFurnitureListSuccess (res: any) {
      furnituresLoading = false;
      furnituresList = res?.data;
      if ( tileContainerRef )
        tileContainerRef.scrollTop = 0;
      if (resetPagination && pagination)
        pagination.resetPagination(furnituresList?.total);
    }
  }, 500)

  const updateAvailableFurnitureFilters = debounce(() => {

    let queryParams = new queryParamsFurnitures();
    // merge(queryParams, selectedFilters, collapsedFilters);
    merge(queryParams, selectedFilters);
    let filterPromise;
    if( onlyWishlistsFurnitures && $wishlistSlug )
      filterPromise = getAvailableWishlistFurnituresFilters($wishlistSlug, queryParams);
    else 
      filterPromise = getAvailableFurnituresFilters(queryParams)

    filterPromise.then((res) => {
        let availableFilters = res?.data?.available_filters;

        function setAvailability(filters: Filter[], category: string, availableFilters: any) {
          
          let availableCategoryFilters = availableFilters[category];
          //all filters of the category are available
          let all: boolean = availableCategoryFilters.length > 0 && availableCategoryFilters[0] === "all";

          for (let filter of filters)
          {
            let index: number = -1;

            if (!all)
            {
              //if filter is found then is available, so remove it from array to speed up subsequent searches
              index = availableCategoryFilters.findIndex((id) => id === filter.id);
              if (index >= 0)
                availableCategoryFilters.splice(index, 1);
            }

            //set availability
            filter.available = (all || index >= 0);

            if (filter.subfilters.length > 0)
            {
              setAvailability(filter.subfilters, filter.subcategory, availableFilters);

              //if filter isn't available but at least one of its subfilters is, then check as available
              if (!filter.available)
              {
                for (let subfilter of filter.subfilters)
                {
                  if (subfilter.available)
                  {
                    filter.available = true;
                    break;
                  }
                }
              }
            }
            //set availability if there are no subfilters
            // else
            //   filter.available = (all || index >= 0);
          }
        }
        for (let category in availableFilters)
        {
          let filters = $furnitureFilterCategories[category]?.filters;
          if (filters)
            setAvailability(filters, category, availableFilters);
        }

        //Svelte requires an assignment to trigger an update
        $furnitureFilterCategories = $furnitureFilterCategories;

        isFilterLoaded = true;
      })
  }, 200)

  function simplifyQueryParams(filter: Filter) {

    let descendants;

    if (filter.checked)
    {
      let ancestor = filter.getLastCheckedAncestor();

      if (ancestor.category === ancestor.subcategory)
      {
        //remove all descendants slug from query params only
        let descendants = ancestor.getDescendantsArray();
        for (let descendant of descendants)
            delete selectedFilters[descendant.category][descendant.slug];

        //add the checked ancestor slug in query params
        selectedFilters[ancestor.category][ancestor.slug] = undefined;
      }
      else
      {
        //add all checked descendants slugs in query params
        descendants = filter.getCheckedDescendantsArray();
        for (let descendant of descendants)
          selectedFilters[descendant.category][descendant.slug] = undefined;

        //add checked filter slug from query params
        selectedFilters[filter.category][filter.slug] = undefined;
      }
    }
    else
    {
      //remove unchecked filter slug from query params
      delete selectedFilters[filter.category][filter.slug];

      if (!filter.indipendentSubfilters || filter.category !== filter.subcategory)
      // if (!filter.indipendentSubfilters)
      {
        //remove all descendants slugs of the unchecked filter from query params
        descendants = filter.getDescendantsArray();
        for (let descendant of descendants)
          delete selectedFilters[descendant.category][descendant.slug];
      }
      else
      {
        //add all checked descendants slugs in query params
        descendants = filter.getCheckedDescendantsArray();
        for (let descendant of descendants)
          selectedFilters[descendant.category][descendant.slug] = undefined;
      }

      let root = filter.root();
      if (!root.indipendentSubfilters)
      {
        //remove root slug from query params
        delete selectedFilters[root.category][root.slug];

        //add only the shallower checked descendants slugs in query params
        descendants = root.getCheckedDescendantsArray();
        for (let descendant of descendants)
          selectedFilters[descendant.category][descendant.slug] = undefined;
      }
    }
  }

  function getSelectedFilter(category: FilterCategory, filterKey: string) {
    const result = category.filters.find((v) => v.slug === filterKey || v.subfilters.find((k) => k.slug === filterKey))
    if( result.slug !== filterKey )
      return result.subfilters.find((k) => k.slug === filterKey)
    return result;
  }

  function resetFilter() {
    selectedFilters = new queryParamsFurnitures();
    filterOrder.forEach(category => {
      $furnitureFilterCategories[category]?.filters.forEach(filter => {
        if (filter.checked)
        {
          filter.toggle();
        }
        else if (filter.subfilters.length > 0)
        {
          filter.subfilters.forEach(subFilter => {
            if (subFilter.checked)
              subFilter.toggle();
          })
        }
      })
    })
    //Svelte requires an assignment to trigger an update
    $furnitureFilterCategories = $furnitureFilterCategories;
   
    updateFurnitureList();
    updateAvailableFurnitureFilters();
  }

  function handleBrandFilter (e) {
    brandFilter = e.target.value || '';
  }

  function toggleCategory(category: FilterCategory) {
    category.toggle();
    //Svelte requires an assignment to trigger an update
    $furnitureFilterCategories = $furnitureFilterCategories;
  }

  function toggleSubfilters(filter: Filter) {

    filter.toggleCollapsingSubfilters();
    //Svelte requires an assignment to trigger an update
    $furnitureFilterCategories = $furnitureFilterCategories;
  }

  function toggleFilter(filter: Filter) {
    filter.toggle();
    //Svelte requires an assignment to trigger an update
    $furnitureFilterCategories = $furnitureFilterCategories;

    let queryParams = new queryParamsFurnitures();
    merge(queryParams, selectedFilters);

    simplifyQueryParams(filter);

    //if the query parameters remain the same after simplification, then updating is not necessary
    if (queryParams.getQueryString() === selectedFilters.getQueryString())
      return;

    updateFurnitureList();
    updateAvailableFurnitureFilters();
  }

  onMount(() => {
    updateFurnitureList();
    updateAvailableFurnitureFilters();
  })

  
  onDestroy(() => {
    resetFilter();
    currentPage = 1;
  })

  function confirmTileSelection(furniture: FurnitureInfoResponse) {
    getFurniture(furniture.id).then((info: FurnitureInfoResponse) => {
      const initialWidth = info.width;
      const initialHeight = isWall ? info.height : info.depth
      let width = initialWidth;
      let height = initialHeight;
      let steps = 0;
      const centerPointer = centroid(room.shape);
      let finalStartPointer: Pointer | undefined = undefined;
      let finalEndPointer: Pointer | undefined = undefined;
      let finalWidth = 0;
      let finalHeight = 0;
      
      do {
        steps++;
        const startPointer = centerPointer.translate(
            -width / 2,
            -height / 2
          );
        const endPointer = centerPointer.translate(
            width / 2,
            height / 2
          );
        
        const vertex = [startPointer, endPointer, startPointer.translate(width, 0), startPointer.translate(0, height)];
        if (vertex.every((v) => isInShape(v, room.shape))) {
          finalWidth = width;
          finalHeight = height;
          finalStartPointer = startPointer.translate(0, 0);
          finalEndPointer = endPointer.translate(0, 0);

          if ( steps === 1 ) 
            break;
          
          width = width + initialWidth / Math.pow(2, steps)
          height = height + initialHeight / Math.pow(2, steps)
        } else {
          width = width - initialWidth / Math.pow(2, steps)
          height = height - initialHeight / Math.pow(2, steps)
        }
      } while (steps < 5)

      if( !finalStartPointer || !finalEndPointer) {
        return
      }
      const furnitureName = getNewSegmentName(info.name, $drawState.context.current.segments)
      const buildingPart = new BuildingPart(
        finalStartPointer,
        finalEndPointer,
        finalWidth,
        finalHeight,
        isWall ? info.view_front : info.view_top,
        0,
        0,
        info.name,
        info.id,
        room.id,
        uuidv4(),
        1,
        furnitureName
      )
      drawSend({
        type: "CREATE_BUILDING_PART",
        segment: buildingPart,
      });
      dispatch("select", { segment: buildingPart });
    })
  }

</script>


<div class="flex flex-col w-[773px] h-full">
  <div class="flex items-center gap-1 p-2.5">
    <Input
      on:input={handleFilterByName} 
      value={selectedFilters.text_search ?? ''}
      placeholder={$_("side_menu.layout.search_by_name")}
      icon="fa-light fa-magnifying-glass" 
      iconFilled={false}
      roundedFull
      fullWidth
    />
    {#if $currentUser && $wishlistSlug}
    <Tooltip tooltip={$_("tile.wishlist_tooltip")}>
      <button 
        class="w-8 h-8 text-center border-0 p-1 rounded" 
        on:click={() => { 
          onlyWishlistsFurnitures = !onlyWishlistsFurnitures;
          updateFurnitureList(); 
          updateAvailableFurnitureFilters();
        }}
      >
        <i class={`${onlyWishlistsFurnitures ? 'fa-solid text-primary-500' : 'fa-regular'} fa-heart fa-xl`} />
      </button>
    </Tooltip>
    {/if}
    <Dropdown dropClassName="mt-2" dropdownWidth="120px" align="right">
      <Tooltip slot="button" tooltip={$_("tile.sort_tooltip")}>
        <button class="w-8 h-8 text-center border-0 p-1 rounded">
          <i class="fa-regular fa-arrow-down-arrow-up fa-xl" />
        </button>
      </Tooltip>
      {#each sortOptions as option}
        <li>
          <button
            class="btn-default flex items-center px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded"
            on:click={() => { 
              sort = option.value 
              updateFurnitureList();
            }}
          >
            <div class="grow text-left text-[13px]">{option.label}</div>
          </button>
        </li>
      {/each}
    </Dropdown>
  </div>
  <div class="w-full flex flex-1 overflow-hidden">
    <div class="w-[250px] shrink-0 p-2.5 h-full overflow-auto">
      {#if $currentUser}
      <Checkbox 
        checked={onlyMyModels} 
        class="mb-2" 
        on:change={() => { 
          onlyMyModels = !onlyMyModels;
          selectedFilters.my = onlyMyModels ? 1 : undefined
          updateFurnitureList();
          updateAvailableFurnitureFilters();
        }}
      >
        <div class="text-title text-base font-semibold">{$_("side_menu.furniture.my_models")}</div>
      </Checkbox>
      {/if}

      {#each filterOrder as category}
        <div class="flex flex-col">
          <div class="flex items-center my-2 cursor-pointer" on:click={() => toggleCategory($furnitureFilterCategories[category])}>
            <label for="{category}" class="grow text-title font-semibold text-base cursor-pointer">{$_(`side_menu.furniture.filter.${category}`)}</label>
            <div class="w-9 h-9 flex items-center justify-center flex-none ml-2">
              <i class="fa-solid text-input-label {$furnitureFilterCategories[category]?.expanded ? 'fa-chevron-down' : 'fa-chevron-right'}"/>
            </div>
          </div>
          <div class="flex-1 p-2 pb-6 overflow-auto {$furnitureFilterCategories[category]?.expanded ? '' : 'hidden'}">
            <div class="flex flex-col">
            {#if category === FURNITURE_FILTERS.PRODUCERS}
              <Input on:input={handleBrandFilter} value={brandFilter} icon="fa-light fa-magnifying-glass" class="mb-2" iconFilled={false} fullWidth />
              <div class="text-xs mb-2">
                {isNoBrand ? $_(`side_menu.furniture.filter.search_brands`) : $_(`side_menu.furniture.filter.search_collections`)}
              </div>
              {#if $furnitureFilterCategories[category]}
                {#each $furnitureFilterCategories[category].filters as filter (filter.slug)}
                  {#if filter.available && (filter.name.toLowerCase().includes(brandFilter.toLowerCase()) || filter.subfilters.some((v) => v.available && v.name.toLowerCase().includes(brandFilter.toLowerCase())))}
                    <Checkbox indeterminate={filter.indeterminate} checked={filter.checked} on:change={() => toggleFilter(filter)} label={filter.name} class="w-full px-1 hover:bg-gray-100" /> 
                    {#each filter.subfilters as subfilter (subfilter.slug)}
                      {#if subfilter.available && (subfilter.name.toLowerCase().includes(brandFilter.toLowerCase()) || filter.name.toLowerCase().includes(brandFilter.toLowerCase()))}
                        <Checkbox checked={subfilter.checked} on:change={() => toggleFilter(subfilter)} label={subfilter.name} class="w-full pl-4 pr-1 hover:bg-gray-100" /> 
                      {/if}
                    {/each}
                  {/if}
                {/each}
              {/if}
            {:else if $furnitureFilterCategories[category]}
              {#each $furnitureFilterCategories[category]?.filters as filter (filter.slug)}
                {#if filter.available}
                  <div class="flex">
                    <Checkbox indeterminate={filter.indeterminate} checked={filter.checked} on:change={() => toggleFilter(filter)} label={filter.name} class="w-full px-1 hover:bg-gray-100" /> 
                    {#if filter.collapsingSubfilters !== COLLAPSING_STATUS.ALWAYS_VISIBILE}
                      <div class="flex items-center justify-end grow">
                        <i class="fa-light fa-sm cursor-pointer {filter.collapsingSubfilters === COLLAPSING_STATUS.COLLAPSED ? 'fa-plus' : 'fa-minus'}" on:click={() => toggleSubfilters(filter)} on:keydown/>
                      </div>
                      <input class="subfilters-collapser hidden" type="checkbox" id="{filter.slug}_collapser"
                              checked={filter.collapsingSubfilters === COLLAPSING_STATUS.NOT_COLLAPSED} on:change={() => toggleSubfilters(filter)}/>
                    {/if}
                  </div>
                  <div class="{filter.collapsingSubfilters ===  COLLAPSING_STATUS.COLLAPSED? 'hidden' : ''}">
                    {#each filter.subfilters as subfilter (subfilter.slug)}
                      {#if subfilter.available}
                        <Checkbox checked={subfilter.checked} on:change={() => toggleFilter(subfilter)} label={subfilter.name} class="w-full pl-4 pr-1 hover:bg-gray-100" /> 
                      {/if}
                    {/each}
                  </div>
                {/if}
              {/each}
            {/if}
            </div>
          </div>
        </div>
      {/each}
    </div>
    <div class="flex-1 flex flex-col h-full">
      <div class="flex items-center flex-wrap gap-2 mb-5 px-4">
        {#each filterOrder as category}
          {#each Object.keys(selectedFilters[category]) as selectedItem}
            <div class="bg-gray-100 rounded p-1 inline-flex items-center justify-center text-sm gap-1">
              <div class="font-medium capitalize">{getSelectedFilter($furnitureFilterCategories[category], selectedItem)?.name}</div>
              <div class="flex items-center justify-center cursor-pointer" on:click={() => toggleFilter(getSelectedFilter($furnitureFilterCategories[category], selectedItem))} on:keydown>
                <i class="fa fa-times fa-sm" />
              </div>
            </div>
          {/each}
        {/each}
      </div>

      <div class="text-base font-bold mb-4 px-4">{formatNumberWithCommas(furnituresList?.total || 0)} {$_("side_menu.results")}</div>

      {#if furnituresLoading}
        <div class="flex h-full justify-center items-center">
          <Loading />
        </div>
      {:else}
        
        <ul
          role="listbox"
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 overflow-auto px-4"
          bind:this={tileContainerRef}
        >
          {#if furnituresList?.elements}
            {#each furnituresList?.elements as element (element.slug)}
              <li>
                <FurnitureCard 
                  furniture={element} 
                  loading={tileSlugId === element.slug}
                  onClick={() => confirmTileSelection(element)}
                />
              </li>
            {/each}
          {/if}
        </ul>
      {/if}
      {#if furnituresList?.total > 0}
      <Pagination bind:totalItems={furnituresList.total} bind:itemsPerPage={itemsPerPage} bind:currentPage={currentPage}
                  bind:this={pagination} onPageChange={() => updateFurnitureList(false)}></Pagination>
      {/if}
    </div>
  </div>
</div>
