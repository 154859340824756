<script lang="ts">
  import Button from "./base/Button.svelte";
  import { _ } from "../services/i18n";
  import router from "page";
  import routes, { ROUTE } from "../router";
  import { DEFAULT_ROOM_HEIGHT, DEFAULT_ROOM_WIDTH, DEFAULT_WALL_HEIGHT, DEFAULT_WALL_WIDTH, PROJECT_SHAPE } from "../global/variable";
  import { drawState, drawSend, storedDrawState } from "src/layout.store";
  import { register } from 'swiper/element/bundle';
  import Input from "./base/Input.svelte";
  import Login from "./toolbox/Login.svelte";
  import { PAGES, PROJECT_TYPE } from "src/global/types";
  import { currentTool, currentUser, loadSuccess, recentLayouts } from "src/store";
  import { getProjectsList } from "src/services/api";
  import ProjectCard from "./dialogs/ProjectCard.svelte";
  import Loading from "./util/Loading.svelte";
  import { debounce } from "lodash";
  import SettingsModal from "./dialogs/SettingsModal.svelte";
  import FullScreenButton from "./base/FullScreenButton.svelte";
  import GridItem from "./toolbox/SubPanels/GridItem.svelte";
  import { METRIC_SMALL_UNITS, METRIC_UNITS } from "src/global/variable";

  register();

  export let page : PAGES;
  export let open: Function;
  
  let swiperEl;
  let currentSwipeIndex = 0;
  let isSwiperBeginning;
  let isSwiperEnd;
  let currentShape: string;

  let roomWidth: number = DEFAULT_ROOM_WIDTH;
  let roomHeight: number = DEFAULT_ROOM_HEIGHT;
  let projectsLoading: boolean = false;

  let projectType: PROJECT_TYPE = PROJECT_TYPE.ROOM_PLAN;
  let itemsPerPage: number = 12, currentPage: number = 1;
  let textSearch;

  let projectsList: {
    total: number,
    total_pages: number,
    projects: any[]
  };

  $: projectTypes = [
    { value: PROJECT_TYPE.ROOM_PLAN, label: $_("home.typology.room"), image: '/room-plan.svg' },
    { value: PROJECT_TYPE.WALL_PLAN, label: $_("home.typology.wall"), image: '/wall-plan.svg' },
  ]

  const onSlideChange = (e) => {
    currentSwipeIndex = e.detail[0].activeIndex;
  }

  $: isSwiperBeginning = currentSwipeIndex === 0
  $: isSwiperEnd = currentSwipeIndex === PROJECT_SHAPE.length - 1

  let fileInput;
  let files : FileList;

  $: if (files) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        open(JSON.parse(reader.result));
      }
      fileInput.value = null;
    };
    reader.readAsText(files[0]);
  }

  const handleFilterByName = debounce(e => {

    textSearch = e.target.value.length > 0 ? e.target.value : undefined;
    updateProjectsList();

  }, 300);

  const updateProjectsList = (resetPagination: boolean = true) => {
    projectsLoading = true;
    const page = resetPagination ? 1 : currentPage + 1;
    let queryParams : { limit?: number, page?: number; textSearch?: string } = {
      limit: itemsPerPage,
      page: page,
      textSearch: textSearch
    };

    getProjectsList(queryParams).then((res) => updateProjectsListSuccess(res)).catch(() => projectsLoading = false)

    function updateProjectsListSuccess (res: any) {
      currentPage = page;
      projectsLoading = false;
      if( resetPagination ) {
        projectsList = res?.data;
      } else if( res?.data ) {
        projectsList = {
          total: res.data.total,
          total_pages: res.data.total_pages,
          projects: [...projectsList.projects, ...res.data.projects]
        }
      }
    }
  }

  const goToHome = () => {
    router.show(routes[ROUTE.HOME].path)
  }

  const goToBoard = () => {
    router.show(routes[ROUTE.BOARD].path)
  }

  const handleQuickStart = () => {
    router.show(routes[ROUTE.NEW_PROJECT].path)
  }

  const handleCreateProject = () => {
    if( currentShape === undefined ) {
      return
    }
    if( currentShape !== '' && (roomWidth <= 0 || roomHeight <= 0 )) return;
    
    if( currentShape === '' ) {
      drawSend({ type: "EMPTY_PROJECT", roomType: projectType, });
    } else {
      drawSend({
        type: "CREATE_ROOM",
        roomType: projectType,
        roomShape: currentShape,
        width: +roomWidth,
        height: +roomHeight
      });
    }

    router.show(routes[ROUTE.BOARD].path)
    $currentTool = undefined;
    $recentLayouts = []
  }

  $: {
    if( $currentUser )
      updateProjectsList();
    else 
      projectsList = {
        total: 0,
        total_pages: 0,
        projects: []
      }
  }

  $: isValidCreate = currentShape !== undefined && ( currentShape === '' || (roomWidth > 0 && roomHeight > 0) )
</script>
<div class="relative w-screen min-h-full">
  {#if $drawState.matches("main.preload") || $drawState.matches("main.loading")}
  <div class="flex h-full">
    <img
      id="cursor-load"
      alt="cursor"
      src="/cursor-load.jpg"
    />
  </div>  
  {:else}
  <div class="flex h-screen">
    <div class="w-[430px] flex flex-col shrink-0 h-screen	bg-logo-blue justify-center items-center">
      <div class="flex flex-col items-center mb-[60px]">
        <img src="/logo-home.svg" alt="logo"/>
        <div class="flex items-center justify-center gap-2.5">
          <div class="text-[32px] font-bold text-white">PLANNER</div>
          <div class="bg-primary-600 text-white font-medium rounded-xl p-2.5">
            BETA
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        {#if storedDrawState}
        <Button 
          variant="neutral" 
          title={$_("home.go_to_board")} 
          icon="fa-light fa-ruler-triangle" 
          iconPos="center"
          class="menu-button"
          on:click={goToBoard}
          size="sm"
          roundedFull 
        />
        {/if}
        <!-- <Button variant="outline-secondary" title={$_("home.import_project")} icon="fa-light fa-file-import" class="menu-button mx-auto mb-4"/> -->
      </div>
    </div>
    <div class="flex flex-col flex-1 h-screen min-w-0">
      <div class="flex justify-end border-b border-gray-300 py-1">
        <FullScreenButton />
        <SettingsModal />
        <Login path={routes[ROUTE.HOME].path}/>
      </div>
      <div class="w-full h-full overflow-y-auto min-w-0">
        {#if page === PAGES.HOME}
        <div class="w-full px-[60px] pb-8 pt-12 flex flex-col gap-5">
          <div class="text-xl text-black font-semibold">{$_("home.my_projects")}</div>
          {#if $currentUser && (textSearch?.length > 0 || (projectsList && projectsList.total > 0))}
          <Input 
            on:input={handleFilterByName} 
            value={textSearch ?? ''} 
            placeholder={$_("home.search")} 
            icon="fa-light fa-magnifying-glass"
            iconFilled={false}
            fullWidth
            roundedFull
          />
          {/if}
          
          
          <ul
            role="listbox"
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4"
          >
            <li>
              <GridItem
                title={$_("home.new_project")}
                on:click={handleQuickStart}
                class="h-full"
                special
              >
                <div class="w-full aspect-square flex items-center justify-center">
                  <i class="fa-solid fa-plus fa-2x" />
                </div>
              </GridItem>
            </li>
            {#if projectsList}
              {#each projectsList.projects.slice(0, projectsList.total_pages > currentPage ? -1 : undefined) as project (project.slug)}
                <li>
                  <ProjectCard project={project} onLoad={(res) => open(res)} onDelete={() => updateProjectsList()} />
                </li>
              {/each}
            {/if}
          </ul>
          {#if projectsLoading}
            <div class="flex h-full justify-center items-center my-4">
              <Loading />
            </div>
          {/if}
          {#if projectsList && projectsList.total_pages > currentPage}
            <Button
              variant="primary-hover"
              title={$_("home.load_more")}
              icon="fa-solid fa-chevron-down fa-custom-lg"
              iconPos="center"
              size="sm"
              on:click={() => {
                updateProjectsList(false)
              }}
              roundedFull
            />
          {/if}
        </div>
        {:else if page === PAGES.NEW_PROJECT}
        <div class="px-[60px] py-5">
          <div class="flex text-sm text-input-label hover:text-title items-center gap-1 cursor-pointer mb-2" on:click={goToHome}>
            <i class="fa-light fa-arrow-left" />
            <span>{$_("side_menu.back")}</span>
          </div>
          <div class="text-black text-xl font-semibold mb-5">{$_("home.new_project")}</div>
          <div class="py-7">
            <div class="text-title text-base font-semibold mb-5">{$_("home.typology.type")}</div>
            <div class="flex gap-2.5">
              {#each projectTypes as item}
                <GridItem
                  title={item.label}
                  on:click={() => { 
                    projectType = item.value 
                    if( projectType === PROJECT_TYPE.ROOM_PLAN ) {
                      roomWidth = DEFAULT_ROOM_WIDTH
                      roomHeight = DEFAULT_ROOM_HEIGHT
                    } else if( projectType === PROJECT_TYPE.WALL_PLAN ) {
                      roomWidth = DEFAULT_WALL_WIDTH
                      roomHeight = DEFAULT_WALL_HEIGHT
                    }
                  }}
                  active={item.value === projectType}
                >
                  <img src={item.image} alt={item.label} />
                </GridItem>
              {/each}
            </div>
          </div>
          <div class="py-7 border-t border-gray-100">
            <div class="text-title text-base font-semibold mb-5">{$_("home.shape")}</div>
            <div class="flex items-center gap-4">
              <i class={`fa fa-chevron-left font-black fa-2x cursor-pointer ${isSwiperBeginning ? 'text-gray-400' : 'text-secondary-500' }`} 
                        on:click={() => swiperEl?.swiper?.slidePrev()} on:keydown/>
              <div class="flex-1 min-w-0">
                <swiper-container
                  slides-per-view="auto"
                  space-between={15}
                  initial-slide={currentSwipeIndex}
                  on:slidechange={onSlideChange}
                  bind:this={swiperEl}
                >
                  <swiper-slide style="width: auto !important">
                    <GridItem
                      title={$_("home.draw_scratch")}
                      class="w-[150px]"
                      active={currentShape === ''}
                      on:click={() => { currentShape = '' }}
                      special
                    >
                      <i class="fa-solid fa-pencil fa-2x text-gray-600"/>
                    </GridItem>
                  </swiper-slide>
                  {#each PROJECT_SHAPE as s}
                    <swiper-slide style="width: auto !important">
                      <GridItem 
                        title={`${$_(`project_shape.${s.name}`)}-${s.type}`}
                        class="w-[150px]"
                        active={currentShape === `${s.name}-${s.type}`}
                        on:click={() => { currentShape = `${s.name}-${s.type}` }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={`-3 -3 ${s.width + 6} ${s.height + 6}`}
                          stroke="black"
                          stroke-width="3px"
                          fill="white"
                        >
                          {@html s.path}
                        </svg>
                      </GridItem>
                    </swiper-slide>
                  {/each}
                </swiper-container>
              </div>
              <i class={`fa fa-chevron-right font-black fa-2x cursor-pointer ${isSwiperEnd ? 'text-gray-400' : 'text-secondary-500' }`} 
                on:click={() => swiperEl?.swiper?.slideNext()} on:keydown/>
            </div>
          </div>
          {#if currentShape && currentShape !== ''}
          <div class="py-7 border-t border-gray-100">
            <div class="text-title text-base font-semibold mb-5">{$_("side_menu.size")}</div>
            <div class="flex flex-col md:flex-row gap-10">
              <div class="flex items-center gap-2.5">
                <div class="text-title text-base">{$_("building_edit.width")}</div>
                <Input 
                  type="number"
                  value={roomWidth} 
                  on:input={(e) => roomWidth = parseFloat(e.target.value)}
                  unit={$drawState.context.currentMetricUnit === METRIC_UNITS[0] ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
                /> 
              </div>
              <div class="flex items-center gap-2.5">
                <div class="text-title text-base">
                  {projectType === PROJECT_TYPE.ROOM_PLAN ? $_("building_edit.length") : $_("building_edit.height")}
                </div>
                <Input 
                  type="number"
                  value={roomHeight} 
                  on:input={(e) => roomHeight = parseFloat(e.target.value)}
                  unit={$drawState.context.currentMetricUnit === METRIC_UNITS[0] ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
                /> 
              </div>
            </div>
          </div>
          {/if}
          <div class="py-7 flex justify-end">
            <Button
              variant="primary"
              title={$_("home.next")}
              class="w-32"
              icon="fa-solid fa-arrow-right fa-custom-lg"
              iconPos="right"
              size="sm"
              on:click={handleCreateProject}
              disabled={!isValidCreate}
              roundedFull
            />
          </div>
        </div>
        {/if}
      </div>
    </div>
  </div>
  {/if}
</div>

<style global>

  #cursor-load {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: none;
    pointer-events: none;
    user-select: none;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
  }


  .menu-button {
    width: 323px
  }
</style>