import { Pointer } from './Pointer';
import { v4 as uuidv4 } from 'uuid';
export const SegmentClass = {
    ARC: 'Arc',
    BUILDING_PART: 'BuildingPart',
    DOOR: 'Door',
    LINE: 'Line',
    TILE_WRAPPER: 'TileWrapper',
    CLOSED_AREA: 'ClosedArea',
};
export class Segment {
    startPointer;
    endPointer;
    id;
    zIndex;
    name;
    altName;
    class;
    constructor(startPointer, endPointer, id = uuidv4(), zIndex = 0, name = '', altName = '') {
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.id = id;
        this.zIndex = zIndex;
        this.name = name;
        this.altName = altName;
    }
    getName(t) {
        if (this.name.length)
            return this.name;
        let name = '';
        switch (this.class) {
            case SegmentClass.ARC:
            case SegmentClass.LINE:
                name = t('segment.line');
                break;
            case SegmentClass.TILE_WRAPPER:
                name = t('segment.area');
                break;
            case SegmentClass.CLOSED_AREA:
                name = t('segment.surface');
                break;
        }
        return name + this.altName;
    }
    generatePath(isFromShape = false, startPointer = undefined) {
        return '';
    }
    generateHelperPath(segments, isWall) {
        return [''];
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx: dx, dy: dy };
    }
    generateHelperTextData(segments, isWall) {
        return [];
    }
    getPointsArray() {
        return [];
    }
    translate(dx, dy, segments) {
        return;
    }
    getLineLength() {
        return 0;
    }
    isInSegment(pointer, acceptInPath = true) {
        return false;
    }
    clone() {
        return new Segment(new Pointer(this.startPointer.x, this.startPointer.y), new Pointer(this.endPointer.x, this.endPointer.y), this.id);
    }
    toJSON() {
        return {
            class: this.class,
            name: this.name,
            altName: this.altName,
            startPointer: this.startPointer.toJSON(),
            endPointer: this.endPointer.toJSON(),
            id: this.id,
            zIndex: this.zIndex,
        };
    }
}
