<script lang="ts">
  import { activeMenu, editSegment, selectedRoom, selectedSegment, viewBox, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import Button from "../../base/Button.svelte";
  import { centroid, createDoor, dist, getNewSegmentName, isClosedArea, isDoor, isLine, isTileWrapper, isWallProject } from "src/helpers";
  import { DOORTYPE, SegmentClass, type Door, Line, Pointer, ClosedArea } from "src/model";
  import DoorInfo from "./DoorInfo.svelte";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import { onMount, onDestroy } from "svelte";
  import LongText from "../../base/LongText.svelte";
  
  export let type : DOORTYPE;
  
  enum DOOR_VIEW {
    INDEX,
    ADD_DOOR,
    EDIT_DOOR,
    ADD_WINDOW,
    EDIT_WINDOW
  }
  
  let routes : DOOR_VIEW[] = [DOOR_VIEW.INDEX];
  
  let currentView: DOOR_VIEW = DOOR_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: [],
    editable: false
  };
  let currentDoor: Door;

  let unsubscribe
  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.DOOR && $activeMenu !== SIDE_MENUS.WINDOW )
        return;

      if( !visible ) {
        handleBack();
        routes = [DOOR_VIEW.INDEX];
        currentView = DOOR_VIEW.INDEX;
      }
    })
  })
  
  $: rooms = $drawState.context.current.segments.filter((segment) =>
    isClosedArea(segment)
  ) as ClosedArea[];

  $: doorsWithRoom = rooms.map((room) => {
    return {
      room: room,
      doors: $drawState.context.current.segments.filter((segment) => 
        isDoor(segment) && (segment as Door).buildingType === type &&
        (
          room.id === (segment as Door).closedAreaId ||
          room.shape.results.find((item) => item.id === (segment as Door).parentId)
        )
      ).sort((a, b) => a.name.localeCompare(b.name))
    }
  })

  const handleNext = (view: DOOR_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    routes.pop()
    if( routes.length )
      currentView = routes[routes.length - 1]
  };
  
  const handleCreateDoor = (room: ClosedArea) => {
    const line = room.shape.results.find((v) => isLine(v) && !(v as Line).isDashed()) as Line;
    const doorName = getNewSegmentName(type === DOORTYPE.DOOR ? $_("segment.door") : $_("segment.window"), $drawState.context.current.segments)
    currentDoor = createDoor(room, line, type, doorName, isWallProject($drawState.context.projectBaseInfo) );

    drawSend({
      type: "CREATE_BUILDING_PART",
      segment: currentDoor,
    });
    
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    
    if( type === DOORTYPE.DOOR )
      handleNext(DOOR_VIEW.ADD_DOOR);
    else if( type === DOORTYPE.WINDOW )
      handleNext(DOOR_VIEW.ADD_WINDOW);
  }

  const handleSelectDoor = (e: CustomEvent) => {
    currentDoor = e.detail.segment;
    // selectedSegment.set(currentDoor);
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    
    if( type === DOORTYPE.DOOR )
      handleNext(DOOR_VIEW.EDIT_DOOR);
    else if( type === DOORTYPE.WINDOW )
      handleNext(DOOR_VIEW.EDIT_WINDOW);
  }

  $: {
    switch (currentView) {
      case DOOR_VIEW.INDEX:
        headerInfo = {
          title: type === DOORTYPE.DOOR ? $_("side_menu.doors") : $_("side_menu.windows"),
          info: "",
          path: [$drawState.context.projectBaseInfo?.name ? $drawState.context.projectBaseInfo.name : $_("project_wizard.untitled")],
          editable: false
        };
        break;
      case DOOR_VIEW.ADD_DOOR:
      case DOOR_VIEW.EDIT_DOOR:
        headerInfo = {
          title: currentDoor?.getName($_),
          info: "",
          path: [$_("side_menu.doors"), currentView === DOOR_VIEW.ADD_DOOR ? $_("side_menu.door.add_door") : $_("side_menu.door.edit_door")],
          editable: true
        };
        break;
      case DOOR_VIEW.ADD_WINDOW:
      case DOOR_VIEW.EDIT_WINDOW:
        headerInfo = {
          title: currentDoor?.getName($_),
          info: "",
          path: [$_("side_menu.windows"), currentView === DOOR_VIEW.ADD_WINDOW ? $_("side_menu.door.add_window") : $_("side_menu.door.edit_window")],
          editable: true
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    switch(currentView) {
      case DOOR_VIEW.ADD_DOOR:
      case DOOR_VIEW.EDIT_DOOR:
      case DOOR_VIEW.ADD_WINDOW:
      case DOOR_VIEW.EDIT_WINDOW:
        drawSend({
          type: "CHANGE_NAME",
          segment: currentDoor,
          name: e.detail.value,
        });
      break;
    }
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header 
    {...headerInfo} 
    on:change={handleTitleChange}
    hasBack={currentView !== DOOR_VIEW.INDEX}
    on:back={handleBack}
  />

  <div class="w-full flex flex-col flex-1 py-3 overflow-auto gap-4">
    {#if currentView === DOOR_VIEW.INDEX}
      <div class="w-full px-2.5">
        {#each doorsWithRoom as item}
          <div class="w-full py-7 px-1 border-t border-gray-100">
            <LongText text={item.room.getName($_)} class="text-base text-title font-semibold mb-3" />
            <SegmentList type={SEGMENT_LIST_TYPE.DOOR} segments={item.doors} on:select={handleSelectDoor} />
            <Button
              variant="primary-hover"
              title={type === DOORTYPE.DOOR ? $_("side_menu.door.add_door") : $_("side_menu.door.add_window")}
              icon="fa-light fa-plus"
              iconPos="center"
              class="w-full rounded-full mt-5"
              on:click={() => handleCreateDoor(item.room)}
            />
          </div>
        {/each}
      </div>
    {:else}
      <DoorInfo door={currentDoor} {type} on:back={handleBack}/>
    {/if}
    <!-- {#if currentView !== DOOR_VIEW.INDEX}
      <div class="flex items-center mt-auto">
        {#if currentView === DOOR_VIEW.ADD_DOOR || currentView === DOOR_VIEW.EDIT_DOOR || currentView === DOOR_VIEW.ADD_WINDOW || currentView === DOOR_VIEW.EDIT_WINDOW}
          <Button
            variant="outline-secondary"
            title={$_("side_menu.reset")}
            class="w-wide-btn ml-auto"
            on:click={handleReset}
          />
        {/if}
      </div>
    {/if} -->
  </div>
</div>
