<script lang="ts">
  import { drawState, drawSend } from "../../layout.store";
  import { Pointer, BuildingPart, Door } from "../../model";
  import {
    convertPointerToViewBox,
    getBuildingElement,
    makePointerInSvgBox,
    getMetricWithUnit,
    isWallProject,
    getFixedBuildingElement,
    isDoor,
    getImageBase64,
  } from "../../helpers";
  import { currentTool } from "src/store";
  import { TOOLS } from "src/global/types";
  import { METRIC_UNITS } from "src/global/variable";

  export let segment: BuildingPart;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;
  export let preview: boolean = false;

  let blobImage: string;
  let prevImage: string;
  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  $: doorElement = isDoor(segment) ? getFixedBuildingElement(
    isWall,
    (segment as Door).buildingType,
    (segment as Door).windowType
  ) : undefined;
  $: snapInfos = segment.getSnapInfos($drawState.context.current.segments);
  $: door = segment as Door;

  $: centerPointer = new Pointer(
      segment.startPointer.x + segment.width / 2, 
      segment.startPointer.y + segment.length / 2)
  $: isPrinting = $drawState.matches("main.printing") || $drawState.matches("main.printPreview")
  $: {
    if( prevImage !== segment.image ) {
      getImageBase64(segment.image).then((v: string) => {
        blobImage = v;
      })
      prevImage = segment.image;
    }
  }

  const segmentDown = (e: any) => {
    if(preview || $currentTool === TOOLS.EYE_DROPPER) return;
    if( selectedObject !== segment ) return;

    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
  }

  const segmentClick = (e: any) => {
    if(preview || $currentTool === TOOLS.EYE_DROPPER) return;
    drawSend({ type: "ENTER_SELECT", segment: segment });
    drawSend({ type: "SHOW_LINE_TOOL" });
    drawSend({ type: "MOUSE_DRAGGABLE" });
  };

  const segmentHovered = () => {
    if(preview) return;
    if ($drawState.matches("main.drawingState")) return;
    if( selectedObject === segment )
      drawSend({ type: "MOUSE_DRAGGABLE" });
    else
      drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if(preview) return;
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_LEAVE" });
  };

  const getRotation = () => {
    return segment.rotation;
  };
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
{#if segment}
<g id={segment.id}>
  <g
    transform={`
    rotate(${snapInfos.deltaAngle} ${
      snapInfos.snapPointer?.x ?? 0
    } ${snapInfos.snapPointer?.y ?? 0}) translate(${
      snapInfos.distance.deltaX
    } ${snapInfos.distance.deltaY}) 
    
     rotate(${getRotation()} ${
      segment.startPointer.x + segment.width / 2
    } ${segment.startPointer.y + segment.length / 2}) 
    matrix(${
      segment.width / (doorElement ? doorElement.width : segment.width)
    }, 0, 0, ${segment.length / (doorElement ? doorElement.height : segment.length)}, ${
      segment.startPointer.x
    }, ${segment.startPointer.y - 0.75})
    `}
    class={`${
      !preview && selectedObject === segment ? "segment_path_selected" : ""
    } segment_path segment`}
    on:click={segmentClick}
    on:mousedown={segmentDown}
    on:mouseover={segmentHovered}
    on:mouseleave={segmentMouseLeave}
  >
    <g transform={`${isWall && isDoor(segment) && door.wallOpenSide ? '' : 
      `translate(${doorElement ? doorElement.width : segment.width} 0) scale(-1, 1)`}`}>
      {#if doorElement}
        {@html doorElement.path}
      {:else if blobImage}
        <image 
          width={segment.width}
          height={segment.length}
          xlink:href={blobImage} 
          preserveAspectRatio="none"
        />
      {/if}
    </g>
  </g>
  {#if !preview && $drawState.matches("helper.showHelper") && 
      ($drawState.matches("main.printPreview") || selectedObject?.id === segment.id)}
    {#if isDoor(segment) || $drawState.matches("main.selectState.resizing")}
      <g
        transform={`rotate(${snapInfos.deltaAngle} ${
          snapInfos.snapPointer?.x ?? 0
        } ${snapInfos.snapPointer?.y ?? 0}) translate(${
          snapInfos.distance.deltaX
        } ${snapInfos.distance.deltaY})`}
      >
        {#each door.generateHelperPath(undefined, isWall) as helperItem}
          <path
            d={helperItem}
            fill="none"
            stroke-linecap="round"
            stroke="grey"
            stroke-width="0.3px"
          />
        {/each}
        {#each door.generateHelperTextData(undefined, isWall) as helperTextItem, j}
          <g>
            {#if helperTextItem}
              <text
                class="stroke-text"
                transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
                style={`font-size: ${16 / scale}px;`}
              >
                {getMetricWithUnit(
                  Math.round(helperTextItem.length * 100) / 100,
                  $drawState.context.currentMetricUnit,
                  /* !isPrinting && */ $drawState.context.currentMetricUnit === METRIC_UNITS[0]
                )}
              </text>
            {/if}
          </g>
        {/each}
      </g>
    {/if}
  {/if}
  {#if isPrinting && !isDoor(segment) && segment.printLabel}
    <g class="text-title" transform="translate({centerPointer.x}, {centerPointer.y})">
      <circle cx={0} cy={0} r={16 / scale} fill="#CCCCCC"/>
      <text
        dy="0.3em"
        style={`font-size: ${18 / scale}px;`}
      >
        {segment.printLabel}
      </text>
    </g>
  {/if}
</g>
{/if}

<style>
  text {
    font: 5px sans-serif;
    text-anchor: middle;
    pointer-events: none;
    user-select: none;
  }
</style>
