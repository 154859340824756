<script lang="ts">
  import { get } from "svelte/store";
  import Modal from "./Modal.svelte";
  import { _ } from "../../services/i18n";
  import Loading from "../util/Loading.svelte";
  import { drawState, drawService, drawSend } from "src/layout.store";
  import Select from "../base/Select.svelte";
  import { localeStore } from "src/store";
  import { SUPPORTED_LOCALIZATIONS } from "src/services/locales";
  import { METRIC_UNITS, QUERY_PARAMS } from "src/global/variable";
  import Switch from "../base/Switch.svelte";
  import Dropdown from "../base/Dropdown.svelte";
  import { onMount } from "svelte";
  
  $:currentMetric = metrics.find((v) => v.value === $drawState.context.currentMetricUnit) ?? metrics[0];

  let locale = get(localeStore) ?? SUPPORTED_LOCALIZATIONS.en

  onMount(() => {
    const unsubscribe = localeStore.subscribe((v) => {
      locale = v
    })
    return () => {
      unsubscribe();
    }
  })

  const flags = [
    { locale: SUPPORTED_LOCALIZATIONS.en, flag: '/flags/gb.svg', name: 'English'},
    { locale: SUPPORTED_LOCALIZATIONS.it, flag: '/flags/it.svg', name: 'Italiano'},
    { locale: SUPPORTED_LOCALIZATIONS.es, flag: '/flags/es.svg', name: 'Español'},
    { locale: SUPPORTED_LOCALIZATIONS.fr, flag: '/flags/fr.svg', name: 'Français'},
    { locale: SUPPORTED_LOCALIZATIONS.de, flag: '/flags/de.svg', name: 'Deutsch'},
    { locale: SUPPORTED_LOCALIZATIONS.pt, flag: '/flags/pt.svg', name: 'Português'},
    { locale: SUPPORTED_LOCALIZATIONS.cs, flag: '/flags/cz.svg', name: 'Čeština'},
    { locale: SUPPORTED_LOCALIZATIONS.hu, flag: '/flags/hu.svg', name: 'Magyar'},
  ]

  $: currentFlag = flags.find((v) => v.locale === locale) ?? flags[0]

  const updateLocale = (newLocale) => {
    localeStore.set(newLocale)
    if (history.pushState) {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${QUERY_PARAMS.LOCALE}=${newLocale}`;
      window.history.pushState({path:newurl},'',newurl);
    }
  }

  $: metrics = [
    { value: METRIC_UNITS[0], label: $_('action_menu.setting.metric')},
    { value: METRIC_UNITS[1], label: $_('action_menu.setting.imperial')}
  ]

  const updateGrid = () => {
    drawSend({ type: "TOGGLE_GRID" });
  };

  const updateHelper = () => {
    drawSend({ type: "TOGGLE_HELPER" });
  };
  
  const updateMetric = (v: string) => {
    drawSend({ type: "CHANGE_METRIC", newMetricUnit: v });
  };
</script>

<Modal containerClass="rounded-3xl w-setting">
  <div slot="trigger" let:open>
    <button class="ml-auto w-12 h-12 flex items-center justify-center border-0 rounded-lg hover:bg-gray-200" on:click={open}>
      <i class="fa-light fa-cog fa-custom-lg font-black text-secondary-500" title={$_("action_menu.settings")} />
    </button>
  </div>
  <div slot="header" let:close>
    <div class="flex justify-between items-center gap-3 px-6 py-4">
      <div class="text-heading-4 font-bold text-title">{$_(`action_menu.setting.title`)}</div>
      <i
        class="fa-solid fa-xmark cursor-pointer fa-2x text-title"
        color={"black"}
        on:click={close}
        on:keydown
      />
    </div>
  </div>
  <div slot="content" class="divide-y px-6 pb-8">
    <div class="grid grid-cols-2 items-center gap-3 py-5">
      <div class="text-base font-bold w-full">{$_(`action_menu.setting.lang`)}</div>
      <Dropdown class="w-full" buttonClassName="flex items-center justify-between px-3 py-3 rounded border border-gray-200 cursor-pointer" dropClassName="mt-1" fitContent arrow>
        <button slot="button" class="btn-default w-full flex items-center gap-2">
          <img src={currentFlag.flag} alt={currentFlag.locale} class="w-6" />
          <span>{currentFlag.name}</span>
        </button>
        {#each flags as item}
          {#if item.locale !== locale}
          <li>
            <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={() => {updateLocale(item.locale)}}>
              <img src={item.flag} alt={item.locale} class="w-6"/>
              <span>{item.name}</span>
            </button>
          </li>
          {/if}
        {/each}
      </Dropdown>
    </div>
    <div class="grid grid-cols-2 items-center gap-3 py-5">
      <div class="text-base font-bold w-full">{$_(`action_menu.setting.measure_unit`)}</div>
      <Dropdown 
        class="w-full rounded border border-gray-200 cursor-pointer" 
        buttonClassName="w-full px-3 py-3 justify-between"
        dropClassName="mt-2"
        title={currentMetric.label} 
        arrow
        fitContent
      >
        {#each metrics as item}
          <li>
            <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={() => {updateMetric(item.value)}}>
              <span>{item.label}</span>
            </button>
          </li>
        {/each}
      </Dropdown>
    </div>
    <div class="flex items-center justify-between gap-3 py-5">
      <div class="text-base font-bold w-full">{$_(`action_menu.setting.show_grid`)}</div>
      <Switch value={$drawState.matches("grid.showGrid")} on:change={updateGrid}/>
    </div>
    <div class="flex items-center justify-between gap-3 py-5">
      <div class="text-base font-bold w-full">{$_(`action_menu.setting.show_dimensions`)}</div>
      <Switch value={$drawState.matches("helper.showHelper")} on:change={updateHelper}/>
    </div>
  </div>
</Modal>
