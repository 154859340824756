import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { Pointer, TileWrapper } from "src/model";
import { getShapeBoundingRect, getShapePath } from "../helpers";
import { beforeUpdate } from "svelte";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { InstancedLine } from '../model/InstancedLine';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { TILE_TRANSFORM_SCALE } from 'src/global/variable';
import { isEmpty } from "lodash";
import { cachedTileImages } from "src/store";
import { get } from "svelte/store";
import { getTileImage } from "src/services/api";
export default class WebGLRenderer {
    // public tiles: ShapeInstance[];
    canvas;
    renderer;
    renderTarget;
    rooms;
    // private tileGroup: THREE.Group;
    // private svgPath: string;
    // private rotation;
    // private offset;
    rotationAxis;
    resolution;
    scene;
    camera;
    composer;
    outlinePass;
    effectFXAA;
    zoom;
    originW;
    animationFrameId;
    animationDebugFrameId;
    pointer;
    raycaster;
    intersectedFurniture;
    constructor() {
        this.resolution = 2;
        // this.rotation = 0;
        // this.offset = new THREE.Vector3(0, 0, 0);
        this.rotationAxis = new THREE.Vector3(0, 0, 1);
        this.scene = new THREE.Scene();
        const w2 = window.innerWidth / 2;
        const h2 = window.innerHeight / 2;
        // this.camera = new THREE.OrthographicCamera(window.innerWidth, window.innerWidth, 0, window.innerHeight, 0.1, 2),
        this.camera = new THREE.OrthographicCamera(-w2, w2, h2, -h2, 0.1, 20);
        this.camera.position.z = 10;
        this.camera.zoom = TILE_TRANSFORM_SCALE;
        this.zoom = 1;
        this.originW = 0.4 * window.innerWidth;
        // this.scene.add(new THREE.Mesh(new THREE.BoxGeometry(1,1,1), new THREE.MeshBasicMaterial({ color: 0xff0000 })));
        this.pointer = new THREE.Vector2();
        this.raycaster = new THREE.Raycaster();
    }
    clearRoom(room) {
        if (room.mainGroup !== undefined) {
            room.mainGroup.clear();
            room.mainGroup.removeFromParent();
            room.mainGroup = undefined;
            room.linesGroup = undefined;
            room.tilesGroup = undefined;
        }
        room.tilesImages = [];
        this.disposeStencilMeshes(room);
    }
    disposeStencilMeshes(room) {
        room.stencilMeshes.forEach(m => {
            this.scene.remove(m);
            m.geometry.dispose();
            if (m.material instanceof Array) {
                m.material.forEach(material => material.dispose());
            }
            else {
                m.material.dispose();
            }
            m = undefined;
        });
    }
    updatePanAndZoom(viewBox, scale) {
        const w2 = viewBox.w / 2;
        const h2 = viewBox.h / 2;
        const offsetx = (w2 + viewBox.x) / this.camera.zoom;
        const offsety = (h2 + viewBox.y) / this.camera.zoom;
        this.camera.left = -w2 + offsetx;
        this.camera.right = w2 + offsetx;
        this.camera.top = h2 - offsety;
        this.camera.bottom = -h2 - offsety;
        this.camera.updateProjectionMatrix();
        // this.zoom = viewBox.w / this.originW;
        this.zoom = scale;
    }
    resizeRenderer() {
        let w = this.canvas.width * this.resolution;
        let h = this.canvas.height * this.resolution;
        this.renderer.setSize(w, h, false);
        this.composer.setSize(w, h);
        // this.canvas.style.width = w / this.resolution + 'px';
        // this.canvas.style.height = h / this.resolution + 'px';
    }
    setTiles(room) {
        let tiles = room.getTiles();
        room.previousOffset = new Pointer(0, 0);
        // if (tiles !== undefined && tiles.length > 0)
        this.drawSVG(room, tiles);
        // else
        //   this.clearRoom(room);
        this.updateScene();
    }
    updateFurnitures(room) {
        if (room.furnituresGroup?.children.length > 0) {
            room.furnituresGroup.rotateX(THREE.MathUtils.degToRad(90));
            this.scene.add(room.furnituresGroup);
            // for(const c of room.furnituresGroup?.children)
            //   this.outlinePass.selectedObjects.push(c);
            this.updateScene();
        }
    }
    updateTransform(room) {
        this.applyTileTransform(room);
    }
    initScene() {
        this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas, preserveDrawingBuffer: true });
        // this.renderTarget = new THREE.WebGLRenderTarget(this.canvas.width, this.canvas.height, { samples: 4, type: THREE.HalfFloatType, stencilBuffer: true });
        this.renderTarget = new THREE.WebGLRenderTarget(this.canvas.width, this.canvas.height, { stencilBuffer: true });
        this.renderer.setClearColor(new THREE.Color(0xffffff));
        // this.renderer.autoClear = false;
        this.composer = new EffectComposer(this.renderer, this.renderTarget);
        // this.composer = new EffectComposer(this.renderer);
        const renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(renderPass);
        // this.outlinePass = new OutlinePass(new THREE.Vector2(4000, 4000), this.scene, this.camera);
        // this.composer.addPass(this.outlinePass);
        // this.outlinePass.edgeStrength = 10;
        // this.outlinePass.edgeThickness = 1;
        // this.outlinePass.visibleEdgeColor.set(0x007fff);
        // this.outlinePass.hiddenEdgeColor.set(0x777fff);
        // this.outlinePass.overlayMaterial.blending = THREE.CustomBlending;
        // const outputPass = new OutputPass();
        // this.composer.addPass(outputPass);
        // const fxaaPass = new ShaderPass( FXAAShader );
        // fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( this.canvas.width * this.resolution );
        // fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( this.canvas.height * this.resolution );
        // console.log("pixel ratio: " + this.renderer.getPixelRatio());
        // console.log("shader width resolution: " + 1 / ( this.canvas.width * this.resolution ));
        // console.log("shader height resolution: " + 1 / ( this.canvas.height * this.resolution ));
        // this.composer.addPass(fxaaPass);
        this.resizeRenderer();
        // const light = new THREE.AmbientLight(0xaaaaaa); // soft white light
        // this.scene.add(light);
        // const directionalLight = new THREE.PointLight(0xffffff);
        // directionalLight.position.set(10, 10, 10);
        // this.scene.add(directionalLight);
        // document.addEventListener('mousemove', this.onPointerMove);
    }
    removeScene() {
        if (!this.renderer)
            return;
        if (this.animationFrameId !== undefined) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = undefined;
        }
        this.composer.dispose();
        this.renderer.dispose();
        this.renderer.forceContextLoss();
        this.renderer = undefined;
        this.composer = undefined;
        // document.removeEventListener('mousemove', this.onPointerMove);
    }
    onPointerMove(event) {
        if (window.bounding !== true)
            return;
        let size = new THREE.Vector2();
        this.renderer.getSize(size);
        // this.pointer.x = (event.clientX / size.x / this.resolution) * 2 - 1;
        // this.pointer.y = -(event.clientY / size.y / this.resolution) * 2 + 1;
        this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
        // console.log(this.pointer);
        this.raycaster.setFromCamera(this.pointer, this.camera);
        for (var room of this.rooms) {
            if (!room.furnituresGroup || room.furnituresGroup.children.length === 0)
                continue;
            const intersects = this.raycaster.intersectObjects(room.furnituresGroup.children, false);
            if (intersects.length > 0) {
                if (this.intersectedFurniture != intersects[0].object) {
                    if (this.intersectedFurniture)
                        this.intersectedFurniture.material.color.setHex(this.intersectedFurniture.currentHex);
                    this.intersectedFurniture = intersects[0].object;
                    this.intersectedFurniture.currentHex = this.intersectedFurniture.material.color.getHex();
                    this.intersectedFurniture.material.color.setHex(0x0000ff);
                }
            }
            else {
                if (this.intersectedFurniture)
                    this.intersectedFurniture.material.color.setHex(this.intersectedFurniture.currentHex);
                this.intersectedFurniture = undefined;
            }
        }
        this.render();
    }
    animate(room) {
        this.animationFrameId = requestAnimationFrame(() => this.animate(room));
        const indexTiles = room.highlightTile.index;
        for (var child of room.tilesGroup[indexTiles].children) {
            const instancedMesh = child;
            const material = instancedMesh.material;
            material.uniforms.highlight.value = true;
            material.uniforms.time.value = performance.now() / 1000;
        }
        //pulse animation
        // const elapsedTime = room.clock.getElapsedTime();
        // const pulseSize = 0.2; //scaling value (from 0 to 1)
        // const pulseIn = 2;
        // const pulseOut = 2;
        // const pulseDuration = pulseIn + pulseOut;
        // const pulseTime = (elapsedTime % pulseDuration);
        // for (let tilesGroup of room.tilesGroup[indexTiles].children)
        // {
        //   const mesh = (tilesGroup as THREE.InstancedMesh);
        //   const instances = mesh.count;
        //   let m4: THREE.Matrix4 = new THREE.Matrix4;
        //   for (let i = 0; i < instances; ++i)
        //   {
        //     let translation = new THREE.Vector3;
        //     let rotation = new THREE.Quaternion;
        //     let scale = new THREE.Vector3;
        //     let pulseX = room.backupScale[indexTiles].x * pulseSize;
        //     let pulseY = room.backupScale[indexTiles].y * pulseSize;
        //     mesh.getMatrixAt(i, m4);
        //     m4.decompose(translation, rotation, scale);
        //     if (pulseTime <= pulseIn)
        //     {
        //       var x = pulseX * (pulseTime / pulseIn);
        //       var y = pulseY * (pulseTime / pulseIn);
        //     }
        //     else
        //     {
        //       x = pulseX - pulseX * ((pulseTime - pulseIn) / pulseOut);
        //       y = pulseY - pulseY * ((pulseTime - pulseIn) / pulseOut);
        //     }
        //     scale.set(room.backupScale[indexTiles].x - x,
        //               room.backupScale[indexTiles].y - y,
        //               room.backupScale[indexTiles].z);
        //     m4.compose(translation, rotation, scale);
        //     mesh.setMatrixAt(i, m4);
        //   }
        //   mesh.instanceMatrix.needsUpdate = true;
        // }
        this.render();
    }
    cancelAnimation(room) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = undefined;
        const indexTiles = room.highlightTile.index;
        for (var child of room.tilesGroup[indexTiles].children) {
            const instancedMesh = child;
            const material = instancedMesh.material;
            material.uniforms.highlight.value = false;
            material.uniforms.time.value = 1.0;
        }
        //reset tiles scale
        // for (let tilesGroup of room.tilesGroup[indexTiles].children)
        // {
        //   const mesh = (tilesGroup as THREE.InstancedMesh);
        //   const instances = mesh.count;
        //   let m4: THREE.Matrix4 = new THREE.Matrix4;
        //   for (let i = 0; i < instances; ++i)
        //   {
        //     let translation = new THREE.Vector3;
        //     let rotation = new THREE.Quaternion;
        //     let scale = new THREE.Vector3;
        //     mesh.getMatrixAt(i, m4);
        //     m4.decompose(translation, rotation, scale);
        //     scale.set(room.backupScale[indexTiles].x, room.backupScale[indexTiles].y, room.backupScale[indexTiles].z);
        //     m4.compose(translation, rotation, scale);
        //     mesh.setMatrixAt(i, m4);
        //   }
        //   mesh.instanceMatrix.needsUpdate = true;
        // }
        this.render();
    }
    animateDebug(rooms) {
        this.animationDebugFrameId = requestAnimationFrame(() => this.animateDebug(rooms));
        for (var room of rooms) {
            if (room.furnituresGroup?.children.length > 0 && window.rotate === true) {
                // room.furnituresGroup.translateY(-0.1);
                room.furnituresGroup.rotateX(0.01);
                // room.furnituresGroup.rotateY(0.01);
            }
        }
        this.render();
    }
    cancelDebugAnimation() {
        if (!this.animationDebugFrameId)
            return;
        cancelAnimationFrame(this.animationDebugFrameId);
        this.animationDebugFrameId = undefined;
        this.render();
    }
    updateScene(reset = false) {
        if (reset)
            this.initScene();
        this.render();
    }
    updateZoom(room) {
        if (room.linesGroup === undefined)
            return;
        for (var child of room.linesGroup.children) {
            // if (child.isMesh && child.material.uniforms ==! undefined)
            child.material.uniforms.zoom.value = this.zoom;
        }
    }
    applyTileTransform(room) {
        if (room.mainGroup === undefined)
            return;
        let shapeBounding = getShapeBoundingRect(room.shape);
        const offsetx = (shapeBounding[0] + shapeBounding[2] / 2) / this.camera.zoom;
        const offsety = (shapeBounding[1] + shapeBounding[3] / 2) / this.camera.zoom;
        room.mainGroup.translateX(offsetx);
        room.mainGroup.translateY(-offsety);
        room.mainGroup.rotation.z = -THREE.MathUtils.degToRad(room.rotation);
        // room.tileGroup.rotateZ(THREE.MathUtils.degToRad(room.rotation));
        let off = new THREE.Vector3(room.offset.x, room.offset.y, 0);
        off.x = room.offset.x - room.previousOffset.x;
        off.y = room.offset.y - room.previousOffset.y;
        off.applyAxisAngle(this.rotationAxis, THREE.MathUtils.degToRad(-room.rotation));
        let pixelToUnit = 0.05;
        room.mainGroup.translateX(off.x * pixelToUnit).translateY(-off.y * pixelToUnit);
        room.previousOffset.x = room.offset.x;
        room.previousOffset.y = room.offset.y;
        room.mainGroup.translateX(-offsetx);
        room.mainGroup.translateY(offsety);
        this.render();
        return;
    }
    drawSVG(room, tiles) {
        let loader = new SVGLoader();
        // STENCIL
        let svgData = loader.parse("<path d='" + getShapePath(room.shape) + "' xmlns='http://www.w3.org/2000/svg'/>");
        // let svgData = loader.parse("<path d='m -0.7 -0.9 l -0.5 2.4 l 0.5 0.9 l 0.6 -0.1 l 0.7 -0.3 l 0.1 -0.5 l -0.2 -0.5 l -0.1 -0.4 l -0.4 0.1 l -0.3 -0 z' xmlns='http://www.w3.org/2000/svg'/>");
        this.clearRoom(room);
        svgData.paths.forEach((path) => {
            const shapes = SVGLoader.createShapes(path);
            shapes.forEach((shape) => {
                // const shape = shapes[ j ];
                // const geometry = new THREE.ShapeGeometry( shape );
                const planeGeom = new THREE.ShapeGeometry(shape);
                // const stencilMat = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff });
                const stencilMat = new THREE.MeshBasicMaterial();
                stencilMat.depthWrite = false;
                // stencilMat.colorWrite = false;
                stencilMat.stencilWrite = true;
                stencilMat.stencilRef = room.stencilBufferReference;
                stencilMat.stencilFunc = THREE.AlwaysStencilFunc;
                stencilMat.stencilZPass = THREE.ReplaceStencilOp;
                room.stencilMeshes.push(new THREE.Mesh(planeGeom, stencilMat));
                // const mesh = new THREE.Mesh( geometry, stencilMat );
                let lastElem = room.stencilMeshes.at(-1);
                lastElem.scale.x /= this.camera.zoom;
                lastElem.scale.y /= -this.camera.zoom;
                // lastElem.position.z = 0;
                this.scene.add(lastElem);
            });
        });
        if (tiles === undefined || tiles.length === 0)
            return;
        // FLOOR LAYOUT
        room.linesGroup = new THREE.Group();
        room.tilesGroup = [];
        room.mainGroup = new THREE.Group();
        //invert Y scale because threejs renders svg upsidedown
        room.mainGroup.scale.y *= -1;
        let shapeBounding = getShapeBoundingRect(room.shape, room.rotation);
        const offsetx = (shapeBounding[0] + shapeBounding[2] / 2) / this.camera.zoom;
        const offsety = (shapeBounding[1] + shapeBounding[3] / 2) / this.camera.zoom;
        // let textureLoader = new THREE.TextureLoader();
        // textureLoader.setRequestHeader({
        //   "Authorization"  : `Bearer ${get(accessToken) || DEFAULT_ACCESS_TOKEN}`,
        //   "Accept-Language": get(localeStore) ?? "en-GB",
        //   "Access-Control-Allow-Origin": "*"
        // });
        // textureLoader.setCrossOrigin("*");
        // textureLoader.setWithCredentials(true);
        const shapes = room.tileLayout.shapes;
        // map tiles by tileId, width and height
        // let mapTiles: ShapeInstance[][] = [];
        let mapTiles = new Map();
        tiles.forEach(tile => {
            // let find = mapTiles.find(e =>
            //   e[0].shape.tileId === tile.shape.tileId &&
            //   e[0].shape.width  === tile.shape.width &&
            //   e[0].shape.height === tile.shape.height
            // );
            let index = shapes.findIndex(s => tile.shape === s);
            if (index >= 0) {
                let find = mapTiles.get(index);
                if (find === undefined)
                    mapTiles.set(index, [tile]);
                else
                    find.push(tile);
            }
            //theoretically it should never hit this "else".
            else
                console.log("ERROR - Tile not found");
        });
        mapTiles.forEach((tileArray, indexTiles) => {
            let transformList = [];
            let mapByMesh = new Map();
            let mapTileRotation = new Map();
            room.tilesGroup[indexTiles] = new THREE.Group();
            svgData = loader.parse("<path d='" + tileArray[0].shape.path.toSvgPath() + "' xmlns='http://www.w3.org/2000/svg'/>");
            //TEMP - remove overlapped tiles (at only moment fishscale layout)
            for (var i = tileArray.length - 1; i >= 0;) {
                let find = tileArray.slice(0, i).findLastIndex(ttest => {
                    return Math.abs(tileArray[i].transform.elements[2] - ttest.transform.elements[2]) < 0.05 &&
                        Math.abs(tileArray[i].transform.elements[5] - ttest.transform.elements[5]) < 0.05;
                });
                if (find !== -1)
                    tileArray.splice(i, 1);
                --i;
            }
            tileArray.forEach(tile => {
                let find;
                let t = tile.transform.elements;
                let m4 = new THREE.Matrix4();
                m4.set(t[0], t[1], 0, offsetx + t[2], t[3], t[4], 0, offsety + t[5], 0, 0, 1, 0, 0, 0, 0, 1);
                transformList.push(m4);
                if (!isEmpty(tile.shape.tileData)) {
                    let imageUrl = tile.shape.tileData.images[Math.floor(Math.random() * tile.shape.tileData.images.length)];
                    find = mapByMesh.get(imageUrl);
                    if (find === undefined)
                        mapByMesh.set(imageUrl, [m4]);
                    else
                        find.push(m4);
                    mapTileRotation.set(imageUrl, tile.shape.rotation);
                }
                else {
                    find = mapByMesh.get("no_image");
                    if (find === undefined)
                        mapByMesh.set("no_image", [m4]);
                    else
                        find.push(m4);
                }
            });
            svgData.paths.forEach((path) => {
                const shapes = SVGLoader.createShapes(path);
                shapes.forEach((shape, indexShape) => {
                    // shape.curves.forEach((curve) => {
                    //   if(curve.type === 'LineCurve')
                    //   {
                    //     const line = new InstancedLine(curve, transformList);
                    //     // if( indexShape === 0 ) {
                    //     //   line.setColorAt(0, new THREE.Color(1, 0, 0))
                    //     // }
                    //     room.linesGroup.add(line.getMesh(this.zoom, room.stencilBufferReference));
                    //   }
                    //   else
                    //   {
                    //     const line = new InstancedLine( curve, transformList, 20 );
                    //     // if( indexShape === 0 ) {
                    //     //   line.setColorAt(0, new THREE.Color(1, 0, 0))
                    //     // }
                    //     room.linesGroup.add(line.getMesh(this.zoom, room.stencilBufferReference));
                    //   }
                    // });
                    mapByMesh.forEach((transform, key) => {
                        // BASE64 IMAGE
                        // let image = new Image();
                        // let texture = new THREE.Texture();
                        // image.src = "data:image/png;base64," + imageUrl;
                        // image.onload = () => {
                        //   texture.image = image;
                        // BASE64 IMAGE
                        // getTileImage(imageUrl).then(res => {
                        const geometry = new THREE.ShapeGeometry(shape);
                        const attUv = geometry.attributes.uv;
                        const pos = geometry.attributes.position;
                        let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
                        for (let i = 0; i < attUv.count; i++) {
                            let u = attUv.getX(i);
                            let v = attUv.getY(i);
                            minX = Math.min(u, minX);
                            maxX = Math.max(u, maxX);
                            minY = Math.min(v, minY);
                            maxY = Math.max(v, maxY);
                        }
                        for (let i = 0; i < attUv.count; i++) {
                            let u = attUv.getX(i);
                            let v = attUv.getY(i);
                            attUv.setXY(i, (u - minX) / (maxX - minX), (v - minY) / (maxY - minY));
                        }
                        function vertexShader() {
                            return `
                varying vec3 pos;
                varying vec2 texCoord;
                void main()
                {
                  pos = position;
                  texCoord = uv;
                  //vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                  //gl_Position = projectionMatrix * modelViewPosition;
                  gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(position, 1.0);
                }
              `;
                        }
                        const fragmentShader = () => {
                            return `
                precision highp float;
                precision highp int;
                uniform float time;
                uniform bool highlight;
                uniform bool noImage;
                uniform sampler2D utexture;
                varying vec3 pos;
                varying vec2 texCoord;
                void main()
                {
                    // Normalized pixel coordinates (from 0 to 1)
                    //vec2 _2dcoord = gl_FragCoord.xy / resolution.xy;
                    vec2 _2dcoord = pos.xy;
                    float stripeWidth = 250.;
                    float stripeAlpha = 100.;
                    float colorWidth = .5;
                    float offset = 0.5 * (1. - colorWidth);
                    float direction = 0.3; // 0: vertical, 1. horizontal
                    float alphaHighlight = .8;
                    // vec3 labelColor = 0.5 + 0.5 * cos(time + _2dcoord.xyx + vec3(0,2,4));
                    //vec3 labelColor = 0.5 + 0.5 * cos(_2dcoord.xyx + vec3(0,2,4));
                    vec3 labelColor = vec3(0.761, 0.58, 0.212);

                    //float normalizedWidth = resolution.x / stripeWidth;
                    //float pos = mix(_2dcoord.x, _2dcoord.y, direction);
                    float pos = -time + mix(_2dcoord.x, _2dcoord.y, direction) * 5.;
                    float fracPos = fract(pos);
                    float smoothed = smoothstep(offset, offset + 2. / stripeAlpha, fracPos) * (1. - smoothstep(1. - offset - 2. / stripeAlpha, 1. - offset, fracPos));

                    // if (_2dcoord.x < -1. || _2dcoord.y < -1.)
                    //   gl_FragColor = vec4(1., 1., 0., 1.0);
                    // else
                    if (noImage)
                    {
                      // // dashed lines
                      // // if (smoothed < 1.0 || mod(_2dcoord.y, 0.25) < 0.035)
                      // if (smoothed < 1.0)
                      //   gl_FragColor = vec4(0.99, 0.99, 0.99, 1.0);
                      // else if (highlight)
                      // {
                      //   if (mod(pos, 2.) >= 1.)
                      //     labelColor = vec3(0.118, 0.125, 0.251);

                      //   gl_FragColor = vec4(labelColor * smoothed, smoothed);
                      // }
                      // else
                      //   gl_FragColor = vec4(0.95, 0.95, 0.95, 1.0);

                      if (highlight && smoothed == 1.0)
                      {
                        if (mod(pos, 2.) >= 1.)
                          labelColor = vec3(0.118, 0.125, 0.251);

                        gl_FragColor.rgb = vec3(1., 1., 1.) * (1. - alphaHighlight) + labelColor * alphaHighlight;
                        gl_FragColor.a = 1.0;
                      }
                      else
                        gl_FragColor = vec4(1., 1., 1., 1.0);
                    }
                    else
                    {
                      vec4 textureColor = texture(utexture, texCoord);
                      if (highlight && smoothed == 1.0)
                      {
                        if (mod(pos, 2.) >= 1.)
                          labelColor = vec3(0.118, 0.125, 0.251);

                        gl_FragColor.rgb = textureColor.rgb * textureColor.a * (1. - alphaHighlight) + labelColor * alphaHighlight;
                        gl_FragColor.a = 1.0;
                      }
                      else
                      {
                        gl_FragColor.rgb = textureColor.rgb;
                        gl_FragColor.a = textureColor.a;
                      }
                    }
                }`;
                        };
                        const shaderMaterial = new THREE.ShaderMaterial({
                            vertexShader: vertexShader(),
                            fragmentShader: fragmentShader(),
                            // side: THREE.DoubleSide,
                            depthTest: false,
                            transparent: true
                        });
                        shaderMaterial.stencilWrite = true;
                        shaderMaterial.stencilRef = room.stencilBufferReference;
                        shaderMaterial.stencilFunc = THREE.EqualStencilFunc;
                        if (key === "no_image") {
                            shaderMaterial.uniforms = {
                                noImage: { value: true },
                                utexture: { value: null },
                                highlight: { value: false },
                                time: { value: 0.0 },
                            };
                            const mesh = new THREE.InstancedMesh(geometry, shaderMaterial, transform.length);
                            for (let i = 0; i < transform.length; i++) {
                                mesh.setMatrixAt(i, transform[i]);
                                // let v3 = new THREE.Vector3;
                                // let copyMatrix = new THREE.Matrix4;
                                // copyMatrix.copy(transform[i]);
                                // v3.setFromMatrixPosition(transform[i]);
                                // v3.z += 1;
                                // copyMatrix.setPosition(v3);
                                //backup scale once for each tile group
                                if (i === 0) {
                                    let v3 = new THREE.Vector3;
                                    v3.setFromMatrixScale(transform[i]);
                                    room.backupScale[indexTiles] = v3;
                                }
                                //test to check overlapped tiles
                                // if (i !== 0)
                                // {
                                //   mesh.setColorAt(i, new THREE.Color('red'));
                                //   let matrix4 = new THREE.Matrix4();
                                //   mesh.getMatrixAt(i, matrix4);
                                //   let v3 = new THREE.Vector3().setFromMatrixPosition(matrix4);
                                //   v3.x += -1;
                                //   v3.z = 0.05;
                                //   mesh.setMatrixAt(i, matrix4.setPosition(v3));
                                // }
                            }
                            room.tilesGroup[indexTiles].add(mesh);
                            this.updateScene();
                        }
                        else {
                            const cachedTileImagesStore = get(cachedTileImages);
                            let cachedPromise = cachedTileImagesStore.get(key);
                            if (!cachedPromise) {
                                // const promises = sources.map((source) => {
                                //   return new Promise((resolve) => {
                                //     var img = new Image();
                                //     img.src = source;
                                //     img.onload = () => resolve();
                                //   });
                                // });
                                const promise = new Promise((resolve, reject) => {
                                    var img = new Image();
                                    img.crossOrigin = "anonymous";
                                    img.src = key;
                                    img.onload = () => resolve(img);
                                });
                                cachedTileImagesStore.set(key, promise);
                                cachedPromise = promise;
                            }
                            cachedPromise.then(img => {
                                const imgWidth = img.width, imgHeight = img.height;
                                const mapCanvas = document.createElement('canvas');
                                const textureCanvas = document.createElement('canvas');
                                const rotation = mapTileRotation.get(key);
                                if (rotation) {
                                    const mapCanvasSize = Math.max(img.width, img.height);
                                    mapCanvas.width = mapCanvasSize;
                                    mapCanvas.height = mapCanvasSize;
                                    // document.body.appendChild( mapCanvas );
                                    const ctx = mapCanvas.getContext('2d');
                                    ctx.translate(mapCanvasSize / 2, mapCanvasSize / 2);
                                    ctx.rotate(-rotation * Math.PI / 180);
                                    ctx.translate(-mapCanvasSize / 2, -mapCanvasSize / 2);
                                    ctx.drawImage(img, (mapCanvasSize - imgWidth) / 2, (mapCanvasSize - imgHeight) / 2, imgWidth, imgHeight);
                                    let newWidth = imgWidth;
                                    let newHeight = imgHeight;
                                    if (rotation === 90 || rotation === 180) {
                                        newWidth = imgHeight;
                                        newHeight = imgWidth;
                                    }
                                    textureCanvas.width = newWidth;
                                    textureCanvas.height = newHeight;
                                    const ctxTexture = textureCanvas.getContext('2d');
                                    ctxTexture.drawImage(mapCanvas, (mapCanvasSize - newWidth) / 2, (mapCanvasSize - newHeight) / 2, newWidth, newHeight, 0, 0, newWidth, newHeight);
                                }
                                else {
                                    mapCanvas.width = imgWidth;
                                    mapCanvas.height = imgHeight;
                                    // document.body.appendChild(mapCanvas);
                                    const ctx = mapCanvas.getContext('2d');
                                    ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
                                }
                                const texture = new THREE.Texture(rotation ? textureCanvas : mapCanvas);
                                texture.needsUpdate = true;
                                texture.colorSpace = THREE.LinearSRGBColorSpace;
                                texture.flipY = false;
                                shaderMaterial.uniforms = {
                                    noImage: { value: false },
                                    utexture: { value: texture },
                                    highlight: { value: false },
                                    time: { value: 0.0 },
                                };
                                const mesh = new THREE.InstancedMesh(geometry, shaderMaterial, transform.length);
                                for (let i = 0; i < transform.length; i++) {
                                    mesh.setMatrixAt(i, transform[i]);
                                    //backup scale once for each tile group
                                    if (i === 0) {
                                        let v3 = new THREE.Vector3;
                                        v3.setFromMatrixScale(transform[i]);
                                        room.backupScale[indexTiles] = v3;
                                    }
                                    //test to check overlapped tiles
                                    // if (i !== 0)
                                    // {
                                    //   mesh.setColorAt(i, new THREE.Color('red'));
                                    //   let matrix4 = new THREE.Matrix4();
                                    //   mesh.getMatrixAt(i, matrix4);
                                    //   let v3 = new THREE.Vector3().setFromMatrixPosition(matrix4);
                                    //   v3.x += -1;
                                    //   v3.z = 0.05;
                                    //   mesh.setMatrixAt(i, matrix4.setPosition(v3));
                                    // }
                                }
                                room.tilesGroup[indexTiles].add(mesh);
                                this.updateScene();
                            });
                            room.tilesImages.push(cachedPromise);
                            // textureLoader.load(key, texture => {
                            //   texture.needsUpdate = true;
                            //   texture.colorSpace = THREE.LinearSRGBColorSpace;
                            //   texture.flipY = false;
                            //   texture.center = new THREE.Vector2(0.5, 0.5);
                            //   texture.rotation = Math.PI / 2;
                            //   texture.updateMatrix();
                            //   console.log(texture.matrix);
                            //   // const mat = new THREE.Matrix3();
                            //   // mat.set(0, 1, 0, -1, 0, 0, 0, 0, 1);
                            //   // texture.matrix = mat;
                            //   // const material = new THREE.MeshBasicMaterial({ map: texture });
                            //   // material.stencilWrite = true;
                            //   // material.stencilRef   = room.stencilBufferReference;
                            //   // material.stencilFunc  = THREE.EqualStencilFunc;
                            //   shaderMaterial.uniforms = {
                            //     noImage: { value: false },
                            //     utexture: { value: texture },
                            //     highlight: { value: false },
                            //     time: { value: 0.0 },
                            //   };
                            //   const mesh = new THREE.InstancedMesh(geometry, shaderMaterial, transform.length);
                            //   for(let i = 0; i < transform.length; i ++)
                            //   {
                            //     mesh.setMatrixAt(i, transform[i]);
                            //     //backup scale once for each tile group
                            //     if (i === 0)
                            //     {
                            //       let v3 = new THREE.Vector3;
                            //       v3.setFromMatrixScale(transform[i]);
                            //       room.backupScale[indexTiles] = v3;
                            //     }
                            //     //test to check overlapped tiles
                            //     // if (i !== 0)
                            //     // {
                            //     //   mesh.setColorAt(i, new THREE.Color('red'));
                            //     //   let matrix4 = new THREE.Matrix4();
                            //     //   mesh.getMatrixAt(i, matrix4);
                            //     //   let v3 = new THREE.Vector3().setFromMatrixPosition(matrix4);
                            //     //   v3.x += -1;
                            //     //   v3.z = 0.05;
                            //     //   mesh.setMatrixAt(i, matrix4.setPosition(v3));
                            //     // }
                            //   }
                            //   room.tilesGroup[indexTiles].add(mesh);
                            //   this.updateScene();
                            // });
                        }
                    });
                });
            });
        });
        room.mainGroup.add(room.linesGroup);
        room.mainGroup.add(...room.tilesGroup);
        this.scene.add(room.mainGroup);
        //outlinePass.selectedObjects = group.children;
    }
    render() {
        // this.renderer?.render(this.scene, this.camera);
        this.composer?.render();
    }
}
