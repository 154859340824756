<script lang="ts">
  import { drawSend, drawState } from "../../layout.store";
  import { BuildingPart, ClosedArea, Door, Line, Pointer, Segment, TileWrapper } from "../../model";
  import {
    convertPointerToViewBox,
    getMetricWithUnit,
    isBuildingPart,
    isClosedArea,
    isDoor,
    isLine,
    isTileWrapper,
    isArc,
    makePointerInSvgBox,
    isWallProject,
  } from "../../helpers";
  import { currentTool } from "src/store";
  import { TOOLS } from "src/global/types";
  import { METRIC_UNITS } from "src/global/variable";

  export let segment: Segment;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;

  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: drawingObject = $drawState.context.drawContext.drawingObject;
  $: closedArea = isTileWrapper(selectedObject) || isBuildingPart(selectedObject) ? $drawState.context.current.segments.find((_seg) => _seg.id === (selectedObject as any).closedAreaId &&
          isClosedArea(_seg)) as ClosedArea : null;
  $: segmentsForWall = $drawState.context.current.segments.filter((_seg) => isBuildingPart(_seg) || isDoor(_seg)) as Segment[]
  $: isDashLine = isLine(segment) && (segment as Line).isDashed()
  $: line = segment as Line
  $: isPrinting = $drawState.matches("main.printPreview") || $drawState.matches("main.printing")
  $: isShowingHelper = (
    (selectedObject?.id === segment.id || drawingObject?.id === segment.id) || 
    (isDoor(selectedObject) && (selectedObject as Door).parentId === segment.id ) ||
    (closedArea && closedArea.shape.results.find((v) => v.id === segment.id))
  )

  const segmentDown = (e: any) => {
    if($currentTool === TOOLS.EYE_DROPPER) return;
    if( selectedObject !== segment ) return;

    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
  }

  const segmentClick = (e: any) => {
    if($currentTool === TOOLS.EYE_DROPPER) return;

    drawSend({ type: "ENTER_SELECT", segment: segment });
    drawSend({ type: "SHOW_LINE_TOOL" });
    
    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
  };

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;

    drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) {
      return;
    }
    drawSend({ type: "MOUSE_LEAVE" });
  };
</script>

<g id={segment.id}>
  <!-- svelte-ignore a11y-mouse-events-have-key-events -->
  <path
    d={isDashLine ? line.generatePath(false, undefined, 6) : segment.generatePath()}
    class={`${
      selectedObject === segment ? isDashLine ? "segment_dashed_path_selected" : "segment_path_selected" : ""
    } ${isDashLine ? "segment_dashed_path" : "segment_path"} segment`}
    stroke-linecap="round"
    stroke-width="1.5px"
    fill="none"
    on:click={segmentClick}
    on:mousedown={segmentDown}
    on:mouseover={segmentHovered}
    on:mouseleave={segmentMouseLeave}
  />
  {#if $drawState.matches("helper.showHelper") && 
    ($drawState.matches("main.printPreview") || isShowingHelper)
  }
    <g>
      {#each segment.generateHelperPath(segmentsForWall, isWall) as helperItem}
        <path
          d={helperItem}
          fill="none"
          stroke-linecap="round"
          stroke="grey"
          stroke-width="0.3px"
        />
      {/each}
      {#each segment.generateHelperTextData(segmentsForWall, isWall) as helperTextItem}
        <g>
          {#if helperTextItem}
            <text
              class="stroke-text"
              transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
              style={`font-size: ${16 / scale}px;`}
            >
              {getMetricWithUnit(
                Math.round(helperTextItem.length * 100) / 100,
                $drawState.context.currentMetricUnit,
                /* !isPrinting && */ $drawState.context.currentMetricUnit === METRIC_UNITS[0]
              )}
            </text>
          {/if}
        </g>
      {/each}
    </g>
  {/if}
</g>

<style>
  text {
    font-family: sans-serif;
    text-anchor: middle;
    pointer-events: none;
    user-select: none;
  }
</style>
