<script lang="ts">
  import { activeMenu, editSegment, selectedRoom, selectedSegment, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import GridItem from "./GridItem.svelte";
  import Icon from "../../../components/base/Icon.svelte";
  import Button from "../../../components/base/Button.svelte";
  import {
  checkBuildingPartInRoom,
  getNewSegmentName,
    getSquareWithUnit,
    isBuildingPart,
    isClosedArea,
    isDoor,
    isLine,
    isTileWrapper,
    isWallProject,
  } from "src/helpers";
  import {
    type TileWrapper,
    type Line,
    type BuildingPart,
    Door,
    Pointer,
    DOORTYPE,
  } from "src/model";
  import ListMenuItem from "./ListMenuItem.svelte";
  import { getMetricWithUnit, createDoor } from "src/helpers";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import type { CategoryElement } from "src/model/CategoryElement";
  import WallInfo from "./WallInfo.svelte";
  import DoorInfo from "./DoorInfo.svelte";
  import SegmentList from "./SegmentList.svelte";
  import FurnitureInfo from "./FurnitureInfo.svelte";
  import AreaInfo from "./AreaInfo.svelte";
  import { ClosedArea } from "src/model/ClosedArea";
  import { onDestroy, onMount } from "svelte";
  import RoomThumbnail from "./RoomThumbnail.svelte";
  import ListPattern from "./ListPattern.svelte";
  import CopyLayout from "./CopyLayout.svelte";
  import SelectTile from "./SelectTile.svelte";
  import SelectFurniture from "./SelectFurniture.svelte";

  enum ROOM_VIEW {
    INDEX = "index",
    LIST_MENU = "list-menu",
    EDIT_LAYOUT = "rooms_menu.layout",
    WALLS = "walls",
    WALL_DETAIL = "wall-info",
    DOORS = "door.list_door",
    ADD_DOOR = "door.add_door",
    EDIT_DOOR = "door.edit_door",
    WINDOWS = "door.list_window",
    ADD_WINDOW = "door.add_window",
    EDIT_WINDOW = "door.edit_window",
    FURNITURES = "furniture.list_furniture",
    SELECT_FURNITURE = "furniture-item-list",
    EDIT_FURNITURE = "furniture.edit_furniture",
    AREAS = "area.list_area",
    EDIT_AREA = "area.edit_area",
    LIST_PATTERN = "layout.choose_pattern",
    COPY_LAYOUT = "layout.copy_layout",
    SELECT_TILE = "area.select_tile"
  }

  let routes: ROOM_VIEW[] = [ROOM_VIEW.INDEX];

  let currentView: ROOM_VIEW = ROOM_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: [],
    editable: false,
  };
  let currentWall: Line;
  let currentCategory: CategoryElement;
  let currentArea: TileWrapper;
  let currentDoor: Door;
  let currentFurniture: BuildingPart;
  
  let isValidSegment: boolean;
  let areas: TileWrapper[];
  let walls: Line[];
  let unsubscribe;
  let elementScroll: HTMLDivElement;

  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.ROOM )
        return;

      if (!visible) {
        handleBack();
        routes = [ROOM_VIEW.INDEX];
        currentView = ROOM_VIEW.INDEX;
        currentWall = undefined;
        selectedRoom.set(undefined);
      }
    });
  })

  $: rooms = $drawState.context.current.segments.filter((segment) =>
    isClosedArea(segment)
  ).sort((a, b) => a.name.localeCompare(b.name)) as ClosedArea[];

  $: {
    if ($editSegment) {
      updatePanel();
    } else {
      routes = [ROOM_VIEW.INDEX]
      currentView = ROOM_VIEW.INDEX
    }
  }

  $: {
    if (!$selectedRoom) {
      routes = [ROOM_VIEW.INDEX]
      currentView = ROOM_VIEW.INDEX
    }
  }

  const updatePanel = () => {
    if( !$editSegment ) return;
    
    if( isClosedArea($editSegment) ) {
      selectedRoom.set($editSegment);
      // selectedSegment.set($editSegment);
      routes = [ROOM_VIEW.INDEX, ROOM_VIEW.LIST_MENU];
      currentView = ROOM_VIEW.LIST_MENU
    } else if( isBuildingPart($editSegment) ) {
      currentFurniture = $editSegment as BuildingPart;
      const room = rooms.find((room) => room.id === currentFurniture.closedAreaId) 
      selectedRoom.set(room);
      // selectedSegment.set($editSegment);
      drawSend({
        type: "ENTER_SELECT",
        segment: $editSegment,
      });
      routes = [ROOM_VIEW.INDEX, ROOM_VIEW.LIST_MENU, ROOM_VIEW.FURNITURES, ROOM_VIEW.EDIT_FURNITURE];
      currentView = ROOM_VIEW.EDIT_FURNITURE
    }  else if( isDoor($editSegment) ) {
      currentDoor = $editSegment as Door;
      const room = rooms.find((room) => room.id === currentDoor.closedAreaId || room.shape.results.find((v) => v.id === currentDoor.parentId)) 
      selectedRoom.set(room);
      // selectedSegment.set($editSegment);
      drawSend({
        type: "ENTER_SELECT",
        segment: $editSegment,
      });
      routes = 
      currentDoor.buildingType === DOORTYPE.DOOR ?
        [ROOM_VIEW.INDEX, ROOM_VIEW.LIST_MENU, ROOM_VIEW.DOORS, ROOM_VIEW.EDIT_DOOR] :
        [ROOM_VIEW.INDEX, ROOM_VIEW.LIST_MENU, ROOM_VIEW.WINDOWS, ROOM_VIEW.EDIT_WINDOW]
      currentView = currentDoor.buildingType === DOORTYPE.DOOR ? ROOM_VIEW.EDIT_DOOR : ROOM_VIEW.EDIT_WINDOW
    } else {
      selectedRoom.set(undefined);
      routes = [ROOM_VIEW.INDEX];
      currentView = ROOM_VIEW.INDEX;
    }
    // editSegment.set(undefined);
  }
  
  $: {
    if( currentView === ROOM_VIEW.AREAS ) 
      areas = $selectedRoom?.tileWrappers.sort((a, b) => a.name.localeCompare(b.name)) as TileWrapper[];
    else if ( currentView === ROOM_VIEW.WALLS || currentView === ROOM_VIEW.DOORS || currentView === ROOM_VIEW.WINDOWS )
      walls = $selectedRoom ? $selectedRoom?.shape?.results.filter(
        (segment) => isLine(segment) && !(segment as Line).isDashed()
      ).sort((a, b) => a.name.localeCompare(b.name)) as Line[] : [];
  }

  $: allDoors = $drawState.context.current.segments.filter(
    (segment) =>
      isDoor(segment) &&
      (
        $selectedRoom?.id === (segment as Door).closedAreaId ||
        walls?.some((wall) => wall.id === (segment as Door).parentId)
      )
  ).sort((a, b) => a.name.localeCompare(b.name)) as Door[];

  $: buildingDoors = allDoors.filter(
    (segment) => segment.buildingType === DOORTYPE.DOOR
  ) as Door[];

  $: buildingWindows = allDoors.filter(
    (segment) => segment.buildingType === DOORTYPE.WINDOW
  ) as Door[];

  $: buildingParts = $drawState.context.current.segments.filter((segment) =>
    isBuildingPart(segment) && (segment as BuildingPart).closedAreaId === $selectedRoom?.id
  ) as BuildingPart[];

  const handleNext = (view: ROOM_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    routes.pop();
    if (routes.length) currentView = routes[routes.length - 1];
  };

  const handleSelectRoom = (e: CustomEvent) => {
    const room = e.detail.segment;
    selectedRoom.set(room);
    selectedSegment.set(room);
    drawSend({
      type: "ENTER_SELECT",
      segment: room,
    });
    currentView = ROOM_VIEW.LIST_MENU;
    routes.push(currentView);
  };

  const handleSelectWall = (e: CustomEvent) => {
    currentWall = e.detail.segment;
    handleNext(ROOM_VIEW.WALL_DETAIL);
  };

  const handleAddDoor = () => {
    const line = isLine(currentWall) ? (currentWall as Line) : $selectedRoom?.shape?.results.find((v) => isLine(v) && !(v as Line).isDashed()) as Line;
    const doorName = getNewSegmentName($_("segment.door"), $drawState.context.current.segments)
    const isWall = isWallProject($drawState.context.projectBaseInfo);
    currentDoor = createDoor($selectedRoom, line, DOORTYPE.DOOR, doorName, isWall);

    drawSend({
      type: "CREATE_BUILDING_PART",
      segment: currentDoor,
    });
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    // selectedSegment.set(currentDoor);
    handleNext(ROOM_VIEW.ADD_DOOR);
  };

  const handleSelectDoor = (e: CustomEvent) => {
    currentDoor = e.detail.segment;
    if (!currentDoor) {
      return
    }
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    // selectedSegment.set(currentDoor);
    handleNext(ROOM_VIEW.EDIT_DOOR);
  };

  const handleAddWindow = () => {
    const line = isLine(currentWall) ? (currentWall as Line) : $selectedRoom?.shape?.results.find((v) => isLine(v) && !(v as Line).isDashed()) as Line;
    const windowName = getNewSegmentName($_("segment.window"), $drawState.context.current.segments)
    const isWall = isWallProject($drawState.context.projectBaseInfo);
    currentDoor = createDoor($selectedRoom, line, DOORTYPE.WINDOW, windowName, isWall);

    drawSend({
      type: "CREATE_BUILDING_PART",
      segment: currentDoor,
    });
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    // selectedSegment.set(currentDoor);
    handleNext(ROOM_VIEW.ADD_WINDOW);
  };

  const handleSelectWindow = (e: CustomEvent) => {
    currentDoor = e.detail.segment;
    if (!currentDoor) {
      return
    }
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    // selectedSegment.set(currentDoor);
    handleNext(ROOM_VIEW.EDIT_WINDOW);
  };

  const handleAddFurniture = () => {
    handleNext(ROOM_VIEW.SELECT_FURNITURE);
  };

  const handleSelectFurniture = (e: CustomEvent) => {
    currentFurniture = e.detail.segment;
    if (currentFurniture) {
      drawSend({
        type: "ENTER_SELECT",
        segment: currentFurniture,
      });
    }
    // selectedSegment.set(currentFurniture);
    if( routes.length && routes[routes.length - 1] !== ROOM_VIEW.FURNITURES) {
      routes.pop();
      routes.pop();
    }
    handleNext(ROOM_VIEW.EDIT_FURNITURE);
  };

  const handleSelectArea = (e: CustomEvent) => {
    currentArea = e.detail.segment;
    if (currentArea) {
      drawSend({
        type: "ENTER_SELECT",
        segment: currentArea,
      });
    }
    selectedSegment.set(currentArea);
    handleNext(ROOM_VIEW.EDIT_AREA);
  };

  const handleGoto = (e: CustomEvent) => {
    if( e.detail.path === 'list-pattern' )
      handleNext(ROOM_VIEW.LIST_PATTERN)
    else if( e.detail.path === 'copy-layout' )
      handleNext(ROOM_VIEW.COPY_LAYOUT)
    else if( e.detail.path === 'select-tile' )
      handleNext(ROOM_VIEW.SELECT_TILE)
  }
  
  $: {
    isValidSegment = true;
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if ( selectedObj ) {
      if( currentView === ROOM_VIEW.EDIT_FURNITURE && isBuildingPart(selectedObj) ) {
        const buildingPart = selectedObj as BuildingPart;
        const closedArea = $drawState.context.current.segments.find(
            (s) =>
              s instanceof ClosedArea &&
              s.id === buildingPart.closedAreaId
          ) as ClosedArea;
        isValidSegment = closedArea && checkBuildingPartInRoom(buildingPart, closedArea)
      }
    }
  }

  $: {
    let areaShape;
    switch (currentView) {
      case ROOM_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.rooms"),
          info: "",
          path: [$drawState.context.projectBaseInfo?.name ? $drawState.context.projectBaseInfo.name : $_("project_wizard.untitled")],
          editable: false,
        };
        break;
      case ROOM_VIEW.LIST_MENU:
        areaShape = $selectedRoom?.getArea();
        headerInfo = {
          title: $selectedRoom?.getName($_),
          info: getSquareWithUnit(areaShape, $drawState.context.currentMetricUnit),
          path: [$drawState.context.projectBaseInfo?.name ? $drawState.context.projectBaseInfo.name : $_("project_wizard.untitled")],
          editable: true,
        };
        break;
      case ROOM_VIEW.EDIT_LAYOUT:
        areaShape = $selectedRoom?.getArea();
        headerInfo = {
          title: $_(`side_menu.${currentView}`),
          info: getSquareWithUnit(areaShape, $drawState.context.currentMetricUnit),
          path: [],
          editable: false,
        };
        break;
      case ROOM_VIEW.WALLS:
      case ROOM_VIEW.DOORS:
      case ROOM_VIEW.WINDOWS:
      case ROOM_VIEW.FURNITURES:
      case ROOM_VIEW.AREAS:
        headerInfo = {
          title: $_(`side_menu.${currentView}`),
          info: "",
          path: [$selectedRoom?.getName($_)],
          editable: false,
        };
        break;      
      case ROOM_VIEW.ADD_DOOR:
      case ROOM_VIEW.EDIT_DOOR:
      case ROOM_VIEW.ADD_WINDOW:
      case ROOM_VIEW.EDIT_WINDOW:
        headerInfo = {
          title: currentDoor?.getName($_),
          info: "",
          path: [$selectedRoom?.getName($_), $_(`side_menu.${currentView}`)],
          editable: true,
        };
        break;
      case ROOM_VIEW.EDIT_FURNITURE:
        headerInfo = {
          title: currentFurniture?.getName($_),
          info: "",
          path: [$selectedRoom?.getName($_), $_(`side_menu.${currentView}`)],
          editable: true,
        };
        break;
      case ROOM_VIEW.EDIT_AREA:
        headerInfo = {
          title: currentArea?.getName($_),
          info: getSquareWithUnit(currentArea?.getArea(), $drawState.context.currentMetricUnit),
          path: [$selectedRoom?.getName($_), $_(`side_menu.${currentView}`)],
          editable: true,
        };
        break;
      case ROOM_VIEW.WALL_DETAIL:
        headerInfo = {
          title: currentWall.getName($_),
          info: getMetricWithUnit(
            currentWall.getLineLength(),
            $drawState.context.currentMetricUnit
          ).toString(),
          path: [$_("side_menu.rooms"), $selectedRoom?.getName($_), $_(
            "side_menu.walls"
          )],
          editable: true,
        };
        break;
      case ROOM_VIEW.SELECT_FURNITURE:
        headerInfo = {
          title: $_("side_menu.furniture.add_furniture"),
          info: "",
          path: [$_("side_menu.rooms"), $selectedRoom?.getName($_)],
          editable: false,
        };
        break;
      case ROOM_VIEW.LIST_PATTERN:
      case ROOM_VIEW.COPY_LAYOUT:
        headerInfo = {
          title: $_(`side_menu.${currentView}`),
          info: "",
          path: [$selectedRoom?.getName($_),currentArea.getName($_)],
          editable: false,
        };
        break;
      case ROOM_VIEW.SELECT_TILE:
        headerInfo = {
          title: $_(`tile.title`),
          info: "",
          path: [$selectedRoom?.getName($_), currentArea.getName($_)],
          editable: false,
        };
        break;
    }
  }
  
  const handleTitleChange = (e) => {
    switch(currentView) {
      case ROOM_VIEW.LIST_MENU:
        // currentArea.name = e.detail.value;
        drawSend({
          type: "CHANGE_NAME",
          segment: $selectedRoom,
          name: e.detail.value,
        });
      break;
      
      case ROOM_VIEW.EDIT_LAYOUT:
      case ROOM_VIEW.EDIT_AREA:
        drawSend({
          type: "CHANGE_NAME",
          segment: currentArea,
          name: e.detail.value,
        });
        break;

      case ROOM_VIEW.ADD_DOOR:
      case ROOM_VIEW.EDIT_DOOR:
      case ROOM_VIEW.ADD_WINDOW:
      case ROOM_VIEW.EDIT_WINDOW:        
        drawSend({
          type: "CHANGE_NAME",
          segment: currentDoor,
          name: e.detail.value,
        });
        break;
      case ROOM_VIEW.EDIT_FURNITURE:
        drawSend({
          type: "CHANGE_NAME",
          segment: currentFurniture,
          name: e.detail.value,
        });
        break;
        
      case ROOM_VIEW.WALL_DETAIL:
        currentWall.name = e.detail.value;
        drawSend({
          type: "CHANGE_NAME",
          segment: currentWall,
          name: e.detail.value,
        });
        break;
    }
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header
    {...headerInfo} 
    on:change={handleTitleChange}
    hasBack={currentView !== ROOM_VIEW.INDEX}
    on:back={handleBack}
  />
  <div class="w-full flex flex-col flex-1 py-3 overflow-auto gap-4" bind:this={elementScroll}>
    {#if currentView === ROOM_VIEW.INDEX}
      <div class="w-full px-2.5">
        <div class="w-full py-7 px-1 border-t border-gray-100">
          <SegmentList
            type={SEGMENT_LIST_TYPE.TILE_WRAPPER} 
            segments={rooms}
            on:select={handleSelectRoom}
          />
        </div>
      </div>
    {:else if currentView === ROOM_VIEW.LIST_MENU && $selectedRoom}
      <div class="flex items-center justify-center">
        <RoomThumbnail
          room={$selectedRoom}
        />
      </div>
      <div class="w-full flex flex-col border-t border-gray-300">
        {#if $selectedRoom?.tileWrappers.length === 1}
          <ListMenuItem
            title={$_("side_menu.rooms_menu.layout")}
            on:click={() => {
              drawSend({
                type: "ENTER_SELECT",
                segment: $selectedRoom?.tileWrappers[0],
              });
              currentArea = $selectedRoom?.tileWrappers[0];
              handleNext(ROOM_VIEW.EDIT_LAYOUT);
            }}
          >
            <!-- <img
              slot="icon"
              alt="layout"
              src="/layout.png"
              class="w-5 h-5 rounded-sm"
            /> -->
            <i slot="icon" class="fa-light fa-block-brick fa-custom-lg" />
          </ListMenuItem>
        {:else}
          <ListMenuItem
            title={$_("side_menu.rooms_menu.area")}
            on:click={() => handleNext(ROOM_VIEW.AREAS)}
          >
            <i slot="icon" class="fa-light fa-square-dashed fa-custom-lg" />
          </ListMenuItem>
        {/if}
        <!-- <ListMenuItem
          title={$_("side_menu.rooms_menu.walls")}
          on:click={() => handleNext(ROOM_VIEW.WALLS)}
        >
          <i slot="icon" class="fa-light fa-draw-square fa-custom-lg" />
        </ListMenuItem> -->
        <ListMenuItem
          title={$_("side_menu.rooms_menu.doors")}
          on:click={() => handleNext(ROOM_VIEW.DOORS)}
        >
          <i slot="icon" class="fa-light fa-door-closed fa-custom-lg" />
        </ListMenuItem>
        <ListMenuItem
          title={$_("side_menu.rooms_menu.windows")}
          on:click={() => handleNext(ROOM_VIEW.WINDOWS)}
        >
          <i slot="icon" class="fa-light fa-window-frame fa-custom-lg" />
        </ListMenuItem>
        <ListMenuItem
          title={$_("side_menu.rooms_menu.furnitures")}
          on:click={() => handleNext(ROOM_VIEW.FURNITURES)}
        >
          <i slot="icon" class="fa-light fa-couch fa-custom-lg" />
        </ListMenuItem>
      </div>
    {:else if currentView === ROOM_VIEW.WALL_DETAIL}
      <div class="w-full px-2.5">
        <div class="px-1 py-7 border-t border-gray-100">
          <WallInfo
            wall={currentWall}
          />
        </div>
      </div>
    {:else if currentView === ROOM_VIEW.DOORS}
      <div class="w-full px-2.5">
        <div class="px-1 py-7 border-t border-gray-100">
          <SegmentList
            type={SEGMENT_LIST_TYPE.DOOR}
            segments={buildingDoors}
            on:select={handleSelectDoor}
          />
          <Button
            variant="primary-hover"
            title={$_("side_menu.door.add_door")}
            icon="fa-light fa-plus"
            iconPos="center"
            class="w-full rounded-full mt-5"
            on:click={handleAddDoor}
          />
        </div>
      </div>
    {:else if currentView === ROOM_VIEW.WINDOWS}
      <div class="w-full px-2.5">
        <div class="px-1 py-7 border-t border-gray-100">
          <SegmentList
            type={SEGMENT_LIST_TYPE.DOOR}
            segments={buildingWindows}
            on:select={handleSelectWindow}
          />
          <Button
            variant="primary-hover"
            title={$_("side_menu.door.add_window")}
            icon="fa-light fa-plus"
            iconPos="center"
            class="w-full rounded-full mt-5"
            on:click={handleAddWindow}
          />
        </div>
      </div>
    {:else if currentView === ROOM_VIEW.ADD_DOOR || currentView === ROOM_VIEW.EDIT_DOOR}
      <DoorInfo door={currentDoor} type={DOORTYPE.DOOR} on:back={handleBack} />
    {:else if currentView === ROOM_VIEW.ADD_WINDOW || currentView === ROOM_VIEW.EDIT_WINDOW}
      <DoorInfo door={currentDoor} type={DOORTYPE.WINDOW} on:back={handleBack} />
    {:else if currentView === ROOM_VIEW.FURNITURES}
      <div class="w-full px-2.5">
        <div class="px-1 py-7 border-t border-gray-100">
          <SegmentList
            type={SEGMENT_LIST_TYPE.BUILDING_PART}
            segments={buildingParts}
            on:select={handleSelectFurniture}
          />
          <Button
            variant="primary-hover"
            title={$_("side_menu.furniture.add_furniture")}
            icon="fa-light fa-plus"
            iconPos="center"
            class="w-full rounded-full mt-5"
            on:click={handleAddFurniture}
          />
        </div>
      </div>
    {:else if currentView === ROOM_VIEW.SELECT_FURNITURE}
      <SelectFurniture room={$selectedRoom} on:select={handleSelectFurniture} />
    {:else if currentView === ROOM_VIEW.EDIT_FURNITURE}
      <FurnitureInfo buildingPart={currentFurniture} on:back={handleBack}/>
    {:else if currentView === ROOM_VIEW.AREAS}
      <div class="w-full px-2.5">
        <div class="px-1 py-7 border-t border-gray-100">
          <SegmentList
            type={SEGMENT_LIST_TYPE.AREA}
            segments={areas}
            on:select={handleSelectArea}
          />
        </div>
      </div>
    {:else if currentView === ROOM_VIEW.EDIT_LAYOUT || currentView === ROOM_VIEW.EDIT_AREA}
      <AreaInfo 
        tileWrapper={currentArea} 
        on:go={handleGoto} 
        on:back={handleBack} 
      />
    {:else if currentView === ROOM_VIEW.COPY_LAYOUT}
      <CopyLayout elementScroll={elementScroll} on:back={handleBack} />
    {:else if currentView === ROOM_VIEW.SELECT_TILE}
      <SelectTile tileWrapper={currentArea} on:back={handleBack} />
    {/if}
  
    <ListPattern tileWrapper={currentArea} on:back={handleBack} visible={currentView === ROOM_VIEW.LIST_PATTERN}/>

    <!-- {#if currentView !== ROOM_VIEW.INDEX}
      <div class="flex items-center justify-between mt-auto">
        {#if currentView === ROOM_VIEW.ADD_DOOR || currentView === ROOM_VIEW.EDIT_DOOR || currentView === ROOM_VIEW.ADD_WINDOW || currentView === ROOM_VIEW.EDIT_WINDOW || currentView === ROOM_VIEW.EDIT_FURNITURE}
          <Button
            variant="outline-secondary"
            title={$_("side_menu.reset")}
            class="w-wide-btn ml-auto"
            on:click={handleReset}
            disabled={!isValidSegment}
          />
        {/if}
      </div>
    {/if} -->
  </div>
</div>
